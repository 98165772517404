<section class="page-content">
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading">
    <div fxFlex class="text-center">
      <h4>Please wait ...</h4>
    </div>
  </div>

  <div class="synonyms-page-actions-container" *ngIf="hasResults">
    <div fxFlex="60%">
        <button mat-button color="primary" class="backBtn" (click)="backToList()">
          Back To List
        </button>
        <button mat-button color="primary" class="saveBtn" [hidden]="!isNeedToSave" (click)="saveSynonyms()">
          Save
        </button>
    </div>
    <div fxFlex="40%">
      <mat-form-field class="filter">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
    </div>
  </div>
  <mat-table #table [dataSource]="synonymMapNames" *ngIf="!hasResults && !isLoading">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef fxFlex="30%">Synonym Map Name</mat-header-cell>
      <mat-cell *matCellDef="let synonymMapName" fxFlex="30%">
        <a class="clickable" (click)="getSynonyms(synonymMapName)">{{ synonymMapName.name }}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="version">
      <mat-header-cell *matHeaderCellDef fxFlex="70%">Synonym Map Version</mat-header-cell>
      <mat-cell *matCellDef="let synonymMapName" fxFlex="30%">
        <a class="clickable" (click)="getSynonyms(synonymMapName)">{{ synonymMapName.version }}</a>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="synonymMapNamesColums"></mat-header-row>
    <mat-row *matRowDef="let synonymMapName; columns: synonymMapNamesColums;"></mat-row>
  </mat-table>

  <div class="synonyms-search-add-container" *ngIf="hasResults">
    <div class="add-container">
      <mat-card>
        <mat-card-title>
          <div fxFlex="70%">Add New Synonyms</div>
        </mat-card-title>
        <mat-form-field class="keyword">
          <input matInput placeholder="Keyword" name="keyword" [(ngModel)]="request.keyword" required />
        </mat-form-field>
        <mat-form-field class="synonyms">
          <input matInput placeholder="Synonyms" name="synonyms" [(ngModel)]="request.synonyms" required />
        </mat-form-field>
        <button mat-button color="primary" class="addBtn" (click)="addSynonym()">
          Add
        </button>
      </mat-card>
    </div>
  </div>

  <div *ngIf="hasResults">
    <mat-table #paginator [dataSource]="dataSource">
      <ng-container matColumnDef="keyword">
        <mat-header-cell *matHeaderCellDef fxFlex="30%">Keyword</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="30%">
          <textarea matInput placeholder="Keywords" [value]="data.keyword" [(ngModel)]="data.keyword" wrap="hard" (keyup)="onKeyup()"></textarea>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="synonyms">
        <mat-header-cell *matHeaderCellDef fxFlex="65%">Synonyms</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="65%">
          <textarea matInput placeholder="Synonyms" [value]="data.synonyms" [(ngModel)]="data.synonyms" wrap="hard" (keyup)="onKeyup()"></textarea>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef fxFlex="5%">Action</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="5%">
          <action-confirmation [actionConfirmation]="actionConfirmationData" (userResponse)="removeKeyword($event, data)"
                               fxFlex="15%">
          </action-confirmation>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let synonym; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="rowCount == 0">
      No Records to show!
    </div>
  </div>
  <mat-paginator [hidden]="!hasResults" [pageSize]="25" showFirstLastButtons></mat-paginator>
</section>
