<section class="page-content">
  <div class="identity-user-search-component" fxLayoutGap="10px">
    <form [formGroup]="form" (ngSubmit)="performIdentityUserSearch()">
      <div style="width:100%">

        <mat-form-field fxFlex="50%">
          <input matInput type="text" (input)="valueChange()" placeholder="Email" formControlName="email" required
            minlength="6" maxlength="100" (keyup)="onKeyUp($event)" />
        </mat-form-field>

        <div fx-flex="50%">
          <button class="search-button" mat-raised-button [color]="'primary'" type="submit">
            <mat-icon>search</mat-icon>Search
          </button>
        </div>

      </div>
    </form>
    <label *ngIf="!showEmailError" class="email-hint-message">
      Please enter the full email address of the user.
    </label>
    <label *ngIf="showEmailError" class="email-error-message">
      Valid email address should contain the username, the '@' character and domain names separated with dots.
    </label>
  </div>
  <div class="identity-users-grid-container">
    <progress-bar [isLoading]="isLoading"></progress-bar>
    <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear="true">

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <ng-container matColumnDef="emailAddress">
        <mat-header-cell *matHeaderCellDef class='min-width-m' mat-sort-header="emailAddress"> Email Address
        </mat-header-cell>
        <mat-cell *matCellDef="let identityuser">
          <a (click)="addUpdateUser(identityuser)"> {{identityuser.email}} </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef class='min-width-m' mat-sort-header="firstName"> First Name
        </mat-header-cell>
        <mat-cell class='min-width-m' *matCellDef="let identityuser"> {{identityuser.firstName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef class='min-width-m' mat-sort-header="lastName"> Last Name </mat-header-cell>
        <mat-cell class='min-width-m' *matCellDef="let identityuser"> {{identityuser.lastName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef class='min-width-m' mat-sort-header="createdDate"> Created Date <br />
          ({{timezoneAbbrevation}})</mat-header-cell>
        <mat-cell class='min-width-m' *matCellDef="let identityuser"> <label *ngIf="!disableCreatedDate">
            {{identityuser.createdDate | date:'medium'}} </label> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="modifiedDate">
        <mat-header-cell *matHeaderCellDef class='min-width-m' mat-sort-header="modifiedDate"> Modified Date <br />
          ({{timezoneAbbrevation}}) </mat-header-cell>
        <mat-cell class='min-width-m' *matCellDef="let identityuser"> <label
            *ngIf="!disableModifiedDate">{{identityuser.modifiedDate | date:'medium'}}</label>
        </mat-cell>
      </ng-container>

    </mat-table>
    <label *ngIf="dataGridReplacementMessage != ''"
      class="data-grid-replacement-message">{{dataGridReplacementMessage}}</label>
  </div>
</section>