import { ColorScheme } from 'app/types/colorScheme';

export class ThemesService {

  setColorTheme(themeName: string): void {
    var availableColorSchemes = this.getAllColorSchemes();
    var selectedColorScheme = availableColorSchemes.find(p => p.schemaClassName == themeName);
    if (selectedColorScheme == null) {
      throw new Error("The selected color scheme does not exist.");
    }
    else {
      localStorage.setItem("MyHelp_CurrentColorScheme", JSON.stringify(themeName));
    }
  }

  getCurrentColorTheme(): ColorScheme {
    var availableColorSchemes = this.getAllColorSchemes();
    var defaultColorScheme = availableColorSchemes.find(p => p.isDefaultScheme);
    var currentSelectedColorScheme = JSON.parse(localStorage.getItem('MyHelp_CurrentColorScheme'));

    // If local storage does not contain any scheme, return the default scheme
    if (currentSelectedColorScheme == null) {
      return defaultColorScheme;
    }

    var currentColorScheme = availableColorSchemes.find(p => p.schemaClassName == currentSelectedColorScheme);

    if (currentColorScheme == null) {
      return defaultColorScheme
    }
    else {
      return currentColorScheme;
    }
  }

  getAllColorSchemes(): ColorScheme[] {
    let colorSchemes: { schemaDisplayName: string, schemaClassName: string, schemaDefaultLoginImage: string, isDefaultScheme: boolean }[] = [
      { "schemaDisplayName": "Orange", "schemaClassName": "orange-theme", "schemaDefaultLoginImage":"LoginPict-00.jpg",  isDefaultScheme: true },
      { "schemaDisplayName": "Blue", "schemaClassName": "blue-theme", "schemaDefaultLoginImage":"LoginPict-Dark-00.jpg",isDefaultScheme: false },
      { "schemaDisplayName": "Grey", "schemaClassName": "grey-theme","schemaDefaultLoginImage":"LoginPict-Grey-00.jpg", isDefaultScheme: false }
    ];
    return colorSchemes;
  }
}
