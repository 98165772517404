import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AlertSettingRequest, CiamAuth, GraphQLRequest } from "@dignity-health/ciam-auth";
import { UiService } from "../ui/ui.service";
import * as _ from 'lodash';
import { environment } from 'environments/environment';

const GET_ALERT_SETTINGS_QUERY = `query{
    alertSettings {
     alertSettingId
     alertName    
     threshold
     thresholdUnit
     shouldSendRyverAlert
     shouldSendEmailAlert 
     shouldSendReoccurringRyverAlert
     shouldSendReoccurringEmailAlert
     emailTo
     reoccurringThreshold   
     reoccurringThresholdUnit
    }
  }`;

@Injectable()
export class SiteSettingService {
  public alertSettingDataSource = new BehaviorSubject<AlertSettingRequest[]>([]);

  public isLoading = new BehaviorSubject<boolean>(true);
  alertSettingsQuery: GraphQLRequest;

  constructor(private ciamAuth: CiamAuth, private uiService: UiService) { }

  getAlertSetting(): BehaviorSubject<AlertSettingRequest[]> {
    this.alertSettingsQuery = {
      query: GET_ALERT_SETTINGS_QUERY,
      variables: {
      }
    };

    this.setIsLoading(true);
    this.ciamAuth.httpApi.apiInfoQueryPost(this.alertSettingsQuery, true)
      .then(result => {
        const alertSettings = result && result.data && result.data.alertSettings;

        if (!alertSettings) {
          this.setIsLoading(false);
          this.uiService.showErrors(
            result.errors ? result.errors[0].message :
              // Default error message in case of nothing came from the service.
              'There is some issue in fetching the Alertsettings. Please contact the technical support team.');
          return;
        }

        const orderedAlertsettings = _.orderBy(alertSettings, [t => t.alertSettingId], ['asc']);
        this.alertSettingDataSource.next(<AlertSettingRequest[]>orderedAlertsettings);
        this.setIsLoading(false);
      })
      .catch(error => {
        return this.uiService.showErrors(error, true, environment.ciamUrl + "info/query");
      });

    return this.alertSettingDataSource;
  }

  setIsLoading(val: boolean) {
    this.isLoading.next(val);
  }
}