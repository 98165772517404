import { UpdateUser } from '../../../store/actions/user';
import { CiamUser, CiamAuth, ServiceResponse, InstructionRequest } from '@dignity-health/ciam-auth'
import { Component, OnInit } from '@angular/core';
import { Store, createAction, props } from '@ngrx/store';
import { IAppState } from '../../../types/state';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getValidationErrors } from '../../../types/helpers';
import { ValidationErrors } from 'app/types/forms';
import { UiService } from '../../../services/ui/ui.service';
import { ValidGroups } from '../../../types/constants';
import { GraphQLQuery } from '../../../types/graphql';
import { USER_MANUAL_LINK } from '../../../types/constants';
import { InstructionService } from 'app/services/instructions/instructions-service';
import { UpdatePatientSearch, UpdateCernerSearch, UpdateUserSearch } from 'app/store/actions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { WarningDialogUserComponent } from 'app/shared/warning-dialog-user/warning-dialog-user.component';
import { environment } from '../../../../environments/environment';
import { ThemesService } from 'app/services/themes/themes-service';

const GET_INSTRUCTION_QUERY = `
    query getInstruction{
      myHelpInstructions{
        instructionId
        title
        description
        isActive
      }}`;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  resolvingUser: boolean;
  user: CiamUser;
  user$: Observable<CiamUser>;
  showSpinner = false;
  form: FormGroup;
  getInstructionQuery: GraphQLQuery;
  description = '';
  loginImages = ['LoginPict-00.jpg', 'LoginPict-01.jpg', 'LoginPict-02.jpg', 'LoginPict-03.jpg', 'LoginPict-04.jpg', 'LoginPict-05.jpg'];
  loginImage = this.loginImages[0];  // Select the default pic, i.e. the Pict0.
  loading = false;
  validationErrors: ValidationErrors = {};
  dispatchUser = createAction(
    'Update user in store',
    props<{ user: CiamUser }>()
  );
  username: string;
  constructor(private fb: FormBuilder, private ciamAuth: CiamAuth, private uiService: UiService, private dialog: MatLegacyDialog,
    private store: Store<IAppState>, private router: Router,
    private instructionService: InstructionService, private oidcSecurityService: OidcSecurityService, private route: ActivatedRoute,
    private themesService: ThemesService) {
    this.user$ = this.store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);

    this.store.dispatch(new UpdateUserSearch(null));
    this.store.dispatch(new UpdatePatientSearch(null));
    this.store.dispatch(new UpdateCernerSearch(null));
  }

  private doUserResolvingAction<T>(action: () => Promise<T>): Promise<T> {
    this.resolvingUser = true;

    const promise = action();

    promise.catch(error => {
      this.uiService.openSnackbar(error.toString(), null, { duration: 2500 });

      this.resolvingUser = false;
    });

    promise.then(() => this.resolvingUser = false);

    return promise;
  }
  
  private updateUser(user: CiamUser) {
    this.user = user;
    this.store.dispatch(this.dispatchUser({ user: this.user }));
  }

  async login(): Promise<void> {
    localStorage.removeItem('MyHelp_UserSearchType');
    this.oidcSecurityService.authorize();

  }

  async signOut(): Promise<void> {
    this.doUserResolvingAction(async () => {
      await this.ciamAuth.session.signOut();
      this.updateUser(null);
      this.router.navigate(['login']);
      this.store.dispatch(new UpdateUser(null));
    });
  }

  async ngOnInit() {
    this.getInstruction();
    this.route.queryParams.subscribe(q => {
      if (q.returnUrl) {
        if (!q.returnUrl.includes('login-loader')) {
          if (localStorage.getItem('returnUrl')) {
            localStorage.removeItem('returnUrl');
          }
          localStorage.setItem('returnUrl', q.returnUrl);
        } else {
          if (localStorage.getItem('returnUrl')) {
            localStorage.removeItem('returnUrl');
          }
          localStorage.setItem('returnUrl', "/users-dignity-health");
        }
      }
    });

    if (localStorage.getItem('invalidUser') == 'yes') {
      localStorage.removeItem('invalidUser');
      this.username = localStorage.getItem("username");
      localStorage.removeItem("username");
      var error = 'Information about user is present in Okta but is missing in CIAM.';
      return this.openWarningDialog(error);
    }

    // Assign the login image as per the theme
    this.loginImage = this.themesService.getCurrentColorTheme().schemaDefaultLoginImage;
  }

  async getInstruction() {
    this.getSpecialLoginImage();
    this.loading = true;
    this.instructionService.getMyHelpInstructions().then(response => {

      this.description = response.description;
      this.loading = false;
    });
  }

  private userManualLink() {
    window.location.href = USER_MANUAL_LINK;
  }

  openWarningDialog(message) {
    this.dialog.open(WarningDialogUserComponent, {
      data: {
        dialogMessage: message,
        email: this.username
      }
    });
  }

  getSpecialLoginImage() {
    const rnd = Math.floor(Math.random() * 500);
    var dayOfWeek = new Date().getDay();
    var isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0); // 6 = Saturday, 0 = Sunday.
    if (!isWeekend && rnd == 0) {
      this.loginImage = this.loginImages[dayOfWeek];
    }
  }
}
