<section *ngIf="user" class="page-content">
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <button mat-raised-button color="primary" [hidden]="!canCreateRegions" class="region-button-margin" (click)="createRegion()">
    <mat-icon class="mat-icon-margin-left">add</mat-icon>Create Region 
  </button>
  <div class="regions-grid-container">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="regionId">
        <mat-header-cell *matHeaderCellDef fxFlex="20%">Region Id</mat-header-cell>
        <mat-cell *matCellDef="let regions" fxFlex="20%">{{regions.regionId}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="regionName">
        <mat-header-cell *matHeaderCellDef fxFlex="60%">Region Name</mat-header-cell>
        <mat-cell *matCellDef="let regions" fxFlex="60%">{{regions.regionName}}</mat-cell>
      </ng-container>    
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef  [hidden]="!canCreateRegions">Actions</mat-header-cell>
        <mat-cell *matCellDef="let regions" [hidden]="!canCreateRegions">
          <mat-icon (click)="editRegion(regions)" matTooltip="Edit regions">border_color</mat-icon>
          <action-confirmation  class="region-icon-margin" [actionConfirmation]="actionConfirmationData" (userResponse)="deleteRegion($event,regions)">
          </action-confirmation>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let regions; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div *ngIf="showNoRowMsg" class="text-center">
    No records to show!
  </div> 
</section>


