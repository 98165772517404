import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'events-monitoring',
  templateUrl: './events-monitoring.component.html',
  styleUrls: ['./events-monitoring.component.scss']
})

export class EventsMonitoringComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
