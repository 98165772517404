import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CiamAuth, AlertSettingRequest, AlertSettingRequestReoccurringThresholdUnit, AlertSettingRequestThresholdUnit } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { ValidationErrors } from '../../../types/forms';
import { getValidationErrors } from '../../../types/helpers';
import { EmailsValidator } from 'app/validations/emails.validator';
import * as _ from 'lodash';

const validationMessages = {
  threshold: {
    'required': 'Please enter a numeric value',
    'min': 'Please enter a numeric value',
    'pattern': 'Please enter a numeric value'
  },
  reoccurringThreshold: {
    'required': 'Please enter a numeric value',
    'min': 'Please enter a numeric value',
    'pattern': 'Please enter a numeric value'
  },
  emailTo: {
    'validEmails': 'Invalid email'
  }
}

@Component({
  selector: 'alert-setting-update',
  templateUrl: './alert-setting-update.component.html',
  styleUrls: ['./alert-setting-update.component.scss']
})

export class AlertSettingUpdateComponent implements OnInit {
  alertSetting: AlertSettingRequest;
  showLoader: boolean = false;
  isEditMode: boolean = false;
  greaterThan: boolean = false;
  form: FormGroup;
  validationErrors: ValidationErrors = {};
  currentPageNumber: 1;
  currentPageSize: 10;
  ReoccurringThresholdUnits: any;
  ThresholdUnits: any;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private fb: FormBuilder,
    public dialogRef: MatLegacyDialogRef<AlertSettingUpdateComponent>
  ) {
    this.isEditMode = data ? true : false;
    this.alertSetting = data ? data : <AlertSettingRequest>{
      alertSettingId: 0,
      alertName: '',
      threshold: 5,
      thresholdUnit: 2,
      reoccurringThreshold: 24,
      reoccurringThresholdUnit: 3,
      emailTo: '',
      shouldSendRyverAlert: false,
      shouldSendEmailAlert: false,
      shouldSendReoccurringRyverAlert: false,
      shouldSendReoccurringEmailAlert: false
    };
  }

  ngOnInit() {
    let seconds = 'seconds';
    this.ReoccurringThresholdUnits = _.omit(AlertSettingRequestReoccurringThresholdUnit, [_.capitalize(seconds), seconds]);
    this.ThresholdUnits = _.omit(AlertSettingRequestThresholdUnit, [_.capitalize(seconds), seconds]);

    this.form = this.fb.group({
      'alertName': [this.alertSetting != null ? this.alertSetting.alertName : ''],
      'threshold': [this.alertSetting != null ? this.alertSetting.threshold : '',
      [Validators.required, Validators.min(0), Validators.pattern(/^[0-9]*$/)]],
      'reoccurringThreshold': [this.alertSetting != null ? this.alertSetting.reoccurringThreshold : '',
      [Validators.required, Validators.min(0), Validators.pattern(/^[0-9]*$/)]],
      'emailTo': [this.alertSetting != null ? this.alertSetting.emailTo : '',
      [EmailsValidator.validEmails()]],
      'shouldSendRyverAlert': [this.alertSetting != null ? this.alertSetting.shouldSendRyverAlert : false],
      'shouldSendEmailAlert': [this.alertSetting != null ? this.alertSetting.shouldSendEmailAlert : false],
      'shouldSendReoccurringRyverAlert': [this.alertSetting != null ? this.alertSetting.shouldSendReoccurringRyverAlert : false],
      'shouldSendReoccurringEmailAlert': [this.alertSetting != null ? this.alertSetting.shouldSendReoccurringEmailAlert : false],
      'reoccurringThresholdUnit': [this.alertSetting != null ? this.alertSetting.reoccurringThresholdUnit : ''],
      'thresholdUnit': [this.alertSetting != null ? this.alertSetting.thresholdUnit : '']
    });

    this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data))
  }

  onFormValuesChanged(data: any) {
    this.validationErrors = getValidationErrors(this.form, validationMessages);
    this.CompareTimeDuration();
  }

  onFormSubmit() {
    const controls = this.form.controls;
    if (!this.form.valid) {
      return;
    }

    this.alertSetting.emailTo = controls['emailTo'].value;
    this.alertSetting.reoccurringThreshold = controls['reoccurringThreshold'].value;
    this.alertSetting.threshold = controls['threshold'].value;
    this.alertSetting.shouldSendRyverAlert = controls['shouldSendRyverAlert'].value;
    this.alertSetting.shouldSendEmailAlert = controls['shouldSendEmailAlert'].value;
    this.alertSetting.shouldSendReoccurringRyverAlert = controls['shouldSendReoccurringRyverAlert'].value;
    this.alertSetting.shouldSendReoccurringEmailAlert = controls['shouldSendReoccurringEmailAlert'].value;
    this.alertSetting.thresholdUnit = controls['thresholdUnit'].value;
    this.alertSetting.reoccurringThresholdUnit = controls['reoccurringThresholdUnit'].value;

    this.showLoader = true;
    if (this.isEditMode) {
      this.updateAlertSetting();
    }
  }

  async updateAlertSetting() {
    const response = await this.ciamAuth.httpApi.apiAdminSitesettingsAlertSettingPatch(this.alertSetting);
    this.showLoader = false;

    if (response.errors.length) {
      this.uiService.openSnackbar(response.errors[0].message, 'Error');
      return;
    }

    this.uiService.openSnackbar('Alert Setting is updated successfully.', 'Success');
    this.dialogRef.close({ status: true });
  }

  CompareTimeDuration() {
    this.greaterThan = false;
    if (this.form.controls) {
      const reoccurringThresholdUnitInSeconds = this.ConvertToSeconds(this.form.controls['reoccurringThreshold'].value,
        this.form.controls['reoccurringThresholdUnit'].value);
      const thresholdUnitInSeconds = this.ConvertToSeconds(this.form.controls['threshold'].value,
        this.form.controls['thresholdUnit'].value);
      if (reoccurringThresholdUnitInSeconds != 0 && thresholdUnitInSeconds != 0 && reoccurringThresholdUnitInSeconds <= thresholdUnitInSeconds) {
        this.greaterThan = true;
      }
    }
  }

  ConvertToSeconds(time: number, unit: AlertSettingRequestReoccurringThresholdUnit): number {
    switch (unit) {
      case AlertSettingRequestReoccurringThresholdUnit.Hours:
        return time * 60 * 60;
      case AlertSettingRequestReoccurringThresholdUnit.Minutes:
        return time * 60;
      default:
        return time;
    }
  }
}
