
<section class="page-content">
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading">
    <div fxFlex class="text-center">
      <h4>Please wait ...</h4>
    </div>
  </div>

  <div class="synonyms-search-add-container">
    <div class="add-container">
      <mat-card>
        <mat-card-title>
          <div fxFlex="70%">Add New Score</div>
        </mat-card-title>
        <mat-form-field class="keyword">
          <mat-label>Please select field name</mat-label>
          <mat-select [(value)]="fieldName">
            <mat-option *ngFor="let fieldName of fieldNames" [value]="fieldName">
              {{fieldName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="synonyms">
          <input matInput placeholder="Please input weight value" name="weight" [(ngModel)]="weight" required />
        </mat-form-field>
        <button mat-button color="primary" class="addBtn" (click)="saveScore()">
          Add
        </button>
      </mat-card>
    </div>
  </div>

  <div *ngIf="hasResults">
    <mat-table [dataSource]="scoreDataSource">
      <ng-container matColumnDef="score">
        <mat-header-cell *matHeaderCellDef fxFlex="30%">Field Name</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="30%">
          <span>{{data.fieldName}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef fxFlex="65%">Weight</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="65%">
          <textarea matInput placeholder="Weights" [value]="data.weight" [(ngModel)]="data.weight" wrap="hard"></textarea>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef fxFlex="9%">Action</mat-header-cell>
        <mat-cell *matCellDef="let data" fxFlex="9%">
          <mat-icon (click)="updateScore()" class="cursor" matTooltip="Update">done</mat-icon>
          <action-confirmation [actionConfirmation]="actionConfirmationData" (userResponse)="deleteScore($event, data)"
                               fxFlex="15%">
          </action-confirmation>
        </mat-cell>

      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let score; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="rowCount == 0">
      No Records to show!
    </div>
  </div>
  <mat-paginator [hidden]="!hasResults" [pageSize]="25" showFirstLastButtons></mat-paginator>
</section>
