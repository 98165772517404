import { ValidationErrors } from '../../../types/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'material-form-input',
  templateUrl: './material-form-input.component.html',
  styleUrls: ['./material-form-input.component.scss']
})

export class MaterialFormInputComponent {
  @Input()
  name: string;

  @Input()
  validationErrors: ValidationErrors;

}
