<section class="page-content" *ngIf="user">
    <progress-bar [isLoading]="isLoading"></progress-bar>
    <div class="patients-grid-container">
        <div class="patients-grid-header">
            <app-patient-enrollments-search [searchType]="searchType" [isLoading]="isLoading"
                (patientEnrollmentsSearchParams)="setPatientEnrollmentsSearchParams($event)" [hasResults]="hasResults"></app-patient-enrollments-search>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isLoading">
            <div fxFlex class="text-center">
                <h4>We are searching in a huge database. Please wait ...</h4>
            </div>
        </div>       
    </div>
    <div *ngIf="emptyUsername && hasResults">The username is missing for at least one of these records. Please log a bug that describes your search and filter criteria.</div> 
    <div fxLayout="row" [hidden]="!hasResults">
        <div fxFlex>
            <div class="example-container">
                <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear="true" (matSortChange)="onSortData($event)">

                    <ng-container matColumnDef="userName">
                        <mat-header-cell *matHeaderCellDef fxFlex="30%" >Username</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="30%">{{row.user?.username}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef fxFlex="15%">First Name</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="15%">{{row.user?.self.firstName}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef fxFlex="10%">Last Name</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="10%">{{row.user?.self.lastName}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="portal">
                        <mat-header-cell *matHeaderCellDef fxFlex="8%" mat-sort-header="portalName">Portal Name</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="8%">{{row.portalName}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef fxFlex="10%" mat-sort-header="status">Enrollment State</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="10%">{{row.status}} {{getError(row.errorCode)}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef fxFlex="10%" mat-sort-header="EnrollmentType">Enrollment Type</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="10%">{{row.patientEnrollmentType}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="startDateTime">
                        <mat-header-cell *matHeaderCellDef fxFlex="12%" mat-sort-header="startDateTime">Start Date Time</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="12%">{{row.startDateTime+'Z' | date:'MMM d, y, h:mm:ss a'}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef fxFlex="6%">Action</mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="6%">
                            <mat-icon (click)="showErrorDetail(row.enrollmentId)" matTooltip="View Errors">search</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

                </mat-table>
                
                <mat-paginator #paginator [hidden]="rowCount == 0" [pageSize]="pageSize" [length]="rowCount" [pageSizeOptions]="pageSizeOptions" (page)="onPaginationChange($event)"></mat-paginator>
                
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading && rowCount == 0">
        No Records to show!
    </div>
</section>
