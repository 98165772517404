import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatOption } from '@angular/material/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { User, CiamAuth, CiamPersonIdLevel, ServiceResponse } from '@dignity-health/ciam-auth';
import { UiService } from '../../../services/ui/ui.service';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-user-properties',
  templateUrl: './user-properties.component.html',
  styleUrls: ['./user-properties.component.scss']
})

export class UserPropertiesComponent implements OnInit {
  @Input() selectedUser: User;
  username: string;

  isULLoading: boolean;
  form: FormGroup;
  levels = CiamPersonIdLevel;
  currentLevel: string;
  needToSaveUserLevel: boolean;

  isTCLoading: boolean;
  termsAccepted: boolean;
  needToSaveTerms: boolean;

  isEVLoading: boolean;
  emailVerified: boolean;
  needToSaveEmailVerified: boolean;
  hasEditAccess: boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private ciamAuth: CiamAuth,
    public route: ActivatedRoute,
    private uiService: UiService,
    private snackBar: MatSnackBar,
    private oktaGroupService: OktaSupportGroupsService) { }

  ngOnInit() {
    if (this.selectedUser) {
      this.route.params.subscribe(params => {
        this.username = params['user'];
      });
    }

    this.form = this.fb.group({
      userLevel: ['']
    });

    this.getUserLevel();

    this.termsAccepted = this.selectedUser.isTermsAccepted;
    this.emailVerified = this.selectedUser.isVerified;
    this.hasEditAccess = this.oktaGroupService.isSuperAdmin;
  }


  async getUserLevel() {
    this.isULLoading = true;

    this.ciamAuth.httpApi.apiAdminUsersGetUserLevelGet(this.username).then(response => {
      const hasResult = <ServiceResponse<any>>response;
      this.isULLoading = false;
      this.form.controls.userLevel.setValue(this.levels[hasResult.result]);
      this.currentLevel = hasResult.result;
    });
  }

  async setUserLevel() {
    this.isULLoading = true;
    const selectedLevel = this.form.controls.userLevel.value;

    const response = await this.ciamAuth.httpApi.apiAdminUsersSetUserLevelPatch(this.username, selectedLevel);

    this.isULLoading = false;
    if (response && response.errors.length > 0) {
      return this.uiService.showErrors(response.errors, true, environment.ciamUrl + "admin/users/set-user-level");
    }

    if (response.isValid) {
      this.snackBar.open('User Level Updated Successfully!', null, { duration: 2500 });
      this.needToSaveUserLevel = false;
      this.currentLevel = selectedLevel;
    }
  }

  onULChange(changedValue: MatOption) {
    if (changedValue.value.toLowerCase() !== this.currentLevel.toLowerCase()) {
      this.needToSaveUserLevel = true;
    } else {
      this.needToSaveUserLevel = false;
    }
  }

  //////////////////////////////////////
  // Terms & Conditions
  //////////////////////////////////////
  async onTCChange(changedValue: MatLegacySlideToggleChange) {
    if (changedValue.checked !== this.selectedUser.isTermsAccepted) {
      this.needToSaveTerms = true;
    } else {
      this.needToSaveTerms = false;
    }
  }

  onSaveTerms() {
    this.saveTCChange(this.termsAccepted);
  }

  async saveTCChange(acceptTermsValue) {
    this.isTCLoading = true;
    this.ciamAuth.httpApi.apiTermsAndConditionsTosOperationPost(this.username, acceptTermsValue)
      .then(response => {
        this.isTCLoading = false;
        if (response.isValid) {
          this.uiService.openSnackbar('Terms and Conditions value updated successfully');
          this.needToSaveTerms = false;
          this.selectedUser.isTermsAccepted = acceptTermsValue;
        } else {
          this.uiService.showErrors(response.errors, true, environment.ciamUrl + "terms-and-conditions/tos-accept-reset");
        }
      })
      .catch(() => {
        this.isTCLoading = false;
        this.uiService.openSnackbar('Something went wrong while updating Terms and conditions');
      });
  }

  //////////////////////////////////////
  // Email Verified
  //////////////////////////////////////

  async onEVChange(changedValue: MatLegacySlideToggleChange) {
    if (changedValue.checked !== this.selectedUser.isVerified) {
      this.needToSaveEmailVerified = true;
    } else {
      this.needToSaveEmailVerified = false;
    }
  }

  onEmailValidationSave() {
    this.saveEVChange();
  }

  async saveEVChange() {
    this.isEVLoading = true;
    this.ciamAuth.httpApi.apiUsersEnrollmentVerificationPost(this.username, this.selectedUser.emailVerificationCode)
      .then(response => {
        if (!response.isValid) {
          this.uiService.showErrors(response.errors, true, environment.ciamUrl + "users/enrollment/verification");
          return;
        }
        this.isEVLoading = false;
        this.uiService.openSnackbar('Email validation updated successfully');
        this.needToSaveEmailVerified = false;
        this.selectedUser.isVerified = true;
      })
      .catch(() => {
        this.isEVLoading = false;
        this.uiService.openSnackbar('Something went wrong while updating Email validation');
      });
  }
}
