<section *ngIf="selectedUser">
    <div class="padding-top">

        <mat-form-field flex="">
            <mat-select placeholder="Show Me:" (selectionChange)="getUserAppointments()" [(value)]="selectedAppointmentStatus">
                <mat-option *ngFor="let status of availableAppointmentStatuses | enumKeyValueList" [value]="status.value">{{status.key}}</mat-option>
            </mat-select>
        </mat-form-field>

        <progress-bar [isLoading]="isLoading"></progress-bar>

        <div class="appointment-container padding-top">
            <mat-table [dataSource]="userAppointmentDataSource" *ngIf="isValid">
                <ng-container matColumnDef="appointmentId">
                    <mat-header-cell *matHeaderCellDef fxFlex="10%">Inquicker confirmation number</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="10%">{{row.inQuickerConfirmationNumber}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="gecbAppointmentId">
                    <mat-header-cell *matHeaderCellDef fxFlex="10%">GECB Appointment number</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="10%">{{row.gecbAppointmentId}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="providerName">
                    <mat-header-cell *matHeaderCellDef fxFlex="15%">Provider Name</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="15%">{{row.provider?.displayName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="facilityName">
                    <mat-header-cell *matHeaderCellDef fxFlex="15%">Facility Name</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="15%">{{row.facilityName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef fxFlex="20%">Location Address</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="20%">
                        {{row.facilityAddress?.city}} <br /> {{row.facilityAddress?.line1}} {{row.facilityAddress?.line2}}, {{row.facilityAddress?.stateOrProvince}} {{row.facilityAddress?.zipCode}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="appointmentType">
                    <mat-header-cell *matHeaderCellDef fxFlex="10%">Appointment Type</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="10%">{{row.appointmentTypeName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="locationPhone">
                    <mat-header-cell *matHeaderCellDef fxFlex="10%">Location Phone Number</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="10%"> {{ row.facilityPhoneNumber | phoneNumber}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="appointmentDate">
                  <mat-header-cell *matHeaderCellDef fxFlex="15%">Date of Appointment ({{timezoneAbbrevation}})</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="15%">{{row.appointmentStart | date: 'MM/dd/yyyy, h:mm a'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="appointmentStatus">
                    <mat-header-cell *matHeaderCellDef fxFlex="10%">Appointment Status</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="10%">{{row.appointmentStatus}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <div class="message-text" *ngIf="!isValid">
                {{messageText}}
            </div>
        </div>
    </div>
</section>
