import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'route-disabled',
  templateUrl: './route-disabled.component.html',
  styleUrls: ['./route-disabled.component.scss']
})
export class RouteDisabledComponent {
  constructor() { }
}
