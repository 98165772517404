<div  *ngIf="selectedUser">
  <mat-card>
    <mat-card-title>User Created</mat-card-title>
     <mat-card-content>
      {{selectedUser.createdDate | date:'MMM d, y, h:mm:ss a' }}
    </mat-card-content>
  </mat-card>
  
  <mat-card *ngIf="hasEditAccess">
    <mat-card-title>User Level</mat-card-title>
     <mat-card-content>
      <mat-spinner *ngIf="isULLoading" [diameter]="30"></mat-spinner>
      <form [formGroup]="form" novalidate (ngSubmit)="setUserLevel()" class="padding-top" *ngIf="!isULLoading">
        <mat-form-field flex="">
          <mat-select formControlName="userLevel" placeholder="User Level" (selectionChange) = "onULChange($event)" [disabled]="isULLoading">
            <mat-option *ngFor="let level of levels | enumKeyValueList;" [value]="level.key" [disabled]="level.key == 'Level1'|| level.key == 'Unknown'"> {{level.key}} </mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button class="submit-button" mat-raised-button color="primary" type="submit" *ngIf="needToSaveUserLevel"> Update User Level </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>DHome ID (Self ID)</mat-card-title>
     <mat-card-content>
      {{selectedUser.selfId}}
    </mat-card-content>
  </mat-card>
</div>