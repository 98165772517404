import { Component, Input, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatLegacyTableDataSource, MatLegacyTable } from '@angular/material/legacy-table';
import { MatLegacyPaginator, LegacyPageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Sort } from '@angular/material/sort';

import { Router } from '@angular/router';
import { CiamUser, Patient } from '@dignity-health/ciam-auth';
import { PagedResult } from '@dignity-health/ciam-auth/dist/src/graphql';
import { IAppState } from 'app/types';
import { PatientModel, SortInfo } from 'app/types/models';
import { GraphQLQuery } from 'app/types/graphql';
import { SEARCH_TYPE } from 'app/shared/enums/enums';
import { UiService } from 'app/services/ui/ui.service';
import { UsersDataSource } from 'app/types/users-datasource';
import { PatientSearchRequest } from 'app/types/patientSearchRequest';
import { UpdatePatientSearch, UpdateCernerSearch } from 'app/store/actions';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { UNPROCESSABLE_ENTITY_CODE, UNPROCESSABLE_ENTITY_MESSAGE } from 'app/types/constants';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const GET_CERNER_PATIENTS = `query getCernerPatients(
     $patientId: String
    , $firstName: String
    , $lastName: String
    , $mrn: String
    , $dateOfBirth: CustomDate
    , $pageSize: Int
    , $currentPage: Int
    , $sortColumn: String
    , $sortOrder: String
    , $exactSearch: Boolean
  ){
  searchCernerPatients(
    searchRequest: {
      patientId: $patientId,
      firstName: $firstName,
      lastName: $lastName
      mrn: $mrn,
      dateOfBirth: $dateOfBirth,
      sortColumn: $sortColumn,
      sortOrder: $sortOrder,    
      exactSearch: $exactSearch
  },sortInfo: {     
    sortColumn: $sortColumn,
    sortOrder: $sortOrder
  },
  pageInfo: { 
    pageSize: $pageSize,
    currentPage: $currentPage
  })
    { 
      currentPage
      pageSize
      firstRowOnPage      
      lastRowOnPage
      pageCount
      rowCount  
      results {
      id,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      domainName,
      accountType
    }
  }
}`;

const GET_PATIENTS = `query getPatients(
  $firstName: String
  , $lastName: String
  , $mrn: String
  , $dateOfBirth: CustomDate
  , $pageSize: Int
  , $currentPage: Int
  , $sortColumn: String
  , $sortOrder: String
  , $exactSearch: Boolean)
{
  searchPatients(
    searchRequest: {      
      firstName: $firstName,
      lastName: $lastName
      mrn: $mrn,
      dateOfBirth: $dateOfBirth,       
      exactSearch: $exactSearch
    },
    sortInfo: {     
      sortColumn: $sortColumn,
      sortOrder: $sortOrder
    },
    pageInfo: { 
      pageSize: $pageSize,
      currentPage: $currentPage
    }
  )  
  {
    currentPage
    pageSize
    firstRowOnPage      
    lastRowOnPage
    pageCount
    rowCount      
    results{        
      patientId
      firstName
      lastName
      dateOfBirth
      gender 
      sourceId     
      userPatientRelationships { 
        relationshipType          
        patientId
        user {
          userId
          username          
          self {              
            firstName
            lastName
            dateOfBirth
            gender
            idLevel                      
          }            
        }
      }
      patientMrns{
        patientMrnId
        mrn
      }
    }
  }
}`;

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})

export class PatientsComponent implements OnChanges {

  user: CiamUser;
  user$: Observable<CiamUser>;
  @Input() searchType: string;
  @ViewChild(MatLegacyTable) table: MatLegacyTable<PatientModel>;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  searchPatientsQuery: GraphQLQuery;
  patientSearchParams: PatientSearchRequest = <PatientSearchRequest>{
    patientId: '',
    mrn: '',
    lastName: '',
    firstName: '',
    dateOfBirth: null,
    isValidSearch: false
  };
  dataSource: MatLegacyTableDataSource<PatientModel>;

  rowCount = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  currentPage = 1;
  isLoading = false;
  noResultsMessage: string;
  hasResults = false;
  sortInfo: SortInfo = { sortColumn: '', sortOrder: 'asc' };
  myCareColumns = ['username', 'firstName', 'lastName', 'dateOfBirth', 'gender', 'accountType', 'sourceId'];
  cernerColumns = ['firstName', 'lastName', 'dateOfBirth', 'gender', 'accountType', 'personID', 'domainName', 'facilitiesVisited'];

  constructor(
    private router: Router
    , public dialog: MatLegacyDialog
    , private uiService: UiService
    , private store: Store<IAppState>
    , private usersDataSource: UsersDataSource
    , private cd: ChangeDetectorRef
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => { this.user = user; });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchType = changes.searchType.currentValue;
    this.clear(null);
    this.patientSearchParams = {
      patientId: '', dateOfBirth: null, firstName: '', isValidSearch: false, mrn: '', lastName: ''
      , accountType: '', isExactSearch: false
    }
  }

  onPaginateChange(LegacyPageEvent: LegacyPageEvent) {
    this.pageSize = LegacyPageEvent ? LegacyPageEvent.pageSize : this.pageSize;
    this.currentPage = LegacyPageEvent ? LegacyPageEvent.pageIndex + 1 : 1;
    this.setPatientSearchParams(this.patientSearchParams);
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.sortInfo = { sortColumn: sort.active, sortOrder: sort.direction };
    this.isLoading = true;
    if (this.searchType == SEARCH_TYPE.PATIENT_PORTALS) {
      this.setupMultiColumnSortForPortalUsers(sort);
      this.getPatients(this.patientSearchParams);
    }
    else if (this.searchType == SEARCH_TYPE.CERNER_MILLENIUM) {
      this.setupMultiColumnSortForCernerPatients(sort);
      this.getCernerPatients(this.patientSearchParams);
    }
  }

  // Get Patients
  getPatients(patientSearchParams: PatientSearchRequest) {
    this.usersDataSource.searchPatients({
      query: GET_PATIENTS,
      variables: {
        'pageSize': this.pageSize,
        'currentPage': this.currentPage,
        'sortOrder': this.sortInfo.sortOrder,
        'sortColumn': this.sortInfo.sortColumn,
        'mrn': patientSearchParams.mrn,
        'lastName': patientSearchParams.lastName,
        'firstName': patientSearchParams.firstName,
        'dateOfBirth': this.uiService.turnLocalDateIntoUtcDate(patientSearchParams.dateOfBirth),
        'exactSearch': patientSearchParams.isExactSearch
      }
    })
      .then(response => {
        this.isLoading = false;

        // Handle the errors elegantly.      
        if (response && response.errors && response.errors?.length) {
          this.uiService.showErrors(response.errors, true, environment.patientPortalsUrl + 'graphql/query/getPatients');
        }

        if (response && response.data && response.data.searchPatients && response.data.searchPatients.results) {
          if (response.data.searchPatients.results?.length == 0) {
            this.noResultsMessage = 'No results found';
            this.hasResults = false;
            this.rowCount = 0;
            return;
          }

          let patients = this.toPatientModelArray(response.data.searchPatients.results);
          const pagedResult = <PagedResult<PatientModel>>(response.data.searchPatients);

          this.rowCount = pagedResult.rowCount;
          this.pageSize = pagedResult.pageSize;
          this.currentPage = pagedResult.currentPage;

          this.dataSource = new MatLegacyTableDataSource<PatientModel>(<PatientModel[]>(patients));
          this.noResultsMessage = '';
          this.hasResults = true;
          return;
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  // Get Cerner Patients
  getCernerPatients(patientSearchParams: PatientSearchRequest) {
    this.usersDataSource.searchPatients({
      query: GET_CERNER_PATIENTS,
      variables: {
        'pageSize': this.pageSize,
        'currentPage': this.currentPage,
        'sortOrder': this.sortInfo.sortOrder,
        'sortColumn': this.sortInfo.sortColumn,
        'patientId': patientSearchParams.patientId,
        'mrn': patientSearchParams.mrn,
        'lastName': patientSearchParams.lastName,
        'firstName': patientSearchParams.firstName,
        'dateOfBirth': this.uiService.turnLocalDateIntoUtcDate(patientSearchParams.dateOfBirth),
        'exactSearch': patientSearchParams.isExactSearch
      }
    })
      .then(response => {
        this.isLoading = false;
        if (response && response.errors && response.errors.length) {
          if (_.find(response.errors, { message: UNPROCESSABLE_ENTITY_CODE })) {
            this.noResultsMessage = UNPROCESSABLE_ENTITY_MESSAGE;
            this.hasResults = false;
            return;
          }
          return this.uiService.showErrors(response.errors, true, environment.patientPortalsUrl + 'graphql/query/getCernerPatients');
        }

        if (response && response.data && response.data.searchCernerPatients && response.data.searchCernerPatients.results) {
          if (response.data.searchCernerPatients?.length === 0) {
            this.noResultsMessage = 'No results found';
            this.hasResults = false;
            this.rowCount = 0;
            return;
          }

          const pagedResult = <PagedResult<PatientModel>>(response.data.searchCernerPatients);
          this.rowCount = pagedResult.rowCount;
          this.pageSize = pagedResult.pageSize;
          this.currentPage = pagedResult.currentPage;
          this.dataSource = new MatLegacyTableDataSource<PatientModel>(<PatientModel[]>(response.data.searchCernerPatients.results));
          this.noResultsMessage = '';
          this.hasResults = true;
          return;
        }
      }).catch(error => {
        console.log(error);
        this.isLoading = false;
      });
  }

  setPatientSearchParams(patientSearchParams: PatientSearchRequest) {
    this.noResultsMessage = '';

    if (patientSearchParams.firstName == '' && patientSearchParams.lastName == '' && patientSearchParams.dateOfBirth == null && patientSearchParams.mrn == '') {
      this.isLoading = false;
      return;
    }

    if (!patientSearchParams.isValidSearch && patientSearchParams.patientId == "") {
      this.isLoading = false;
      this.noResultsMessage = 'Try searching with a combination of at least two parameters.';
      this.hasResults = false;
      return;
    }

    if (!patientSearchParams.isValidSearch && patientSearchParams.firstName == "" && patientSearchParams.lastName == "" && patientSearchParams.mrn === undefined && patientSearchParams.dateOfBirth == null) {
      this.isLoading = false;
      this.noResultsMessage = 'Try searching with a combination of at least two parameters.';
      this.hasResults = false;
      return;
    }

    if (!patientSearchParams.isExactSearch && (patientSearchParams.firstName?.length > 0 && patientSearchParams.firstName?.length < 3)) {
      this.isLoading = false;
      this.noResultsMessage = 'Minimum 3 characters are required for First Name search field.';
      this.hasResults = false;
      return;
    }

    if (!patientSearchParams.isExactSearch && (patientSearchParams.lastName?.length > 0 && patientSearchParams.lastName?.length < 3)) {
      this.isLoading = false;
      this.noResultsMessage = 'Minimum 3 characters are required for Last Name search field.';
      this.hasResults = false;
      return;
    }

    this.isLoading = true;
    this.patientSearchParams = patientSearchParams;

    if (this.searchType == SEARCH_TYPE.PATIENT_PORTALS) {
      this.store.dispatch(new UpdatePatientSearch(Object.assign({}, this.patientSearchParams)));
      this.getPatients(patientSearchParams);
    } else if (this.searchType == SEARCH_TYPE.CERNER_MILLENIUM) {
      this.store.dispatch(new UpdateCernerSearch(Object.assign({}, this.patientSearchParams)));
      this.getCernerPatients(patientSearchParams);
    }
  }

  goToViewUserProfile(username: string) {
    this.router.navigate(['/users/view', { user: username }]);
  }

  goToFacilitiesVisit(patientId: string) {
    this.router.navigate(['/facilitiesVisit', patientId]);
  }

  toPatientModelArray(patientsResult: PagedResult<Patient>): PatientModel[] {
    let patients: PatientModel[] = [];

    _.forEach(patientsResult, function (patientResult: any) {
      _.forEach(patientResult.userPatientRelationships, function (userPatient) {
        let patient: PatientModel = {};
        if (userPatient.user != null) {
          patient.username = userPatient.user.username;
        }
        patient.accountType = userPatient.relationshipType.toString().toUpperCase();
        patient.firstName = patientResult.firstName;
        patient.lastName = patientResult.lastName;
        patient.dateOfBirth = patientResult.dateOfBirth;
        patient.gender = patientResult.gender.toString().toUpperCase();
        patient.sourceId = patientResult.sourceId;
        patients.push(patient);
      });
    })

    return patients;
  }

  setupMultiColumnSortForCernerPatients(sort: Sort) {
    var sortOnColumns = '';
    switch (sort.active) {
      case "FirstName":
        sortOnColumns = "FirstName, LastName, DateOfBirth, Gender";
        break;
      case "LastName":
        sortOnColumns = "LastName, FirstName, DateOfBirth, Gender";
        break;
      case "DateOfBirth":
        sortOnColumns = "DateOfBirth, LastName, FirstName, Gender";
        break;
      case "Gender":
        sortOnColumns = "Gender, LastName, FirstName, DateOfBirth";
        break;
      case "PersonId":
        sortOnColumns = "Id, LastName, FirstName, DateOfBirth, Gender";
        break;
      case "DomainName":
        sortOnColumns = "DomainName, LastName, FirstName, DateOfBirth, Gender";
        break;
    }
    this.sortInfo = { sortColumn: sortOnColumns, sortOrder: sort.direction };
  }

  setupMultiColumnSortForPortalUsers(sort: Sort) {
    var sortOnColumns = '';
    switch (sort.active) {
      case "Username":
        sortOnColumns = "Username, LastName, FirstName, DateOfBirth, Gender";
        break;
      case "FirstName":
        sortOnColumns = "FirstName, LastName, DateOfBirth, Gender";
        break;
      case "LastName":
        sortOnColumns = "LastName, FirstName, DateOfBirth, Gender";
        break;
      case "DateOfBirth":
        sortOnColumns = "DateOfBirth, LastName, FirstName, Gender";
        break;
      case "Gender":
        sortOnColumns = "Gender, LastName, FirstName, DateOfBirth";
        break;
      case "SourceId":
        sortOnColumns = "SourceId, LastName, FirstName, DateOfBirth, Gender";
        break;
    }
    this.sortInfo = { sortColumn: sortOnColumns, sortOrder: sort.direction };
  }

  clear(evt: any): void {
    this.isLoading = false;
    if (this.dataSource) {
      this.dataSource.data = [];
    } else {
      this.dataSource = new MatLegacyTableDataSource<PatientModel>(<PatientModel[]>([]));
    }
    this.noResultsMessage = '';
    this.sortInfo.sortOrder = 'asc';
    this.sortInfo.sortColumn = '';
    this.hasResults = false;
  }
}
