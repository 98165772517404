<section class="page-content" *ngIf="user">
  <div class="search-component" fxLayoutGap="10px">
    <mat-form-field>
      <input matInput type="text" (input)="valueChange()" [(ngModel)]="errorCode" [ngModelOptions]="{standalone: true}"
        placeholder="Error Code">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" (input)="valueChange()" [(ngModel)]="username" [ngModelOptions]="{standalone: true}"
        placeholder="Username">
    </mat-form-field>

    <mat-form-field>
      <input matInput [matDatepicker]="fromDatePicker" placeholder="From date" name="fromDate" #startDate="ngModel"
        [(ngModel)]="fromDate" [max]="maxDate" (dateInput)="valueChange()" (dateChange)="valueChange()">
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker" (selectionChange)="valueChange()"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput [matDatepicker]="toDatePicker" placeholder="To date" name="toDate" [(ngModel)]="toDate"
        [max]="maxDate" [min]="fromDate" (dateInput)="valueChange()" (dateChange)="valueChange()">
      <mat-datepicker-toggle matSuffix [for]="toDatePicker" (selectionChange)="valueChange()"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>
    </mat-form-field>

    <button class="search-button" [disabled]="disableSearch" mat-raised-button [color]="'primary'"
      (click)="performSearch()">
      <mat-icon>search</mat-icon> Search
    </button>
  </div>

  <progress-bar [isLoading]="isLoading"></progress-bar>
  <div class="portal-logs-grid-container">
    <mat-table [dataSource]="dataSourcePortalLogs">
      <ng-container matColumnDef="errorCode">
        <mat-header-cell *matHeaderCellDef fxFlex="20%">Error Code</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="20%">{{row.errorCode}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="message">
        <mat-header-cell *matHeaderCellDef fxFlex="35%">Error Message</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="35%">{{row.message}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef fxFlex="25%">Username</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="25%">{{row.username}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef fxFlex="20%">Timestamp <br /> ({{timezoneAbbrevation}})</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="20%">{{row.timestamp | date:'medium'}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div *ngIf="rowCount > 0">
    <mat-paginator #paginator [pageSize]="pageSize" [length]="rowCount" [pageSizeOptions]="pageSizeOptions"
      (page)="onPaginationChange($event)">
    </mat-paginator>
  </div>
  <div class="no-record-found" *ngIf="displayNoRecordFoundLabel">
    No support log found!
  </div>
</section>