import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'event-replay-engine',
  templateUrl: './replay-engine.component.html',
  styleUrls: ['./replay-engine.component.scss']
})

export class ReplayEngineComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
