import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filterUserLevel'
})

export class FilterUserLevelPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    value = value || {};
    const keys = Object.getOwnPropertyNames(value);

    args.forEach(arg => {
      return _.pull(keys, arg);
    });

    return _(keys)
      .filter(key => isNaN(Number(key)))
      .uniqBy(key => key.toLocaleLowerCase())
      .map(key => { return { key: key, value: value[key] } })
      .value();
  }
}
