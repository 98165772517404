import { Injectable } from "@angular/core";
import { environment } from 'environments/environment';
import { CiamAuth } from '@dignity-health/ciam-auth';
import * as consts from '../../types/constants';
import { Route } from "@angular/router";

const flags = environment.featureFlags;

interface RouteType {
  path: string,
  canTier1Access: boolean,
  canTier2Access: boolean,
  canTier3Access: boolean
}

@Injectable()
export class OktaSupportGroupsService {
  constructor(private ciamAuth: CiamAuth) {

  }

  isTier1SupportGroup: boolean;
  isTier2SupportGroup: boolean;
  isTier3SupportGroup: boolean;
  isSuperAdmin: boolean = true;

  private resetGroups() {
    this.isTier1SupportGroup = false;
    this.isTier2SupportGroup = false;
    this.isTier3SupportGroup = false;
    this.isSuperAdmin = false;
    this.hideNavigationsforTier1SupportUserGroup(true);
    this.hideNavigationsforTier2SupportUserGroup(true);
  }

  permissionMatrixForOktaGroups: RouteType[] = [
    // Accessible to Tier1 
    { path: 'login', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'entities/facilities', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'users-dignity-health', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'users-cerner-millennium', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'users-patient-portals', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'users-consumer-identity', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'identity-audit-logs', canTier1Access: true, canTier2Access: true, canTier3Access: true },

    { path: 'patient/enrollments', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'cerner-invitations', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    // Accessible to Tier2 and Tier 3
    { path: 'terms-and-conditions', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'groups', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'groups/create', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'support-log-viewer', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'entities/bargroups', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'entities/regions', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'entities/empi-hubs', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    // Accessible to Super Admin only 
    { path: 'entities/bargroup-facilities', canTier1Access: false, canTier2Access: false, canTier3Access: true },
    { path: 'instructions', canTier1Access: false, canTier2Access: false, canTier3Access: true },
    { path: '', canTier1Access: true, canTier2Access: true, canTier3Access: true }
  ];

  parameterizedRoutes: RouteType[] = [
    { path: 'users/view', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'terms-and-conditions/create', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'terms-and-conditions/create', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'terms-and-conditions/view', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'groups/view', canTier1Access: false, canTier2Access: true, canTier3Access: true },
    { path: 'patient/enrollment-errors', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: 'facilitiesVisit', canTier1Access: true, canTier2Access: true, canTier3Access: true },
    { path: '?code', canTier1Access: true, canTier2Access: true, canTier3Access: true }
  ];

  async checkOktaGroup(groups: string[]) {
    this.resetGroups();
    if (!groups) {
      return;
    }

    if (groups.includes(consts.SUPER_ADMIN_GROUP_NAME)) {
      this.isSuperAdmin = true;
      return;
    }

    if (groups.includes(consts.TIER_3_SUPPORT_GROUP_NAME)) {
      this.isTier3SupportGroup = true;
      return;
    }

    if (groups.includes(consts.TIER_2_SUPPORT_GROUP_NAME)) {
      this.isTier2SupportGroup = true;
      this.hideNavigationsforTier2SupportUserGroup(false);
      return;
    }

    if (groups.includes(consts.TIER_1_SUPPORT_GROUP_NAME)) {
      this.isTier1SupportGroup = true;
      this.hideNavigationsforTier1SupportUserGroup(false);
    }
  }

  isAuthorizedRoute(url: string): boolean {   
    if (this.isSuperAdmin || this.isTier3SupportGroup) {
      // As Super Admin has access to whole application.
      return true;
    }
    const listedNonParameterizedRoute = this.checkListedRoutePermission(url);

    if (listedNonParameterizedRoute) {
      return true;
    }

    const parameterizedRoute = this.parameterizedRoutes.find(item => url.includes(item.path));
    if (!parameterizedRoute) {
      return false;
    }

    return this.validRoute(parameterizedRoute);
  }


  private checkListedRoutePermission(url: string): boolean {
    const definedRoute = this.permissionMatrixForOktaGroups.find(item => item.path == url);
    if (!definedRoute) {
      return false;
    }

    return this.validRoute(definedRoute)
  }

  private validRoute(route: RouteType): boolean {
    return (this.isTier2SupportGroup && route.canTier2Access) ||
      (this.isTier1SupportGroup && route.canTier1Access);
  }

  hideNavigationsforTier1SupportUserGroup(value: boolean) {
    flags.navigation.termsAndConditions.create = value;
    flags.navigation.termsAndConditions.list = value;
    flags.navigation.termsAndConditions.view = value;
    flags.navigation.entities.bargroup = value;
    flags.navigation.entities.region = value;
    flags.navigation.entities.empihub = value;
    flags.navigation.entities.bargroupFacilities.create = value;
    flags.navigation.instructions.create = value;
    flags.navigation.enterpriseEvents.view = value;
  }

  hideNavigationsforTier2SupportUserGroup(value: boolean) {
    // Add navigations to which Tier 2 User is unauthorized.
    flags.navigation.entities.bargroupFacilities.create = value;
    flags.navigation.instructions.create = value;
    flags.navigation.enterpriseEvents.view = value;
  }
}
