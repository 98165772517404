import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { CiamUser } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';
import { IAppState } from '../../types/state';
import { PortalSupportEvent } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { PagedResult } from '../../types/graphql';
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent, MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { PortalLogViewerService } from 'app/services/portal-logs/portal-log-viewer';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as moment from 'moment-timezone';
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';

@Component({
  selector: 'support-log-viewer',
  templateUrl: './support-log-viewer.component.html',
  styleUrls: ['./support-log-viewer.component.scss']
})

export class SupportLogViewerComponent implements OnInit {
  isLoading: boolean = false;
  fromDate: Date;
  toDate: Date;
  username: string;
  errorCode: string;
  user: CiamUser;
  user$: Observable<CiamUser>;
  currentPage: number;
  pageSize: number;
  rowCount: number;
  pageSizeOptions: number[] = [10, 20, 50, 100];
  pagedResult: PagedResult<PortalSupportEvent[]>;
  @ViewChild('paginator') paginator: MatLegacyPaginator;
  maxDate = new Date();

  dataSourcePortalLogs: MatLegacyTableDataSource<PortalSupportEvent>;
  displayedColumns = ["errorCode", "message", "username", "timestamp"];
  @ViewChild(MatLegacyTable) table: MatLegacyTable<PortalSupportEvent>;
  showAdvancedSearch: boolean = false;
  timezoneAbbrevation: string;
  displayNoRecordFoundLabel: boolean = false;
  disableSearch: boolean = false;

  constructor(
    private router: Router,
    private uiService: UiService,
    private route: ActivatedRoute,
    private ciamAuth: CiamAuth,
    private oidcSecurityService: OidcSecurityService,
    private portalLogViewerService: PortalLogViewerService,
    private sharedSearchService: SharedSearchService,
    private store: Store<IAppState>) {
    this.user$ = store.select(s => s.user)
    this.user$.subscribe(user => this.user = user)

    this.sharedSearchService.resetObservable
      .subscribe((resetValue) => {
        if (resetValue) {
          this.username = '';
          this.errorCode = '';
          this.toDate = new Date();
          this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
        }
      });
  }

  ngOnInit() {
    this.toDate = new Date();
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
    this.getUserName();
    this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      this.username = existingPatientSearchDataOnPortal[0].userName;
      this.errorCode = existingPatientSearchDataOnPortal[0].errorCode;
      if (existingPatientSearchDataOnPortal[0].supportLogfromDate != undefined)
        this.fromDate = existingPatientSearchDataOnPortal[0].supportLogfromDate;
      if (existingPatientSearchDataOnPortal[0].supportLogToDate != undefined)
        this.toDate = existingPatientSearchDataOnPortal[0].supportLogToDate;
    }
  }

  getUserName() {
    this.route.params.subscribe(params => {
      this.username = params['username'];
      if (this.username != undefined) {
        this.showAdvancedSearch = true;
        this.fromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
      }
      this.oidcSecurityService.isAuthenticated$.subscribe(authenticated => {
        if (authenticated) {
          //this.performSearch();
        }
      })
    });
  }

  async refreshSupportEventData() {
    this.isLoading = true;
    const pagedPortalLogs: PagedResult<PortalSupportEvent[]> = await this.portalLogViewerService.getPortalLogs(this.currentPage, this.pageSize, this.username, this.errorCode, this.fromDate, this.toDate);
    try {
      this.dataSourcePortalLogs = new MatLegacyTableDataSource(pagedPortalLogs.results as PortalSupportEvent[]);
      this.rowCount = pagedPortalLogs.rowCount;
      if (this.rowCount == 0) {
        this.displayNoRecordFoundLabel = true;
      }
      this.disableSearch = false;
    } catch (errors) {
      return;
    } finally {
      this.isLoading = false;
    }
  }

  valueChange() {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].userName = this.username.trim();
      existingPatientSearchDataOnPortal[0].errorCode = this.errorCode.trim();
      existingPatientSearchDataOnPortal[0].supportLogfromDate = this.fromDate;
      existingPatientSearchDataOnPortal[0].supportLogToDate = this.toDate;
      if (this.username.trim() != '') {
        existingPatientSearchDataOnPortal[0].basicUserSearchText = '';
      }
    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.userName = this.username;
      newPatientSearch.errorCode = this.errorCode;
      newPatientSearch.supportLogfromDate = this.fromDate;
      newPatientSearch.supportLogToDate = this.toDate;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
  }

  performSearch() {
    this.disableSearch = true;
    this.resetPagination();
    this.refreshSupportEventData();
  }

  resetPagination() {
    this.currentPage = 1;
    this.pageSize = 10;
    this.rowCount = 0;
  }

  onPaginationChange(LegacyPageEvent: LegacyPageEvent) {
    this.pageSize = LegacyPageEvent == null ? this.pageSize : LegacyPageEvent.pageSize;
    this.currentPage = LegacyPageEvent == null ? this.currentPage : LegacyPageEvent.pageIndex + 1;
    this.refreshSupportEventData();
  }

  formatStackTrace(traceJson: string): string {
    return JSON.stringify(JSON.parse(traceJson), null, 2);
  }

  toggleAdvancedSearchControls() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    if (!this.showAdvancedSearch) {
      this.resetAdvancedSearchParams();
    }
  }

  clearSearch() {
    this.showAdvancedSearch = false;
    this.resetAdvancedSearchParams();
  }

  resetAdvancedSearchParams(): void {
    this.username = '';
    this.errorCode = '';
  }
}
