import { User } from '@dignity-health/ciam-auth';
import { UPDATE_PARENT_USER } from '../actions/parentUser';

export function parentUser(state: User, { type, payload }) {
  switch (type) {
    case UPDATE_PARENT_USER:
      return payload;

    default:
      return state;
  }
}
