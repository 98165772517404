import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import * as moment from 'moment-timezone';
import { IdentityService } from 'app/services/identity-logs/identity-service';
import { AuditLogs, ResponseWrap, ErrorDetails } from "app/types/user-models/identity-api-user.model";
import { UiService } from 'app/services/ui/ui.service';
import { ServiceResponseError } from '@dignity-health/ciam-auth';
import { CiamAuth } from "@dignity-health/ciam-auth";
import { environment } from '../../../environments/environment';
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';

@Component({
  selector: 'identity-audit-logs',
  templateUrl: './identity-audit-logs.component.html',
  styleUrls: ['./identity-audit-logs.component.scss']
})
export class IdentityAuditLogsComponent implements OnInit {
  email: string;
  fromDate: Date;
  toDate: Date;
  maxAllowedDate = new Date();   // Do not allow setting dates in future.

  showEmailError: boolean = false;
  showDatesRangeError: boolean = false;
  isLoading = false;

  timezoneAbbrevation: string;
  displayedColumns = ["eventdatetime", "eventtype", "createdBy", "email", "changelog"];
  dataSourceAuditLogs: MatLegacyTableDataSource<AuditLogs[]> = null;
  dataGridMessage: string = '';
  rowCount: number = 0;

  constructor(private uiService: UiService, private identityService: IdentityService, private ciamAuth: CiamAuth,
    private sharedSearchService: SharedSearchService) {
    this.sharedSearchService.resetObservable
      .subscribe((resetValue) => {
        if (resetValue) {
          this.email = '';
          this.toDate = new Date();
          this.fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
        }
      });
  }

  ngOnInit(): void {
    this.email = '';
    this.toDate = new Date();
    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 30));

    this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      this.email = existingPatientSearchDataOnPortal[0].userName;
      if (existingPatientSearchDataOnPortal[0].auditLogFromDate != undefined)
        this.fromDate = existingPatientSearchDataOnPortal[0].auditLogFromDate;
      if (existingPatientSearchDataOnPortal[0].auditLogToDate != undefined)
        this.toDate = existingPatientSearchDataOnPortal[0].auditLogToDate;
    }
    this.performLogSearch();
  }

  makeBoundaryDate(inputDate: Date, addDays: number = 0): Date {
    let date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + addDays);
    return date;
  }

  valueChange() {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].userName = this.email?.trim();
      existingPatientSearchDataOnPortal[0].auditLogFromDate = this.fromDate;
      existingPatientSearchDataOnPortal[0].auditLogToDate = this.toDate;
      if (this.email != '')
        existingPatientSearchDataOnPortal[0].basicUserSearchText = '';

    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.userName = this.email;
      newPatientSearch.auditLogFromDate = this.fromDate;
      newPatientSearch.auditLogToDate = this.toDate;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
  }

  performLogSearch() {
    var searchBlocked = false;
    this.dataSourceAuditLogs = null;
    this.dataGridMessage = '';
    if (!this.checkEmail()) {
      this.showEmailError = true;
      searchBlocked = true;
    }
    if (!this.checkDatesRange()) {
      this.showDatesRangeError = true;
      searchBlocked = true;
    }

    if (searchBlocked) {
      return;
    }


    this.showDatesRangeError = false;
    this.isLoading = true;
    this.identityService.getAuditLogs(this.makeBoundaryDate(this.fromDate), this.makeBoundaryDate(this.toDate, 1), this.email?.trim()).then(response => {
      this.isLoading = false;
      switch (response.status) {
        case 204:
          this.dataGridMessage = "No records to show."
          this.isLoading = false;
          break;
        case 200:
          response.json()
            .then(json => {
              this.rowCount = json.length;
              this.dataSourceAuditLogs = JSON.parse(JSON.stringify(<AuditLogs[]>json));
              this.isLoading = false;
            });
          break;
        default:
          {
            var serviceResError: ServiceResponseError = new ErrorDetails();
            if (response.status != 422) {
              serviceResError.code = response.status.toString();
              serviceResError.message = "Something went wrong! Please contact Administrator.";
            }
            else {
              response.json()
                .then(json => {
                  let response = JSON.parse(JSON.stringify(<ResponseWrap>json));
                  serviceResError.code = response.status;
                  for (let key in response.errors) {
                    let value = response.errors[key];
                    serviceResError.message = value;
                  }
                });
            }
            var errorsArray: ServiceResponseError[] = [serviceResError];
            this.isLoading = false;
            this.uiService.showErrors(errorsArray, true, environment.identityApiUrl + "v1/UserProfile/GetAuditLogs");
            break;
          }
      }
    });
  }

  private checkEmail(): boolean {
    var emailToCheck = this.email?.trim();
    return (emailToCheck === undefined) || (emailToCheck === '') || (emailToCheck?.includes('@') && emailToCheck?.length >= 6 && emailToCheck?.length <= 100);
  }

  private checkDatesRange(): boolean {
    var timeDiff = this.toDate.getTime() - this.fromDate.getTime();
    var daysDiff = timeDiff / (1000 * 3600 * 24);
    return daysDiff >= 0 && daysDiff < 31;
  }

  onEmailKeyUp(event: any) {
    // The key up even only resets the email error condition.
    if (this.checkEmail() && this.showEmailError) {
      this.showEmailError = false;
    }
    this.dataGridMessage = '';
  }

  onDatesRangeChange(): void {
    this.showDatesRangeError = !this.checkDatesRange();
    this.dataGridMessage = '';
  }
}