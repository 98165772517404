import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetPasswordResponse } from 'app/types/okta/resetPasswordResponse';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from '../http-error-handler.service';
import { UiService } from '../ui/ui.service';
import { StsServerResponse } from 'app/types/okta/StsServerResponse';
import { map } from 'rxjs/operators';
import { AuthModule, StsConfigHttpLoader, StsConfigLoader, LogLevel, OpenIdConfigLoader,OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class OktaApiService {
  private readonly dev: string = 'dev';
  private readonly stg: string = 'stg';
  private readonly prd: string = 'prd';
  private errorHandler: HandleError;
  readonly passwordRecoveryEmailUrl: string = `${environment.oktaBaseUrl}/api/v1/authn/recovery/password`;

  constructor(public http: HttpClient,
    private httpErrorHandler: HttpErrorHandler,
    private uiService: UiService) {
    this.errorHandler = httpErrorHandler.createHandleError('OktaApiService');
  }

  apiAdminUsersResetUserPasswordPost(username: string): Observable<ResetPasswordResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      username,
      factorType: environment.factorType
    };

    return this.http.post<ResetPasswordResponse>(this.passwordRecoveryEmailUrl, JSON.stringify(body), httpOptions)
      .pipe(
        catchError(
          this.errorHandler<ResetPasswordResponse>('UsersResetPassword', {
            status: 'fail',
            factorResult: 'fail',
            factorType: 'fail',
            recoveryType: 'fail'
          })
        )
      )
  }

  public displayEnvironmentName(envName: string): string {
    envName = envName.toLowerCase();
    if (envName.includes(this.dev)) {
      return this.dev;
    }
    else if (envName.includes('slot2tst')) {
      return 'qa';
    }
    else if (envName.includes(this.stg)) {
      return this.stg;
    }
    else if (envName.includes(this.prd)) {
      return this.prd;
    }
    else {
      return null;
    }
  }
}
