<section class="page-content">
    <mat-progress-bar mode="indeterminate" *ngIf="showLoadder"></mat-progress-bar>

    <div class="mat-elevation-z8" *ngIf="selectedUser" [class.div-border]="!isMyHomeUser">
        <mat-card>
            <mat-card-content>
                <div fxLayout="row">
                    <button mat-raised-button color="primary" class="create-button-margin" (click)="BackToUsers()">Go
                        Back to
                        Users</button>
                </div>
                <div fxLayout="row" *ngIf="personId">
                    Go Back to: <a href="javascript:void(0)" (click)="BackToParent()"><b>{{parentUser.firstName}}
                            {{parentUser.lastName}}</b></a>
                </div>

                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <div fxFlex="25%">
                        <app-user-profile-image-section fxFlex fxFlexFill [userProfileImage]="userProfileImage">
                        </app-user-profile-image-section>
                    </div>
                    <div fxFlex>
                        <div fxLayout="column">
                            <div fxFlex fxFlexFill>
                                <h4>{{selectedUser.firstName}} {{selectedUser.lastName}}</h4>
                                <div *ngIf="selectedUser.preferredName != null"><i>(Preferred:
                                        {{selectedUser.preferredName}})</i></div>
                            </div>
                            <div fxFlex fxFlexFill>
                                <strong>{{username}}</strong>
                                <!--hiding this edit icon to support User Story 72909: My Help: Remove ability to edit email address-->
                                <i *ngIf="username != ''" [hidden]="true" class="material-icons update-button-margin"
                                    (click)="OpenUpdateUsernameModal()">
                                    border_color
                                </i>
                            </div>
                            <div fxFlex fxFlexFill *ngIf="isMyHomeUser">
                                <button mat-button color="accent" (click)="goToSupportLogViewer()">
                                    <mat-icon>history</mat-icon> View Logs and Errors (default last 2 years)
                                </button>
                            </div>
                        </div>
                    </div>
                    <div fxFlex class="div-width" *ngIf="isMyHomeUser">
                        <div fxLayout="column">
                            <div fxFlex fxFlexFill>
                                <div class="fieldset">
                                    <div class="legend">Password</div>
                                    <div class="button-alignment">
                                        <div class="margin-bottom"><i>Last Updated:
                                                {{selectedUser.lastPasswordChangedDateTime | date:'MMM d, y, h:mm:ss a'}} ({{timezoneAbbrevation}})</i></div>
                                        <app-reset-user-password [username]="username"></app-reset-user-password>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>

                <div fxLayout="row">
                    <div fxFlex fxFlexFill>
                        <mat-tab-group (selectedTabChange)="getCurrentTabIndex($event)" animationDuration="0ms">
                            <mat-tab label="Profile">
                                <app-user-profile-overview [selectedUser]="selectedUser" [isMyHomeUser]="isMyHomeUser">
                                </app-user-profile-overview>
                            </mat-tab>
                            <mat-tab label="Application Access">
                                <app-user-profile-groups [selectedUser]="selectedUser" [isMyHomeUser]="isMyHomeUser"
                                    [(showOktaGroupsLoading)]="showOktaGroupsLoading"
                                    (getProfileDetails)="getUpdatedProfileData();">
                                </app-user-profile-groups>
                            </mat-tab>
                            <mat-tab label="Appointments" *ngIf="isMyHomeUser">
                                <app-user-appointments [selectedUser]="selectedUser"></app-user-appointments>
                            </mat-tab>
                            <mat-tab label="Associated Accounts">
                                <app-user-associated-account-profile
                                    *ngIf="(!username && tabIndex == 2) || tabIndex == 3" [selectedUser]="selectedUser"
                                    [isMyHomeUser]="isMyHomeUser" [parentUserId]="parentUser.userId">
                                </app-user-associated-account-profile>
                            </mat-tab>
                            <mat-tab label="Properties">
                                <app-user-properties [selectedUser]="selectedUser"></app-user-properties>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</section>