<section *ngIf="patientDependent">
  <mat-card>
    <div fxLayout="row">
      <div fxFlex="100%" fxLayoutGap="0.25em">
        <mat-card-title>Patient Dependent Details</mat-card-title>
        <mat-card-content>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <p><strong>First Name :</strong> {{patientDependent.firstName}}</p>
            </div>
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Last Name :</strong> {{patientDependent.lastName}}</p>              
            </div>
          </div>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Date Of Birth :</strong> {{patientDependent.dateOfBirth | utcDate | date }}</p>
            </div>
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Cerner Person ID :</strong> {{patientDependent.sourceId}}</p>
            </div>
          </div>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Gender :</strong> {{patientDependent.gender | capitalize}}</p>
            </div>
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Relationship Type :</strong> {{relationshipType}} </p>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
    <hr>
    <div fxLayout="row" *ngIf="patientDependent.address">
      <div fxFlex="100%" fxLayoutGap="0.25em">
        <mat-card-title>Address Details</mat-card-title>
        <mat-card-content>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Address :</strong> {{patientDependent.address.line1}}</p>
            </div>
            <div fxLayout="column" fxFlex="50%">
              <p><strong>City : </strong> {{patientDependent.address.city}}</p>
            </div>
          </div>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <p><strong>State / Province : </strong> {{patientDependent.address.stateOrProvince}}</p>
            </div>
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Zip Code : </strong> {{patientDependent.address.zipCode}}</p>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
    <hr>
    <div fxLayout="row" *ngIf="patientDependent.domain">
      <div fxFlex="100%" fxLayoutGap="0.25em">
        <mat-card-title>Domain Details</mat-card-title>
        <mat-card-content>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <p><strong>Domain Name :</strong> {{patientDependent.domain.name}}</p>
            </div>
            <div fxLayout="column" fxFlex="50%" *ngIf="patientDependent.domain.portal">
              <p><strong>Portal Name : </strong> {{patientDependent.domain.portal.name}}</p>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
    <div fxLayout="row">      
       <action-confirmation 
          class="float-left" 
          [actionConfirmation]="severAccessConfirmation" 
          (userResponse)="severUserAccess($event, true)">
        </action-confirmation> 
        <a mat-button class="mat-button-style" (click)="viewAssociatedAccountProfile(username)">View {{displayAssociateFirstName()}}'s account</a>     
    </div>
  </mat-card>
  <div class="padding-top">
    <progress-bar [isLoading]="isLoading"></progress-bar>
  </div>
</section>
