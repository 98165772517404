<section *ngIf="selectedUser">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="padding-top" fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <material-form-input [validationErrors]="validationErrors" [name]="'firstName'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="First Name" formControlName="firstName" />
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'lastName'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="Last Name" formControlName="lastName" />
                </mat-form-field>
            </material-form-input>
        </div>
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <material-form-input [validationErrors]="validationErrors" [name]="'dateOfBirth'" fxFlex="">
                <mat-form-field>
                    <input matInput [matDatepicker]="dateOfBirth" placeholder="Date Of Birth" formControlName="dateOfBirth" />
                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                    <mat-datepicker #dateOfBirth></mat-datepicker>
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'primaryCaregiverName'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="Primary Caregiver Name" formControlName="primaryCaregiverName" />
                </mat-form-field>
            </material-form-input>
        </div>
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <material-form-input [validationErrors]="validationErrors" [name]="'gender'" fxFlex="">
                <mat-form-field>
                    <mat-select placeholder="Gender" formControlName="gender">
                        <mat-option *ngFor="let gender of genderEnum | enumKeyValueList;" [value]="gender.value">
                            {{ gender.key }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'relationshipType'" fxFlex="">
                <mat-form-field>
                    <mat-select placeholder="Relationship Type" formControlName="relationshipType">
                        <mat-option *ngFor="let relationshipType of relationshipTypeEnum | enumKeyValueList;" [value]="relationshipType.value">
                            {{ relationshipType.key }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </material-form-input>
        </div>
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <material-form-input [validationErrors]="validationErrors" [name]="'contactName'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="Contact Name" formControlName="contactName" />
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'contactPhoneNumber'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="Contact Number" class="phoneFormat" formControlName="contactPhoneNumber" (focus)="onPhoneNumberFocus()" (blur)="onPhoneNumberBlur()" />
                </mat-form-field>
            </material-form-input>
        </div>
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <material-form-input [validationErrors]="validationErrors" [name]="'line1'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="Address Line 1" formControlName="line1" />
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'line2'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="Address Line 2" formControlName="line2" />
                </mat-form-field>
            </material-form-input>
        </div>
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <material-form-input [validationErrors]="validationErrors" [name]="'city'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" placeholder="City" formControlName="city" />
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'stateOrProvince'" fxFlex="">
                <mat-form-field>
                    <input matInput type="text" pattern="[A-Za-z]{2}" placeholder="State" formControlName="stateOrProvince" />
                </mat-form-field>
            </material-form-input>
            <material-form-input [validationErrors]="validationErrors" [name]="'zipCode'" fxFlex="">
                <mat-form-field>
                    <input matInput type="number" pattern="[0-9]{5}" placeholder="Zip Code" formControlName="zipCode" />
                </mat-form-field>
            </material-form-input>
        </div>
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="1em" class="padding-top">
            <button mat-raised-button color="primary" type="submit">Save</button>
            <a mat-button class="mat-button-style" (click)="viewAssociatedAccountProfile(selectedAssociate.email)">View {{displayAssociateFirstName()}}'s account</a>
            <span *ngIf="userPatientRelationshipId && userPatientRelationshipId > 0">
        <action-confirmation class="float-left" [actionConfirmation]="severAccess" (userResponse)="severUserAccess($event, true)"></action-confirmation>
      </span>
        </div>
    </form>
    <div class="padding-top">
        <progress-bar [isLoading]="isLoading"></progress-bar>
    </div>
</section>