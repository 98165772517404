<div *ngIf="searchType == 'MyHome'">
  <div fxLayout="row" class="search-component" (keyup.enter)="modifySearch()" [ngClass]="{'on-top': this.hasResults}"
    fxLayoutGap="10px">
    <div fxFlex="100%" fxFlexAlign="center" class="align-center">
      <input [(ngModel)]="basicUserSearchText" class="search-box" (input)="keyPress($event)"
        placeholder="First Name, Last Name, Email, DOB or InQuicker Confirmation #">
      <button class="search-button" mat-raised-button [color]="'primary'" (click)="modifySearch()">
        <mat-icon>search</mat-icon> Search Users
      </button>
      <button class="search-button" mat-raised-button [color]="'accent'" [hidden]="!this.hasResults"
        (click)="clearSearch()">
        <mat-icon>clear_all</mat-icon> Clear
      </button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutGap="20px">
    <form [formGroup]="advancedSearchForm">
      <div fxFlex="100%">
        <div fxLayout="row" fxLayoutGap="10px">
          <mat-form-field fxFlex="20%">
            <input matInput type="text" (input)="valueChange()" title="Username" placeholder="Username"
              formControlName="username" />
          </mat-form-field>

          <mat-form-field fxFlex="12%">
            <input matInput type="text" (input)="valueChange()" title="InQuicker Confirmation #"
              placeholder="InQuicker Confirmation #" formControlName="inQuickerConfirmationNumber" />
          </mat-form-field>

          <mat-form-field fxFlex="10%">
            <input matInput type="text" (input)="valueChange()" title="First Name" placeholder="First Name"
              formControlName="firstName" />
          </mat-form-field>

          <mat-form-field fxFlex="10%">
            <input matInput type="text" (input)="valueChange()" title="Last Name" placeholder="Last Name"
              formControlName="lastName" />
          </mat-form-field>

          <mat-form-field fxFlex="10%">
            <input matInput (blur)="updateDobModel()" title="Date of Birth" placeholder="DOB" formControlName="dob"
              [matDatepicker]="picker" [min]="minDate" [max]="maxDate" (dateInput)="valueChange()"
              (dateChange)="valueChange()" />

            <mat-datepicker-toggle (selectionChange)="valueChange()" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="advancedSearchForm.controls['dob'].hasError('matDatepickerMin')">Please enter DOB in
              format MM/DD/YYYY.</mat-error>
            <mat-error
              *ngIf="advancedSearchForm.controls['dob'].hasError('matDatepickerMax')">{{maxDateErrorMessage}}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="10%">
            <mat-label>Gender</mat-label>
            <mat-select (selectionChange)="valueChange()" formControlName="gender">
              <mat-option>-- Any gender --</mat-option>
              <mat-option *ngFor="let gender of genders | enumKeyValueList;" [value]="gender.value">
                {{ gender.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="10%">
            <mat-label>User Level</mat-label>
            <mat-select (selectionChange)="valueChange()" formControlName="userLevel">
              <mat-option>-- Any level --</mat-option>
              <mat-option *ngFor="let level of levels | filterUserLevel: ['level1','Level1','level3','Level3'];"
                [value]="level.value">
                {{ level.key }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="10%">
            <input matInput (blur)="updateCreatedDateModel()" title="Created Date" [matDatepicker]="pickerCreatedDate"
              [min]="minDate" [max]="maxDate" placeholder="Created Date" formControlName="createdDate"
              (dateInput)="valueChange()" (dateChange)="valueChange()" />
            <mat-datepicker-toggle matSuffix (selectionChange)="valueChange()"
              [for]="pickerCreatedDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerCreatedDate></mat-datepicker>
            <mat-error *ngIf="advancedSearchForm.controls['createdDate'].hasError('matDatepickerMin')">Please enter
              Created Date in format MM/DD/YYYY.</mat-error>
            <mat-error
              *ngIf="advancedSearchForm.controls['createdDate'].hasError('matDatepickerMax')">{{maxDateErrorMessage}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>