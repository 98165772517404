export class PortalInformation {
    portalName: string;
    isRevokeEligible: boolean;
    isResetEligible = true;
    status: string[] = [];
    message: string;
    hasErrorEnrollment = false;

    constructor (name: string, status: string) {
        this.portalName = name;
        this.status.push(status);
    }
}
