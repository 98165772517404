import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';;
import { CiamAuth, UpdateBarGroupRequest, UpdateRegionRequest, UpdateEmpiHubRequest } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import { ValidationErrors } from '../../../types/forms';
import { getValidationErrors } from '../../../types/helpers';

const validationMessages = {
  groupId: {
    'required': 'Group Id is required'
  },
  regionName: {
    'required': 'Region Name is required'
  },
  empiHubId: {
    'required': 'Empi Hub is required'
  }
}

@Component({
  selector: 'bar-groups-create-update',
  templateUrl: './bar-groups-create-update.component.html',
  styleUrls: ['./bar-groups-create-update.component.scss']
})

export class BarGroupsCreateUpdateComponent implements OnInit {
  regions: UpdateRegionRequest[] = [];
  empiHubs: UpdateEmpiHubRequest[] = [];
  barGroup: UpdateBarGroupRequest;
  showLoader: boolean = false;
  isEditMode: boolean = false;
  form: FormGroup;
  validationErrors: ValidationErrors = {};
  currentPageNumber: 1;
  currentPageSize: 10;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private fb: FormBuilder,
    public dialogRef: MatLegacyDialogRef<BarGroupsCreateUpdateComponent>,
    private barGroupService: BarGroupService
  ) {
    this.isEditMode = data ? true : false;
    this.barGroup = data ? data : <UpdateBarGroupRequest>{
      groupId: '',
      regionId: null,
      empiHubId: null
    };
  }

  ngOnInit() {
    this.barGroupService.getRegions().subscribe(value => {
      this.regions = value;
    });

    this.barGroupService.getEMPIHubs().subscribe(value => {
      this.empiHubs = value;
    });

    this.form = this.fb.group({
      'groupId': [this.barGroup != null ? this.barGroup.groupId : '', Validators.required],
      'regionName': [this.barGroup != null ? this.barGroup.regionId : '', Validators.required],
      'empiHubId': [this.barGroup != null ? this.barGroup.empiHubId : '', Validators.required]
    });

    this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data))
  }

  onFormValuesChanged(data: any) {
    this.validationErrors = getValidationErrors(this.form, validationMessages);
  }

  onFormSubmit() {
    const controls = this.form.controls;
    if (!this.form.valid) {
      return;
    }

    this.barGroup.groupId = controls['groupId'].value;
    this.barGroup.regionId = controls['regionName'].value;
    this.barGroup.empiHubId = controls['empiHubId'].value;

    this.showLoader = true;
    if (this.isEditMode) {
      this.updateBarGroup();
    }
    else {
      this.createBarGroup();
    }
  }

  async createBarGroup() {
    const response = await this.ciamAuth.httpApiFacilities.apiAdminBargroupPost(this.barGroup);
    this.showLoader = false;

    if (response.errors.length) {
      this.uiService.openSnackbar(response.errors[0].message, 'Error');
      return;
    }

    this.uiService.openSnackbar('Bar Group is created successfully', 'Success');
    this.dialogRef.close({ status: true });
  }

  async updateBarGroup() {
    const response = await this.ciamAuth.httpApiFacilities.apiAdminBargroupByIdPatch(this.barGroup, this.barGroup.barGroupId.toString());
    this.showLoader = false;

    if (response.errors.length) {
      this.uiService.openSnackbar(response.errors[0].message, 'Error');
      return;
    }

    this.uiService.openSnackbar('Bar Group is updated successfully.', 'Success');
    this.dialogRef.close({ status: true });
  }
}
