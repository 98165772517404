<div class="page-content">
    <mat-card>
        <form (ngSubmit)="onFormSubmit()" [formGroup]="form">
            <div class="bargroup-container">
                <form-input [validationErrors]="validationErrors" [name]="'barGroupId'">
                    <mat-form-field>
                        <mat-select placeholder="Select Bar Group" formControlName="barGroupId" (selectionChange)="onChangeGroup($event.value)">
                            <mat-option>Group Id : Region Name : Empi Name</mat-option>
                            <mat-option *ngFor="let barGroup of barGroups" [value]="barGroup.barGroupId">
                                {{barGroup.groupId}} : {{barGroup.region}} : {{barGroup.empiId}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form-input>
            </div>
            <div class="bargroup-container">
                <form-input [validationErrors]="validationErrors" [name]="'barGroupFacility'">
                    <mat-form-field>
                        <input matInput type="text" formControlName="barGroupFacility" [(ngModel)]="query" (keyup)="searchBarGroupFacilities()" placeholder="Select Facilities" />
                    </mat-form-field>
                </form-input>
                <div class="bargroup-facilities" *ngIf="barGroupFacilities.length > 0">
                    <ul>
                        <li *ngFor="let item of barGroupFacilities">
                            <a (click)="setBarGroupFacility(item)">{{item.edwFacilityName}}</a>
                        </li>
                    </ul>
                </div>
                <div>                    
                    <mat-chip-list>
                        <mat-chip *ngFor="let selectedBarGroupFacility of selectedBarGroupFacilities">
                            <span>{{selectedBarGroupFacility.edwFacilityName}}</span>
                            <a class="pointer" (click)="removeBarGroupFacility(selectedBarGroupFacility)">x</a>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="bargroup-container">
                <button mat-raised-button color="primary" type="submit">Save</button>
                <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="bar-group-loader-margin"  [diameter]="30"></mat-spinner>
            </div>
        </form>
    </mat-card>
</div>
