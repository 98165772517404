import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { fadeInOut } from '../../services/animations.service';

@Component({
  selector: 'login-loader',
  templateUrl: './login-loader.component.html',
  styleUrls: ['./login-loader.component.scss'],
  animations: [fadeInOut]
})
export class LoginLoaderComponent implements OnInit {

  constructor(private ciamAuth: CiamAuth, private router: Router) { }

  ngOnInit() { }

}
