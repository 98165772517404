import { CiamUser } from '@dignity-health/ciam-auth';
import { Action } from '@ngrx/store';

export const UPDATE_PARENT_USER = 'UPDATE_PARENT_USER';

export class UpdateParentUser implements Action {
  public type = UPDATE_PARENT_USER;

  constructor(public payload: CiamUser) { }
}

