import { Component, OnInit, Input } from '@angular/core';
import { User } from '@dignity-health/ciam-auth';
import { UserProfileImage } from 'app/types/user-models/user-profile-image.model';

@Component({
  selector: 'app-user-profile-image-section',
  templateUrl: './user-profile-image-section.component.html',
  styleUrls: ['./user-profile-image-section.component.scss']
})
export class UserProfileImageSectionComponent implements OnInit {

  @Input()
  userProfileImage: UserProfileImage;

  constructor() { }

  ngOnInit() {
  }

}
