import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { UiService } from '../../../services/ui/ui.service';
import { ValidationErrors } from '../../../types/forms';
import { getValidationErrors } from '../../../types/helpers';

import * as _ from 'lodash';
import { CiamPerson, CiamUser, CiamAuth, ServiceResponse, UpdateDependentRequestGender, UpdateDependentRequestRelationshipType, UnitServiceResponse } from '@dignity-health/ciam-auth';
import { UtcDatePipe } from '../../../pipes/utc-date/utc-date.pipe';
import { PhoneNumberPipe } from '../../../pipes/phone-number/phone-number.pipe';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { environment } from 'environments/environment';

const delimiter = '.';
declare var jQuery: any;

const validationMessages = {
  firstName: {
    'required': 'first name is required'
  },
  lastName: {
    'required': 'last name is required'
  },
  dateOfBirth: {
    'required': 'date of birth is required'
  },
  primaryCaregiverName: {
    'required': 'primary caregiver name is required'
  },
  gender: {
    'required': 'gender is required'
  },
  contactName: {
    'required': 'contact name is required'
  },
  line1: {
    'required': 'address line 1 is required'
  },
  city: {
    'required': 'city is required'
  },
  zipCode: {
    'required': 'zip code is required',
    'pattern': 'zip code should contain exactly 5 digits'
  },
  stateOrProvince: {
    'required': 'state is required',
    'pattern': 'state/province code should contain exactly 2 letters'
  }
}

@Component({
  selector: 'app-user-manage-associated-account',
  templateUrl: './user-manage-associated-account.component.html',
  styleUrls: ['./user-manage-associated-account.component.scss'],
  providers: [UtcDatePipe, PhoneNumberPipe]
})

export class UserManageAssociatedAccountComponent implements OnInit {
  @Input()
  selectedUser: CiamUser;

  @Input()
  selectedAssociate: CiamPerson;

  @Input()
  personId: string;

  @Input()
  userPersonAccessId: number;

  @Input()
  personRelationshipType: string;

  @Input()
  selectedAuthRepresentativeEmail: string;

  @Input()
  userPatientRelationshipId: number;

  @Output()
  isSeverAccessSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();

  genderEnum = UpdateDependentRequestGender;
  relationshipTypeEnum = UpdateDependentRequestRelationshipType;

  form: FormGroup;
  firstName = new FormControl('', [Validators.required]);
  lastName = new FormControl('', [Validators.required]);
  dateOfBirth = new FormControl('', [Validators.required]);
  gender = new FormControl('', [Validators.required]);
  primaryCaregiverName = new FormControl('', [Validators.required]);
  contactName = new FormControl('', [Validators.required]);
  contactPhoneNumber = new FormControl('');
  relationshipType = new FormControl('', [Validators.required]);
  line1 = new FormControl('', [Validators.required]);
  line2 = new FormControl('');
  city = new FormControl('', [Validators.required]);
  zipCode = new FormControl('', [Validators.minLength(5), Validators.required]);
  stateOrProvince = new FormControl('', [Validators.minLength(2), Validators.required]);

  routeCommand: any[];
  validationErrors: ValidationErrors = {};
  isLoading: boolean = false;
  severAccess: IActionConfirmation;

  constructor(
    private uiService: UiService,
    private ciamAuth: CiamAuth,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private utcDatePipe: UtcDatePipe,
    private phoneNumberPipe: PhoneNumberPipe
  ) { }

  ngOnInit() {
    this.severAccess = {
      actionButtonText: this.selectedAssociate != null ? "Sever Link to " + this.selectedAssociate.firstName : "account",
      modalTitle: "Confirmation!",
      modalInstruction: "Are you sure you want to sever access for this user?",
      cancelButtonText: "Cancel",
      okButtonText: "Ok",
      isAdvanceConfirmation: true,
      advanceConfirmationValidationText: "SEVER",
      advanceConfirmationInstruction: "To sever Type 'SEVER'",
      isMatLink: true
    }
    return this.initForm(this.selectedUser);
  }

  initForm(selectedUser: any) {
    if (!selectedUser) {
      return;
    }

    if (!this.selectedAssociate.email) {
      this.selectedAssociate.email = this.selectedAuthRepresentativeEmail;
    }

    this.form = this.fb.group({
      firstName: this.firstName,
      lastName: this.lastName,
      dateOfBirth: this.dateOfBirth,
      primaryCaregiverName: this.primaryCaregiverName,
      gender: this.gender,
      contactName: this.contactName,
      contactPhoneNumber: this.contactPhoneNumber,
      relationshipType: this.relationshipType,
      line1: this.line1,
      line2: this.line2,
      city: this.city,
      zipCode: this.zipCode,
      stateOrProvince: this.stateOrProvince
    });

    let selectedAssociateDateOfBirth = this.utcDatePipe.transform(this.selectedAssociate.dateOfBirth);

    this.firstName.setValue(this.selectedAssociate.firstName);
    this.lastName.setValue(this.selectedAssociate.lastName);
    this.dateOfBirth.setValue(selectedAssociateDateOfBirth);
    this.gender.setValue(this.selectedAssociate.gender.toString());
    this.primaryCaregiverName.setValue(this.selectedAssociate.primaryCaregiverName);
    this.contactName.setValue(this.selectedAssociate.contactName);
    this.relationshipType.setValue(this.personRelationshipType);
    this.contactPhoneNumber.setValue(this.selectedAssociate.contactPhoneNumber ? this.phoneNumberPipe.transform(this.selectedAssociate.contactPhoneNumber) : '');
    this.line1.setValue(this.selectedAssociate.address ? this.selectedAssociate.address.line1 : '');
    this.line2.setValue(this.selectedAssociate.address ? this.selectedAssociate.address.line2 : '');
    this.city.setValue(this.selectedAssociate.address ? this.selectedAssociate.address.city : '');
    this.zipCode.setValue(this.selectedAssociate.address ? this.selectedAssociate.address.zipCode : '');
    this.stateOrProvince.setValue(this.selectedAssociate.address ? this.selectedAssociate.address.stateOrProvince : '');

    this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data));
  }

  onFormValuesChanged(data: any) {
    this.validationErrors = getValidationErrors(this.form, validationMessages);
  }

  replaceAll(str: string, term: string, replacement: string) {
    return _.replace(str, RegExp('\\.', 'g'), replacement);
  }

  onPhoneNumberFocus() {
    var phone = this.replaceAll(this.contactPhoneNumber.value, '.', '');
    this.contactPhoneNumber.setValue(phone);
  }

  onPhoneNumberBlur() {
    var $phone = jQuery('.phoneFormat');
    var phoneNumber = this.phoneNumberPipe.transform($phone.val());
    this.contactPhoneNumber.setValue(phoneNumber);
  }

  displayAssociateFirstName() {
    if (this.selectedAssociate == null || this.selectedAssociate.firstName == null || this.selectedAssociate.firstName == "") {
      return "Unknown name";
    }

    return this.selectedAssociate.firstName;
  }

  viewAssociatedAccountProfile(email: string) {
    var username = email == undefined ? '' : email;

    this.routeCommand = ['/users/view', { user: username, personId: this.personId }];
    this.router.navigate(this.routeCommand);
  }

  async onSubmit(): Promise<void> {
    const controls = this.form.controls;

    if (!this.form.valid) {
      this.snackBar.open("Please enter valid details.", null, { duration: 2500 });
      return;
    }

    const associatedAccountRequest = await this.ciamAuth.httpApi.apiAdminUsersUpdateDependentPost({
      personId: this.personId,
      userName: this.selectedUser.email,
      firstName: controls['firstName'].value,
      lastName: controls['lastName'].value,
      dateOfBirth: this.uiService.formatDate(controls['dateOfBirth'].value),
      gender: <UpdateDependentRequestGender>controls['gender'].value,
      primaryCaregiverName: controls['primaryCaregiverName'].value,
      contactName: controls['contactName'].value,
      contactPhoneNumber: this.replaceAll(controls['contactPhoneNumber'].value, '.', ''),
      relationshipType: <UpdateDependentRequestRelationshipType>controls['relationshipType'].value,
      address: {
        line1: controls['line1'].value, line2: controls['line2'].value,
        city: controls['city'].value, zipCode: controls['zipCode'].value,
        stateOrProvince: controls['stateOrProvince'].value
      }
    }) as ServiceResponse<CiamPerson>;

    if (associatedAccountRequest.errors.length) {
      return this.uiService.showErrors(associatedAccountRequest.errors, true, environment.ciamUrl + "admin/users/update-dependent");
    }

    this.snackBar.open("Updated User Person Relation!", null, { duration: 2500 });
  }

  async severLink() {
    try {
      this.isLoading = true;
      let portalupr = this.ciamAuth.httpApiPatientPortal.apiPortalsUserUserPatientRelationshipPost(this.userPatientRelationshipId);

      portalupr.then(ppResult => {
        if (!ppResult.isValid && ppResult.errors) {
          return this.uiService.showErrors(ppResult.errors, true, environment.patientPortalsUrl + "portals/user/user-patient-relationship")
        } else {
          if (this.userPersonAccessId && this.userPersonAccessId > 0) {
            this.ciamAuth.httpApi.apiAdminUsersRemoveDependentByUserPersonAccessIdDelete(this.userPersonAccessId).then(ciamResult => {
              if (!ciamResult.isValid && ciamResult.errors) {
                return this.uiService.showErrors(ciamResult.errors, true, environment.patientPortalsUrl + "admin/users/remove-dependent/" + this.userPersonAccessId)
              } else {
                this.snackBar.open("Updated successfully", null, { duration: 2500 });
                this.isSeverAccessSuccessful.emit(true);
              }
            })
          }
          else {
            this.snackBar.open("Updated successfully", null, { duration: 2500 });
            this.isSeverAccessSuccessful.emit(true);
          }
        }
      });
    } catch (errors) {
      return;
    } finally {
      this.isLoading = false;
    }
  }

  severUserAccess(confirmationValue: boolean, withSecurityResponse: boolean) {
    if (confirmationValue) {
      this.severLink();
    }
  }

}
