<section class="page-content">
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <div class="patients-grid-container">
    <div class="patients-grid-header">
      <app-patients-search [searchType]="searchType" [isLoading]="isLoading"
        (patientSearchParams)="setPatientSearchParams($event)" [patientSearchObject]="patientSearchParams"
        (clearResult)="clear($event)"></app-patients-search>
    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex class="text-center">
      <h4 class="no-results-message">{{noResultsMessage}}</h4>
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex>
      <div class="patients-container">
        <mat-table *ngIf="hasResults" #table [dataSource]="dataSource" matSort matSortDisableClear="true"
          (matSortChange)="onSortData($event)">

          <!-- Username Column -->
          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef fxFlex="20%">
              Parent Username </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="20%">
              <a (click)="goToViewUserProfile(element?.username)"> {{element?.username}} </a>
            </mat-cell>
          </ng-container>

          <!-- FirstName Column -->
          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef fxFlex="12%" mat-sort-header="FirstName"> First Name </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="12%"> {{element?.firstName}} </mat-cell>
          </ng-container>

          <!-- Last Name Column -->
          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef fxFlex="12%" mat-sort-header="LastName"> Last Name </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="12%"> {{element?.lastName}} </mat-cell>
          </ng-container>

          <!-- Date of Birth Column -->
          <ng-container matColumnDef="dateOfBirth">
            <mat-header-cell *matHeaderCellDef fxFlex="10%" mat-sort-header="DateOfBirth"> Date of Birth
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="10%"> {{element?.dateOfBirth | utcDate | date: 'MM/dd/yyyy'}}
            </mat-cell>
          </ng-container>

          <!-- Gender Column -->
          <ng-container matColumnDef="gender">
            <mat-header-cell *matHeaderCellDef fxFlex="10%" mat-sort-header="Gender"> Gender </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="10%"> {{element?.gender}} </mat-cell>
          </ng-container>

          <!-- Account Type Column; Account Type is in context of username/email and hence will be Self or Auth Rep. -->
          <ng-container matColumnDef="accountType">
            <mat-header-cell *matHeaderCellDef fxFlex="10%"> Account Type </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="10%">
              <!-- <span *ngIf="element?.accountType=='SELF' ? self : authRep">{{element?.accountType}} </span> -->
              <span>{{element?.accountType}} </span>
              <!-- <ng-template #authRep>
                AUTHORIZED REPRESENTATIVE
              </ng-template> -->
              <!-- <ng-template #self>
                AUTHORIZED SELF
              </ng-template> -->
            </mat-cell>
          </ng-container>
          <!-- SourceId Column -->
          <ng-container matColumnDef="sourceId">
            <mat-header-cell *matHeaderCellDef fxFlex="11%" mat-sort-header="SourceId"> Cerner Person Id
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="11%"> {{element?.sourceId}} </mat-cell>
          </ng-container>
          <!-- Cerner Person ID  -->
          <ng-container matColumnDef="personID" *ngIf="searchType=='Cerner'">
            <mat-header-cell *matHeaderCellDef fxFlex="10%" mat-sort-header="PersonId"> Person ID </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="10%"> {{element?.id}} </mat-cell>
          </ng-container>

          <!-- Domain Column -->
          <ng-container matColumnDef="domainName" *ngIf="searchType=='Cerner'">
            <mat-header-cell *matHeaderCellDef fxFlex="5%" mat-sort-header="DomainName"> Domain </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="5%"> {{element?.domainName}} </mat-cell>
          </ng-container>

          <!-- Facilities Visited-->
          <ng-container matColumnDef="facilitiesVisited" *ngIf="searchType=='Cerner'">
            <mat-header-cell *matHeaderCellDef>Facilities Visited</mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              <mat-icon svgIcon="search" inline="true" (click)="goToFacilitiesVisit(element?.id)"
                matTooltip="Facilities Visited">image_search</mat-icon>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="searchType=='MyCare'? myCareColumns : cernerColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: searchType=='MyCare'? myCareColumns : cernerColumns;"></mat-row>
        </mat-table>
        <mat-paginator [length]="rowCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="onPaginateChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>

</section>