<div class="example-container">
  <h1 mat-dialog-title>Resend Invitation</h1>
  <mat-form-field>
    <input matInput [readonly]="isResendDisable" placeholder="Email of the user"
      (ngModelChange)="emailchangedMessage(email)" [readonly]="isTier1Group" [formControl]="email" required>
    <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
  <div class="updated-email-message" *ngIf="showUpdatedEmailMessge">
    <span>&#8226;</span> The invitation will be sent to the updated email, as it is entered above.<br />
    <span>&#8226;</span> The database will be updated in 5-10 minutes and it will start returning
       the updated email after this time.
  </div>
  <progress-bar class="progess-bar" [isLoading]="isLoading"></progress-bar>
  <div mat-dialog-actions>
    <button cdkFocusInitial mat-button mat-raised-button (click)="cancel()" color="accent">Cancel</button>
    <button mat-button mat-raised-button color="primary" [disabled]="isResendDisable"
      (click)="!email.invalid && reSendInvitation()">Resend</button>
  </div>
</div>