import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class FlagGuard implements CanActivate {

    constructor(private router: Router) { }    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const canActivate = !!route.data.flag;

        if (!canActivate) {
            this.router.navigateByUrl('route-disabled')
        }

        return canActivate;
    }
}
