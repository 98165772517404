import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';

import { CiamAuth, CiamUser, PatientEnrollmentError } from '@dignity-health/ciam-auth';
import { UiService } from 'app/services/ui/ui.service';
import { GraphQLQuery } from '../../../types/graphql';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/types';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const GET_ERRORS_BY_PATIENT_ENROLLMENT_ID_QUERY = `
  query getPatientEnrollmentErrorById(
    $patientEnrollmentId: Int)
    {
      patientEnrollmentErrors(
        patientEnrollmentId:$patientEnrollmentId)
      {
        errorId
        attemptNumber
        errorCode
        errorMessage    
      }
   }`;

@Component({
  selector: 'patient-enrollment-errors',
  templateUrl: './patient-enrollment-errors.component.html',
  styleUrls: ['./patient-enrollment-errors.component.scss']
})

export class PatientEnrollmentErrorsComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;

  isValid: boolean;
  patientEnrollId: number;
  isLoading: boolean = false;

  enrollmentErrorQuery: GraphQLQuery;
  dataSource: MatLegacyTableDataSource<PatientEnrollmentError>

  displayedColumns = ["errorId", "attemptNumber", "errorCode", "errorMessage"];

  constructor(
    private store: Store<IAppState>,
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.patientEnrollId = params['id'];
    });

    this.getErrorDetail();
  }

  async getErrorDetail(): Promise<void> {
    this.enrollmentErrorQuery = {
      query: GET_ERRORS_BY_PATIENT_ENROLLMENT_ID_QUERY,
      variables: {
        "patientEnrollmentId": Number(this.patientEnrollId)
      }
    };

    const result = await this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.enrollmentErrorQuery);
    const patientEnrollmentErrors = result && result.data && result.data.patientEnrollmentErrors;

    this.isLoading = false;

    if (!patientEnrollmentErrors && result.errors !== undefined) {
      return this.uiService.showErrors(result.errors, true, environment.patientPortalsUrl + 'graphql/query/getPatientEnrollmentErrorById');
    }

    this.isValid = patientEnrollmentErrors.length > 0;
    this.dataSource = new MatLegacyTableDataSource(patientEnrollmentErrors);
  }

  goToPatientEnrollments() {
    this.router.navigate(['/patient/enrollments']);
  }
}
