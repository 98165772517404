import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../../services/animations.service';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  animations: [fadeInOut]
})
export class UnauthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
