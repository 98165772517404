<div class="page-content">
  <div class="cernerConfig-container">

    <progress-bar [isLoading]="isLoading"></progress-bar>

    <div *ngIf="cernerSSOConfigDetails != null && cernerSSOConfigDetails.error != null">
      <span>Error: {{cernerSSOConfigDetails.error}}</span>
    </div>
    
    <div *ngIf="cernerSSOConfigDetails != null">
      <!-- Migration/Invitaion/Error Details -->
      <div class="row">
        <div class="col-md-10">
          <span class="heading">Slot2 Data Load Configuration: {{cernerSSOConfigDetails.configMode}}</span>
        </div>      
        <div class="col-md-2">
          <button mat-raised-button color="accent" (click)="onRefreshConfigs()" class="refresh">
            <mat-icon>autorenew</mat-icon>Refresh
          </button>
        </div>
      </div>
      <!-- Mock A Details -->
      <div class="row card">
        <mat-card class="card-bg">
          <mat-card-title class="text-center">MOCK - A</mat-card-title>
          <mat-card-content>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Portal URL</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockASSOPortal.migration.portalURL}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockACernerIDPPortal.migration.portalURL}}</span></div>
            </div>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Associated Domains</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockASSOPortal.migration.associatedDomains}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockACernerIDPPortal.migration.associatedDomains}}</span></div>
            </div>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">IDP Configuration</span></div>
              <div class="col-md-4 content-border"><span class="card-content" [ngStyle]="{'color':getIdpConfiguration(cernerSSOConfigDetails.mockASSOPortal.migration.idpConfiguration)}">{{cernerSSOConfigDetails.mockASSOPortal.migration.idpConfiguration}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content" [ngStyle]="{'color':getIdpConfiguration(cernerSSOConfigDetails.mockACernerIDPPortal.migration.idpConfiguration)}">{{cernerSSOConfigDetails.mockACernerIDPPortal.migration.idpConfiguration}}</span></div>
            </div>
            <!--<div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Service URL</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockASSOPortal.migration.serviceURL}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockACernerIDPPortal.migration.serviceURL}}</span></div>
            </div>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Mnemonic Codes</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockASSOPortal.migration.mnemonic}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockACernerIDPPortal.migration.mnemonic}}</span></div>
            </div>-->
          </mat-card-content>
        </mat-card>
      </div>
      <!-- Mock B Details -->
      <div class="row card">
        <mat-card class="card-bg">
          <mat-card-title class="text-center">MOCK - B</mat-card-title>
          <mat-card-content>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Portal URL</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBSSOPortal.migration.portalURL}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBCernerIDPPortal.migration.portalURL}}</span></div>
            </div>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Associated Domains</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBSSOPortal.migration.associatedDomains}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBCernerIDPPortal.migration.associatedDomains}}</span></div>
            </div>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">IDP Configuration</span></div>
              <div class="col-md-4 content-border"><span class="card-content" [ngStyle]="{'color':getIdpConfiguration(cernerSSOConfigDetails.mockBSSOPortal.migration.idpConfiguration)}">{{cernerSSOConfigDetails.mockBSSOPortal.migration.idpConfiguration}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content" [ngStyle]="{'color':getIdpConfiguration(cernerSSOConfigDetails.mockBCernerIDPPortal.migration.idpConfiguration)}">{{cernerSSOConfigDetails.mockBCernerIDPPortal.migration.idpConfiguration}}</span></div>
            </div>
            <!--<div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Service URL</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBSSOPortal.migration.serviceURL}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBCernerIDPPortal.migration.serviceURL}}</span></div>
            </div>
            <div class="row">
              <div class="col-md-4 content-border"><span class="card-header-content">Mnemonic Codes</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBSSOPortal.migration.mnemonic}}</span></div>
              <div class="col-md-4 content-border"><span class="card-content">{{cernerSSOConfigDetails.mockBCernerIDPPortal.migration.mnemonic}}</span></div>
            </div>-->
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    
  </div>
</div>
