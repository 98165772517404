import { Component, OnInit, Input } from '@angular/core';
import { User, CiamAuth, Appointment } from '@dignity-health/ciam-auth';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { GraphQLQuery } from 'app/types/graphql';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'app/services/ui/ui.service';
import { MIN_DATE } from 'app/types/constants';
import * as moment from 'moment-timezone';
import { environment } from 'environments/environment';

const GET_APPOINTMENTS_BY_USERNAME_QUERY = `
query getAppointmentsByUserName($username: String, $includeInactive: Boolean, $beginDate: String, $endDate: String){ 
  users(username:$username){
    self{
      appointments(includeInactive: $includeInactive, beginDate: $beginDate, endDate: $endDate){
        appointmentId
        gecbAppointmentId
        inQuickerConfirmationNumber
        provider {
          displayName
        }
        facilityAddress {
          city
          countryCode
          line1
          line2
          stateOrProvince
          zipCode
        }
        appointmentTypeName
        facilityPhoneNumber
        appointmentStart
        appointmentStatus
        facilityName
      }
    }
  }
}`;

@Component({
  selector: 'app-user-appointments',
  templateUrl: './user-appointments.component.html',
  styleUrls: ['./user-appointments.component.scss']
})

export class UserAppointmentsComponent implements OnInit {
  @Input() selectedUser: User;

  userAppointmentDataSource: MatLegacyTableDataSource<Appointment>;
  userAppointmentQuery: GraphQLQuery;
  availableAppointmentStatuses = AppointmentStatus;
  displayedColumns = ['appointmentId', 'gecbAppointmentId', 'providerName', 'facilityName', 'address', 'appointmentType', 'locationPhone', 'appointmentDate', 'appointmentStatus'];

  username: string;
  messageText: string;
  isValid: boolean;
  isLoading: boolean;
  selectedAppointmentStatus: number;
  timezoneAbbrevation: string;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private ciamAuth: CiamAuth,
    private uiService: UiService
  ) { }

  ngOnInit() {
    if (this.selectedUser) {
      this.route.params.subscribe(params => {
        this.username = params['user'];
      });
      this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');
    }

    this.selectedAppointmentStatus = this.availableAppointmentStatuses.Upcoming;
    this.getUserAppointments();
  }

  async getUserAppointments(): Promise<void> {    
    this.isLoading = true;

    this.userAppointmentQuery = {
      query: GET_APPOINTMENTS_BY_USERNAME_QUERY,
      variables: this.makeGetAppointmentsGraphQlVariables()
    }

    const result = await this.ciamAuth.httpApi.apiUsersQueryPost(this.userAppointmentQuery, true);
    const appointments = result && result.data && result.data.users && result.data.users.self && result.data.users.self.appointments;

    this.isLoading = false;
    if (!appointments) {
      return this.uiService.showErrors(result.errors, true, environment.ciamUrl + "users/query")
    }

    const displayedAppointments = this.selectedAppointmentStatus == AppointmentStatus.Canceled
      ? (appointments.filter(x => x.appointmentStatus == AppointmentStatus[AppointmentStatus.Canceled])
        .sort((a, b) => { +new Date(b.appointmentStart) - +new Date(a.appointmentStart) }))
      : (this.selectedAppointmentStatus == AppointmentStatus.Upcoming ? (appointments.filter(x => x.appointmentStatus != AppointmentStatus[AppointmentStatus.Canceled]))
        .sort((a, b) => { +new Date(b.appointmentStart) - +new Date(a.appointmentStart) })
        : appointments.sort((a, b) => +new Date(b.appointmentStart) - +new Date(a.appointmentStart)));
   

    this.userAppointmentDataSource = new MatLegacyTableDataSource(displayedAppointments);

    this.isValid = this.userAppointmentDataSource.data.length > 0;
    this.messageText = this.isValid ? '' : 'No records to show!';
  }

  makeGetAppointmentsGraphQlVariables(): GetAppointmentsVariables {
    const status = this.selectedAppointmentStatus;
    const today = new Date();

    return {
      username: this.username,
      beginDate: status == AppointmentStatus.Upcoming ? today : MIN_DATE,
      endDate: status == AppointmentStatus.Past ? today : null,
      includeInactive: true
    }
  }
}

enum AppointmentStatus {
  Upcoming,
  All,
  Past,
  Canceled
}

interface GetAppointmentsVariables {
  username: string;
  beginDate: Date;
  endDate: Date;
  includeInactive: boolean;
}
