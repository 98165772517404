<div class="page-content">
    <div class="search-component" fxLayoutGap="10px">
        <mat-form-field fxFlex="30%">
            <input matInput placeholder="Email Address" (input)="valueChange()" name="email" [(ngModel)]="email"
                minlength="6" maxlength="100" (keyup)="onEmailKeyUp($event)">
        </mat-form-field>
        <mat-form-field fxFlex="15%">
            <input matInput [matDatepicker]="fromDatePicker" placeholder="From date" name="fromDate"
                [(ngModel)]="fromDate" required [max]="maxAllowedDate" (dateChange)="onDatesRangeChange()" (dateInput)="valueChange()" (dateChange)="valueChange()" />
            <mat-datepicker-toggle (selectionChange)="valueChange()" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex="15%">
            <input matInput [matDatepicker]="toDatePicker" placeholder="To date" name="toDate" [(ngModel)]="toDate"
                required [min]="fromDate" [max]="maxAllowedDate" (dateChange)="onDatesRangeChange()" (dateInput)="valueChange()" (dateChange)="valueChange()" />
            <mat-datepicker-toggle (selectionChange)="valueChange()" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
        <div fx-flex="40%">
            <button class="search-button" mat-raised-button [color]="'primary'" (click)="performLogSearch()">
                <mat-icon>search</mat-icon>Search
            </button>
        </div>
    </div>
    <div>
        <label *ngIf="showEmailError" class="validation-error-email">
            Please enter a valid email address. It should contain the '@' character and be from 6 to 100 characters
            long.
        </label>
        <label *ngIf="showDatesRangeError" class="validation-error-dates">
            The date range to search should not exceed 30 days.
        </label>
    </div>
    <progress-bar [isLoading]="isLoading"></progress-bar>

    <div *ngIf="rowCount > 0">
        <mat-card>
            <mat-table #table matSort matSortDisableClear="true" [dataSource]="dataSourceAuditLogs">
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                <ng-container matColumnDef="eventdatetime">
                    <mat-header-cell class='min-width-m' *matHeaderCellDef>Event Date Time <br>
                        ({{timezoneAbbrevation}})
                    </mat-header-cell>
                    <mat-cell class='min-width-m' *matCellDef="let row">{{row.createdDate | date:'medium'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="eventtype">
                    <mat-header-cell class='min-width-m' *matHeaderCellDef>Event Type</mat-header-cell>
                    <mat-cell class='min-width-m' *matCellDef="let row">{{row.eventType}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                    <mat-header-cell class='min-width-m' *matHeaderCellDef>Created By</mat-header-cell>
                    <mat-cell class='min-width-m' *matCellDef="let row">{{row.createdBy}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                    <mat-header-cell class='min-width-m' *matHeaderCellDef>User</mat-header-cell>
                    <mat-cell class='min-width-m' *matCellDef="let row">{{row.email}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="changelog">
                    <mat-header-cell class='min-width-l' *matHeaderCellDef>Change Log <br /> </mat-header-cell>
                    <mat-cell class='min-width-l' *matCellDef="let row">{{row.changeLogs | json}}</mat-cell>
                </ng-container>
            </mat-table>
            <label *ngIf="dataGridMessage != ''" class="data-grid-message">{{dataGridMessage}}</label>
        </mat-card>
    </div>
</div>