<div>
  <h1 mat-dialog-title class="update-heading-margin">Update Username</h1>
  <mat-form-field class="form-field">
    <input matInput placeholder="Enter new UserName" [formControl]="newUsername" cdkFocusInitial required>
    <mat-error *ngIf="newUsername.invalid">{{getErrorMessage()}}</mat-error>
  </mat-form-field>
  <div mat-dialog-actions class="update-dialog-margin">
    <button mat-button (click)="cancel()" color="accent">Cancel</button>
    <button mat-button color="primary" (click)="!newUsername.invalid && updateUsername()">Update</button>
    <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="update-spinner-margin" [diameter]="35"></mat-spinner>
  </div>
</div>
