import { UpdateUser } from '../../../store/actions/user';
import { CiamUser, CiamAuth, ServiceResponse } from '@dignity-health/ciam-auth'
import { Component, OnInit } from '@angular/core';
import { Store, props, createAction } from '@ngrx/store';
import { IAppState } from '../../../types/state';
import { Router, NavigationEnd } from '@angular/router';
import { UiService } from '../../../services/ui/ui.service';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatRadioButton } from '@angular/material/radio';
import { ColorScheme } from 'app/types/colorScheme';
import { ThemesService } from 'app/services/themes/themes-service';

@Component({
  selector: 'login-navbar',
  templateUrl: './login-navbar.component.html',
  styleUrls: ['./login-navbar.component.scss']
})
export class LoginNavbarComponent implements OnInit {
  resolvingUser: boolean;
  user: CiamUser;
  user$: Observable<CiamUser>;
  currentSelectedColorScheme: string;
  colorSchemeList: ColorScheme[] = [];

  constructor(private ciamAuth: CiamAuth, private uiService: UiService,
    private store: Store<IAppState>, private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private themesService: ThemesService
  ) {
    this.resolvingUser = true;
    this.user$ = this.store.select(s => s.user);
    this.user$.subscribe(user => {
      if (user) {
        this.user = user;
        this.resolvingUser = false;
      }
    });

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url != '/login' && !this.user) {
          this.resolvingUser = true;
        } else {
          this.resolvingUser = false;
        }
      }
    })

    this.currentSelectedColorScheme = this.themesService.getCurrentColorTheme().schemaClassName;
    this.colorSchemeList = themesService.getAllColorSchemes();
  }

  private doUserResolvingAction<T>(action: () => Promise<T>): Promise<T> {
    const promise = action();
    promise.catch(error => {
      this.uiService.openSnackbar(error.toString(), null, { duration: 2500 });
      this.resolvingUser = false;
    });

    promise.then(() => {
      this.resolvingUser = false;
    });

    return promise;
  }

  private updateUser(user: CiamUser) {
    this.user = user;
    this.store.dispatch(new UpdateUser(user));
  }

  async logIn(username: string, password: string): Promise<void> {
    this.doUserResolvingAction(async () => {
      await this.ciamAuth.session.signIn(username, password, 'foo');
      this.updateUser(await this.getLoggedInUser());
    });
  }

  async signOut(): Promise<void> {
    this.doUserResolvingAction(async () => {
      await this.ciamAuth.session.signOut();
      sessionStorage.clear();
      this.updateUser(null);
    });
    this.oidcSecurityService.logoff().subscribe(logout => {
      console.log('logout');
    });
  }
  
  async ngOnInit() {
    this.oidcSecurityService.isAuthenticated().subscribe(async (isLoggedIn) => {
      if (isLoggedIn) {
        this.updateUser(await this.getLoggedInUser());
      }
    });
  }

  private async getLoggedInUser(): Promise<CiamUser> {
    return this.doUserResolvingAction(async () => {
      const getUserResponse = await this.ciamAuth.user.getProfile() as ServiceResponse<CiamUser>;
      return getUserResponse.result;
    });
  }

  changeColorScheme($event: MatRadioButton) {
    this.currentSelectedColorScheme = $event.value;
    this.themesService.setColorTheme(this.currentSelectedColorScheme);
  }
}
