import { BehaviorSubject } from 'rxjs';
import { Injectable } from "@angular/core";
import { CiamAuth, UpdateRegionRequest, GraphQLRequest, UpdateEmpiHubRequest, BarGroup, Facility, UpdateBarGroupRequest } from "@dignity-health/ciam-auth";
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { PagedResult } from '../../types/graphql';
import { UiService } from '../../services/ui/ui.service';
import { GraphQlBarGroupFacilities } from 'app/types/graphQL-barGroupFacilities';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const GET_REGIONS_QUERY = `query {
  regions(isActive:true){
    regionId
    regionName
    isActive
  }
}`;

const GET_EMPIHUB_QUERY = `query{
  empiHubs(isActive: true) {
    empiHubId
    empiHubName
  }
}`;

const GET_PAGED_BARGROUPS_QUERY = `query($includeInactive: Boolean, $currentPage: Int, $pageSize: Int){
    pagedBarGroups(
    pageInfo: {
      currentPage: $currentPage, 
      pageSize: $pageSize
    },
    args: {
      groupId:"",
      regionName:"",
      includeInactive: false
    })   
    {
      results
      {
        barGroupId
        empiHubId
        groupId
        isActive
        barGroupEmpiHub
        {
          empiHubName
        }
        barGroupRegion
        {
          regionName
        }
      }
      currentPage
      pageSize
      firstRowOnPage
      lastRowOnPage
      pageCount   
      rowCount
    }
  }`

const GET_BARGROUPS_QUERY = `query {
  barGroups
  {
    barGroupId
    groupId
    region
    empiId
  }
}`;

const GET_FACILITIES_QUERY = `query {
  facilities
  {
    facilityId
    edwFacilityName
  }
}`;

const GET_FACILITIESBYBARGROUPID_QUERY = `query barGroupQuery($barGroupId: Int){
  barGroup(barGroupId: $barGroupId)
  {
    barGroupId
    facilities
    {
      facilityId
      edwFacilityName 
    }
  }
}`;

@Injectable()
export class BarGroupService {
  public regionDataSource = new BehaviorSubject<UpdateRegionRequest[]>([]);
  public empiHubDataSource = new BehaviorSubject<UpdateEmpiHubRequest[]>([]);

  public isLoading = new BehaviorSubject<boolean>(true);
  regionsQuery: GraphQLRequest;
  empiHubQuery: GraphQLRequest;
  barGroupsQuery: GraphQLRequest;
  facilitiesQuery: GraphQLRequest;
  barGroupFacilitiesQuery: GraphQLRequest;

  constructor(private ciamAuth: CiamAuth, private uiService: UiService) { }

  getRegions(): BehaviorSubject<UpdateRegionRequest[]> {
    this.regionsQuery = {
      query: GET_REGIONS_QUERY,
      variables: {
      }
    };

    this.setIsLoading(true);
    this.ciamAuth.httpApiFacilities.apiGraphqlQueryPost(this.regionsQuery, true)
      .then(result => {
        const regions = result && result.data && result.data.regions;

        if (regions && regions.length > 0) {
          const orderedRegions = _.orderBy(regions, [t => t.regionId], ['asc']);
          this.regionDataSource.next(<UpdateRegionRequest[]>orderedRegions);
        }
        else {
          this.setIsLoading(false);
          this.uiService.showErrors(
            result.errors ? result.errors[0].message :
              // Default error message in case of nothing came from the service.
              'There is some issue in fetching the Regions. Please contact the technical support team.');
          return;
        }
        this.setIsLoading(false);
      });
    return this.regionDataSource;
  }

  getEMPIHubs(): BehaviorSubject<UpdateEmpiHubRequest[]> {
    this.empiHubQuery = {
      query: GET_EMPIHUB_QUERY
    };

    this.setIsLoading(true);
    this.ciamAuth.httpApiFacilities.apiGraphqlQueryPost(this.empiHubQuery, true)
      .then(result => {
        const empiHubs = result && result.data && result.data.empiHubs;

        if (!empiHubs || !empiHubs.length) {
          this.showErrorMessage(result, environment.facilitiesApimUrl + "graphql/query");
          return;
        }

        const sortedEmpiHubs = _.orderBy(empiHubs, [t => t.empiHubName], ['asc']);
        this.empiHubDataSource.next(<UpdateEmpiHubRequest[]>sortedEmpiHubs);
        this.setIsLoading(false);
      });

    return this.empiHubDataSource;
  }

  async getPagedBarGroups(currentPage, pageSize) {
    this.barGroupsQuery = {
      query: GET_PAGED_BARGROUPS_QUERY,
      variables: {
        currentPage: currentPage,
        pageSize: pageSize,
        includeInactive: true
      }
    };

    const result = await this.ciamAuth.httpApiFacilities.apiGraphqlQueryPost(this.barGroupsQuery, true);

    const barGroups = result && result.data && result.data.pagedBarGroups;

    if (!barGroups && !barGroups.results) {
      this.uiService.openSnackbar(
        result.errors ? result.errors[0].message : 'There is some issue in fetching the Bar Groups. Please contact the technical support team.', 'Error');
      return;
    }

    return barGroups as PagedResult<UpdateBarGroupRequest[]>;
  }

  setIsLoading(val: boolean) {
    this.isLoading.next(val);
  }

  getBarGroups(): BehaviorSubject<BarGroup[]> {
    this.barGroupsQuery = {
      query: GET_BARGROUPS_QUERY,
      variables: {
      }
    };

    const barGroupDataSource = new BehaviorSubject<BarGroup[]>([]);
    this.setIsLoading(true);
    this.ciamAuth.httpApiFacilities.apiGraphqlQueryPost(this.barGroupsQuery, true)
      .then(result => {
        const barGroups = result && result.data && result.data.barGroups;

        if (!barGroups || !barGroups.length) {
          return this.showErrorMessage(result, environment.facilitiesApimUrl + "graphql/query");
        }

        const orderedBarGroups = _.orderBy(barGroups, [t => t.barGroupId], ['asc'])
        barGroupDataSource.next(<BarGroup[]>orderedBarGroups);
        barGroupDataSource.complete();
        this.setIsLoading(false);
      });

    return barGroupDataSource;
  }

  getFacilities(): BehaviorSubject<Facility[]> {
    this.facilitiesQuery = {
      query: GET_FACILITIES_QUERY,
      variables: {
      }
    };

    const facilityDataSource = new BehaviorSubject<Facility[]>([]);
    this.setIsLoading(true);
    this.ciamAuth.httpApiFacilities.apiGraphqlQueryPost(this.facilitiesQuery, true)
      .then(result => {
        const facilities = result && result.data && result.data.facilities;

        if (!facilities || !facilities.length) {
          return this.showErrorMessage(result, environment.facilitiesApimUrl + "graphql/query");
        }

        const orderedFacilities = _.orderBy(facilities, [t => t.edwFacilityName], ['asc']);
        facilityDataSource.next(<Facility[]>orderedFacilities);
        facilityDataSource.complete();
        this.setIsLoading(false);
      });

    return facilityDataSource;
  }

  getFacilitiesByBarGroupId(barGroupId: number): BehaviorSubject<GraphQlBarGroupFacilities> {
    this.barGroupFacilitiesQuery = {
      query: GET_FACILITIESBYBARGROUPID_QUERY,
      variables: {
        barGroupId: barGroupId
      }
    };

    const barGroupFacilitiesDataSource = new BehaviorSubject<GraphQlBarGroupFacilities>(null);
    this.setIsLoading(true);
    this.ciamAuth.httpApiFacilities.apiGraphqlQueryPost(this.barGroupFacilitiesQuery, true)
      .then(result => {
        const barGroupFacilities = result && result.data && result.data.barGroup;

        if (!barGroupFacilities) {
          return this.showErrorMessage(result, environment.facilitiesApimUrl + "graphql/query");
        }

        barGroupFacilitiesDataSource.next(<GraphQlBarGroupFacilities>barGroupFacilities);
        barGroupFacilitiesDataSource.complete();
        this.setIsLoading(false);
      });
    return barGroupFacilitiesDataSource;
  }

  private showErrorMessage(result: any, serviceUrl: string) {
    this.setIsLoading(false);
    this.uiService.showErrors(result.errors, true, serviceUrl);
    return;
  }
} 
