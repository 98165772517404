import { Component, EventEmitter, Output, Inject } from '@angular/core';
import * as moment from 'moment';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';;
import { IActionConfirmation } from 'app/types/action-confirmation-model/action-confirmation-model';
import { IAutoCloseInformation } from 'app/types/auto-close-information';

@Component({
    selector: 'app-autoclose-dialog',
    templateUrl: './autoclose-dialog.component.html',
    styleUrls: ['./autoclose-dialog.component.scss']
  })

export class AutoCloseDialogComponent {
    autoCloseData: IAutoCloseInformation;

    constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private dialogRef: MatLegacyDialogRef<boolean>) {

        this.autoCloseData = this.data.autoCloseInfo;

        setTimeout(() => {
            this.dialogRef.close(true);
          },
          this.autoCloseData.displayTime);
    }
}
