<div class="page-content">
  <div class="users-grid-container">
    <div class="users-grid-header">
      <form (ngSubmit)="getInvitations()" [formGroup]="form" autocomplete="off">
        <div fxlayout="row" fxLayout.lt-md="column" fxLayoutGap="0.25em">
          <mat-form-field fxFlex="15%">
            <input matInput type="text" (input)="valueChange()" placeholder="Patient First Name"
              formControlName="patientFirstName" tabindex="1" />
          </mat-form-field>
          <mat-form-field fxFlex="15%">
            <input matInput type="text" (input)="valueChange()" placeholder="Patient Last Name"
              formControlName="patientLastName" tabindex="2" />
          </mat-form-field>
          <mat-form-field fxFlex="19%">
            <input matInput type="text" (input)="valueChange()" placeholder="Email" formControlName="recipientEmail"
              tabindex="3" />
          </mat-form-field>
          <mat-form-field fxFlex="12%">
            <input matInput [matDatepicker]="dateOfBirth" title="Patient Date of Birth" (blur)="updateDobModel()"
              placeholder="Patient Date Of Birth" formControlName="patientDateOfBirth" tabindex="4" [min]="minDate"
              [max]="maxDate" (dateInput)="valueChange()" (dateChange)="valueChange()" />
            <mat-datepicker-toggle matSuffix (selectionChange)="valueChange()"
              [for]="dateOfBirth"></mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
            <mat-error *ngIf="form.controls['patientDateOfBirth'].hasError('matDatepickerMin')">
              Please enter DOB in the format MM/DD/YYYY.
            </mat-error>
            <mat-error *ngIf="form.controls['patientDateOfBirth'].hasError('matDatepickerMax')">
              {{maxDateErrorMessage}}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="10%">
            <mat-label>Domain</mat-label>
            <mat-select (selectionChange)="valueChange()" formControlName="domain" tabindex="5">
              <mat-option> -- Any domain -- </mat-option>
              <mat-option *ngFor="let domain of domains" [value]="domain.name">
                {{ domain.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> 
          <mat-form-field fxFlex="12%">
            <mat-label>Invitation Status</mat-label>
            <mat-select  (selectionChange)="valueChange()" formControlName="status"
              tabindex="6">
              <mat-option> -- Any status --</mat-option>
              <mat-option *ngFor="let status of statusTypes" [value]="status.value">
                {{ status.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button matTooltip="Search Invitations!" mat-raised-button [color]="'primary'" class="create-button-margin"
            tabindex="7" type="submit">
            <mat-icon>search</mat-icon> Search Invitations
          </button>
        </div>
      </form>
    </div>
  </div>
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <mat-card *ngIf="rowCount > 0">
    <div>
      <mat-table style="width: 100%;" #table [dataSource]="dataSource" matSort matSortDisableClear="true"
        (matSortChange)="onSortData($event)">
        <!-- patientFirstName Column -->
        <ng-container matColumnDef="patientFirstName">
          <mat-header-cell class='min-width-l' *matHeaderCellDef mat-sort-header="PatientFirstName">
            Patient First Name
          </mat-header-cell>
          <mat-cell class='min-width-l' *matCellDef="let row"> {{row.patientFirstName}} </mat-cell>
        </ng-container>
        <!-- patientLastName Column -->
        <ng-container matColumnDef="patientLastName">
          <mat-header-cell class='min-width-m' *matHeaderCellDef mat-sort-header="PatientLastName">
            Patient Last Name
          </mat-header-cell>
          <mat-cell class='min-width-m' *matCellDef="let row"> {{row.patientLastName}} </mat-cell>
        </ng-container>
        <!-- patientDateOfBirth Column -->
        <ng-container matColumnDef="patientDateOfBirth">
          <mat-header-cell class='min-width-s' *matHeaderCellDef mat-sort-header="PatientDateOfBirth">
            Patient DOB
          </mat-header-cell>
          <mat-cell class='min-width-s' *matCellDef="let row"> {{row.patientDateOfBirth | utcDate | date}} </mat-cell>
        </ng-container>
        <!-- patientRecipientEmail Column -->
        <ng-container matColumnDef="patientRecipientEmail">
          <mat-header-cell class='min-width-l' *matHeaderCellDef mat-sort-header="RecipientEmail">
            Email Address
          </mat-header-cell>
          <mat-cell *matCellDef="let row"><a (click)="patientDetails(row)"> {{row.recipientEmail}} </a></mat-cell>
        </ng-container>
        <!-- patientSourceId Column -->
        <ng-container matColumnDef="patientSourceId">
          <mat-header-cell class='min-width-s' *matHeaderCellDef> Cerner PersonId </mat-header-cell>
          <mat-cell class='min-width-s' *matCellDef="let row"> {{row.patientSourceId}} </mat-cell>
        </ng-container>
        <!-- InvitationSource Column -->
        <ng-container matColumnDef="invitationSource">
          <mat-header-cell class='min-width-s' *matHeaderCellDef mat-sort-header="InvitationSource">
            Invitation Source
          </mat-header-cell>
          <mat-cell class='min-width-s' *matCellDef="let row"> {{row.invitationSource}} </mat-cell>
        </ng-container>
        <!-- domainName Column -->
        <ng-container matColumnDef="domainName">
          <mat-header-cell class='min-width-s' *matHeaderCellDef mat-sort-header="DomainId"> Domain </mat-header-cell>
          <mat-cell class='min-width-s' *matCellDef="let row"> {{row.domainName}} </mat-cell>
        </ng-container>
        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell class='min-width-s' *matHeaderCellDef mat-sort-header="Status"> Status </mat-header-cell>
          <mat-cell class='min-width-s' *matCellDef="let row" [style.color]="statusColor(row.status)">
            {{row.status}}
          </mat-cell>
        </ng-container>
        <!-- lastSent Column -->
        <ng-container matColumnDef="dateSent">
          <mat-header-cell class='min-width-m' *matHeaderCellDef mat-sort-header="DateSent">Last Sent <br />
            ({{timezoneAbbrevation}})
          </mat-header-cell>
          <mat-cell class='min-width-m' *matCellDef="let row">
            {{row.dateSent | date:'MMM d, y, h:mm:ss a' }}
          </mat-cell>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell class='min-width-s' *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell class='min-width-s' *matCellDef="let row">

            <button class="button-without-border" [disabled]="row.status == 'unknown'" style="margin-right: 1%;"
              (click)="openExpireModal(row)" matTooltip="Click here to expire the Invitation.">
              <i class="material-icons icon-hover">query_builder</i>
            </button>

            <button class="button-without-border" *ngIf="row.status == 'revoked'"
              [disabled]="((row.status == 'confirmed') || (row.status == 'revoked'))">
              <i class="material-icons" matTooltip="The Invitation is revoked, so it can't be resent.">send</i>
            </button>

            <button *ngIf="row.status != 'revoked'" class="button-without-border"
              [disabled]="((row.status == 'confirmed') || (row.status == 'revoked') || (row.status == 'unknown'))"
              matTooltip="Click here to resend the Invitation." (click)="openResendModal(row)">
              <i class="material-icons">send</i>
            </button>

            <action-confirmation *ngIf="checkRemoveAccess()" [actionConfirmation]="actionConfirmationData"
              (userResponse)="removePortalInvitation($event, row)" (click)='getReciepientEmail(row)' fxFlex="15%"
              matTooltip="Click here to delete the invitation for email." class="remove-icon-align">
            </action-confirmation>

          </mat-cell>
        </ng-container>
        <!-- Symbol Column -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [length]="rowCount" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="getInvitations($event)">
    </mat-paginator>

  </mat-card>
  <div *ngIf="showNoResultMessage" class="left-margin">
    No invitations found. Please try searching again.
  </div>
</div>