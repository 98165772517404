import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { UiService } from '../services/ui/ui.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public router: Router, public uiService: UiService,
        private oidcSecurityService: OidcSecurityService,
        private ciamAuth: CiamAuth) {

    }

    async canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {
        try {
            this.oidcSecurityService.isAuthenticated().subscribe((isAuthenticated) => {
                if (!isAuthenticated) {
                    return this.blockActivationAndNavigateTo(this.router, 'login', state);
                }
            });

            if (environment.featureFlags.general.allowAllUsers) {
                return true;
            }

            return true;

        } catch (e) {
            this.uiService.openSnackbar('Something went wrong logging in!');
            return this.blockActivationAndNavigateTo(this.router, 'login', state);
        }
    }

    private blockActivationAndNavigateTo(router: Router, url: string, state: RouterStateSnapshot): boolean {
        if (state && state.url) {
            this.router.navigate([url], { queryParams: { returnUrl: state.url } });
        } else {
            this.router.navigate([url]);
        }

        return false;
    }
}
