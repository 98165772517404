<section *ngIf="user" class="page-content">
  <progress-bar [isLoading]="isLoading"></progress-bar>
  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="alertName">
          <mat-header-cell *matHeaderCellDef fxFlex="15%">Alert</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="15%">{{alertsettings.alertName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="threshold">
          <mat-header-cell *matHeaderCellDef fxFlex="8%">ThresHold</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="8%">{{alertsettings.threshold}} {{alertsettings.thresholdUnit}}</mat-cell>          
        </ng-container>  

        <ng-container matColumnDef="ryverAlert">
          <mat-header-cell *matHeaderCellDef fxFlex="7%">Threshold Ryver Alerts</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="7%">{{alertsettings.shouldSendRyverAlert == true ? "Yes" : "No"}}</mat-cell>          
        </ng-container>         

        <ng-container matColumnDef="emailAlert">
          <mat-header-cell *matHeaderCellDef fxFlex="7%">Threshold Email Alerts</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="7%">{{alertsettings.shouldSendEmailAlert == true ? "Yes" : "No"}}</mat-cell>          
        </ng-container>

        <ng-container matColumnDef="reoccurringThreshold">
          <mat-header-cell *matHeaderCellDef fxFlex="7%">Send Reminders</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="7%">{{alertsettings.reoccurringThreshold}} {{alertsettings.reoccurringThresholdUnit}}</mat-cell>          
        </ng-container>

        <ng-container matColumnDef="reoccurringryverAlert">
          <mat-header-cell *matHeaderCellDef fxFlex="7%">Reminder Ryver Alerts</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="7%">{{alertsettings.shouldSendReoccurringRyverAlert == true ? "Yes" : "No"}}</mat-cell>          
        </ng-container>         

        <ng-container matColumnDef="reoccurringemailAlert">
          <mat-header-cell *matHeaderCellDef fxFlex="7%">Reminder Email Alerts</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="7%">{{alertsettings.shouldSendReoccurringEmailAlert == true ? "Yes" : "No"}}</mat-cell>          
        </ng-container>

        <ng-container matColumnDef="emailTo">
          <mat-header-cell *matHeaderCellDef fxFlex="20%">Email To</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings" fxFlex="20%">{{alertsettings.emailTo}}</mat-cell>          
        </ng-container>     

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="6%">Actions</mat-header-cell>
          <mat-cell *matCellDef="let alertsettings">
            <mat-icon (click)="editAlertSetting(alertsettings)" mattooltip="Edit" fxFlex="6%">border_color</mat-icon>            
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let alertsettings; columns:displayedColumns"></mat-row>
      </mat-table>
    </mat-card-content>
  </mat-card>
</section>
