import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy { 
    
      private handlers: {[key: string]: DetachedRouteHandle} = {};   
    
      shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return true;
      }
    
      store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
          if (!route) {
              this.handlers[route.url.join("/") || route.parent.url.join("/")] = handle;
          }
          if (route.component) {
            while(document.getElementsByClassName('cdk-overlay-connected-position-bounding-box').length > 0) {
              document.getElementsByClassName('cdk-overlay-connected-position-bounding-box')[0].remove();
            }
          }          
      }
    
      shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.handlers["url"];    
      }
    
      retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
          if (!route) {
              return this.handlers[route.url.join("/") || route.parent.url.join("/")];
          }
      }
    
      shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
          return false;
      }
    
    }
