import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { ActionConfirmationComponent } from './action-confirmation/action-confirmation.component';
import { ConfirmationModalComponent } from './action-confirmation/confirmation-modal/confirmation-modal.component';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { AutoCloseDialogComponent } from './autoclose-dialog/autoclose-dialog.component';
import { WarningDialogUserComponent } from './warning-dialog-user/warning-dialog-user.component';
@NgModule({
  imports: [MatLegacyDialogModule, MatIconModule, MatLegacyButtonModule, NgxDaterangepickerMd, BrowserModule],
  entryComponents: [ConfirmationModalComponent, WarningDialogComponent, WarningDialogUserComponent, ActionConfirmationComponent, DateRangePickerComponent,
    AutoCloseDialogComponent],
  exports: [MatLegacyDialogModule, MatIconModule, MatLegacyButtonModule, ActionConfirmationComponent, ConfirmationModalComponent,
    DateRangePickerComponent, BrowserModule, AutoCloseDialogComponent],
  declarations: [ActionConfirmationComponent, ConfirmationModalComponent, WarningDialogComponent, DateRangePickerComponent,
    AutoCloseDialogComponent,
    WarningDialogUserComponent]
})
export class AppSharedModule { }
