<section class="page-content">
  <progress-bar [isLoading]="loadingIndicator"></progress-bar>
  <div class="users-grid-container">
    <div class="users-grid-header">
      <app-users-search (advancedSearchParams)="advancedSearch($event)" [hasResults]="hasResults"></app-users-search>
    </div>
    <div fxLayout="row">
      <div fxFlex style="text-align:center;">
        <h4 class="no-results-message">{{noResultsMessage}}</h4>
      </div>
    </div>
  </div>

  <div fxLayout="row">
    <div fxFlex>
      <mat-table *ngIf="hasResults" #table [dataSource]="dataSource" matSort matSortDisableClear="true" (matSortChange)="onSortData($event)">

        <!-- userName Column -->
        <ng-container matColumnDef="userName">
          <mat-header-cell *matHeaderCellDef fxFlex="30%" mat-sort-header="Username"> Username </mat-header-cell>
          <mat-cell fxFlex="30%" *matCellDef="let element">
            <a (click)="goToViewUserProfile(element?.username)"> {{element?.username}} </a>
          </mat-cell>
        </ng-container>

        <!-- firstName Column -->
        <ng-container matColumnDef="firstName">
          <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header="FirstName"> First Name </mat-header-cell>
          <mat-cell fxFlex="10%" *matCellDef="let element"> {{element?.firstName}} </mat-cell>
        </ng-container>

        <!-- lastName Column -->
        <ng-container matColumnDef="lastName">
          <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header="LastName"> Last Name </mat-header-cell>
          <mat-cell fxFlex="10%" *matCellDef="let element"> {{element?.lastName}} </mat-cell>
        </ng-container>

        <!-- dateOfBirth Column -->
        <ng-container matColumnDef="dateOfBirth">
          <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header="DateOfBirth"> DOB </mat-header-cell>
          <mat-cell fxFlex="10%" *matCellDef="let element"> {{element?.dateOfBirth | utcDate | date: 'MM/dd/yyyy'}} </mat-cell>
        </ng-container>

        <!-- gender Column -->
        <ng-container matColumnDef="gender">
          <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header="Gender"> Gender </mat-header-cell>
          <mat-cell fxFlex="10%" *matCellDef="let element"> {{element?.gender}} </mat-cell>
        </ng-container>

        <!-- idLevel Column -->
        <ng-container matColumnDef="idLevel">
          <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header="IdLevel"> User Level </mat-header-cell>
          <mat-cell fxFlex="10%" *matCellDef="let element"> {{element?.idLevel}} </mat-cell>
        </ng-container>

        <!-- createdDate Column -->
        <ng-container matColumnDef="createdDate">
          <mat-header-cell fxFlex="20%" *matHeaderCellDef mat-sort-header="CreatedDate"> Created Date Time <br/>  ({{timezoneAbbrevation}}) </mat-header-cell>
          <mat-cell fxFlex="20%" *matCellDef="let element">{{ element?.createdDate | date:'MMM d, y, h:mm:ss a' }} </mat-cell>
        </ng-container>

        <!-- Symbol Column -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>  
      <mat-paginator [ngClass]="rowCount == 0 ? 'no-result-paginator' : ''" #paginator   [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="(pageInfo$ | async).pageSize" [length]="rowCount" (page)="onPaginateChange($event)">
      </mat-paginator>
    </div>
  </div>
</section>
