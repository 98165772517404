import { Router } from '@angular/router';
import { CiamUser, UpdateRegionRequest, CiamAuth } from '@dignity-health/ciam-auth';
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Component, OnInit, Type, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../types/state';
import { UiService } from '../../../services/ui/ui.service';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { RegionsDetailsComponent } from '../regions-details/regions-details.component';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit {
  user: CiamUser;
  user$: Observable<CiamUser>;
  isLoading: boolean = false;
  showNoRowMsg: boolean;
  canCreateRegions: boolean;

  dataSource: MatLegacyTableDataSource<UpdateRegionRequest>;
  @ViewChild(MatLegacyTable)
  table: MatLegacyTable<UpdateRegionRequest>;

  displayedColumns = ['regionId', 'regionName', 'actions'];
  constructor(public ciamAuth: CiamAuth, private store: Store<IAppState>,
    private uiService: UiService,
    private dialog: MatLegacyDialog,
    private oktaGroupService: OktaSupportGroupsService,
    private barGroupService: BarGroupService,
    private router: Router) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => this.user = user);
    this.canCreateRegions = this.oktaGroupService.isSuperAdmin;
  }

  ngOnInit() {
    this.getRegions();
  }

  getRegions() {
    this.barGroupService.getRegions().subscribe((value) => {
      console.log(value.length);
      this.showNoRowMsg = !value.length;
      this.dataSource = new MatLegacyTableDataSource(value);
    });

    this.barGroupService.isLoading.subscribe((value) => {
      this.isLoading = value;
    });
  }

  createRegion() {
    this.showRegionDetails(null);
  }

  editRegion(regions: UpdateRegionRequest) {
    this.showRegionDetails(regions);
  }

  actionConfirmationData: IActionConfirmation = {
    matIcon: 'delete',
    modalTitle: 'Delete Region Confirmation',
    modalInstruction: 'Are you sure you want to delete the selected Region?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes',
    isAdvanceConfirmation: false
  }

  deleteRegion(confirmationValue: boolean, region: UpdateRegionRequest) {
    if (confirmationValue) {
      this.isLoading = true;
      this.ciamAuth.httpApiFacilities.apiAdminBargroupRemoveRegionByIdDelete(region.regionId)
        .then(response => {
          if (response.isValid) {
            this.uiService.openSnackbar('Selected region is successfully deleted', 'Success');
            region.isActive = false;
          }
          else if (response.errors.length > 0) {
            this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "admin/bargroup/remove-region/" + region.regionId);
          }

          this.isLoading = false;
          this.barGroupService.getRegions();
        });
    }
  }

  private showRegionDetails(region?: UpdateRegionRequest) {
    this.dialog.open(RegionsDetailsComponent, {
      width: '400px',
      data: region
    });
  }
}
