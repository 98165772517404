import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IAppState } from 'app/types';
import { PatientSearchRequest } from 'app/types/patientSearchRequest';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE } from 'app/types/constants';
import { Store } from '@ngrx/store';
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';

@Component({
  selector: 'app-patients-search',
  templateUrl: './patients-search.component.html',
  styleUrls: ['./patients-search.component.scss']
})

export class PatientsSearchComponent implements OnInit, OnChanges {

  @Input() searchType: string;
  @Input() hasResults = false;
  @Input() isLoading = false;
  @Output() patientSearchParams: EventEmitter<PatientSearchRequest> = new EventEmitter<PatientSearchRequest>();
  @Output() clearResult: EventEmitter<PatientSearchRequest> = new EventEmitter<PatientSearchRequest>();
  // Allowing any date but the year should be 4 digit
  minDate = MIN_DATE;
  maxDate = new Date();
  patientSearchForm: FormGroup;
  maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
  patientSearchRequest: PatientSearchRequest = <PatientSearchRequest>{
    patientId: '',
    mrn: '',
    lastName: '',
    firstName: '',
    dateOfBirth: null,
    isValidSearch: false,
    isExactSearch: false
  };;
  @Input() patientSearchObject = this.patientSearchRequest;

  constructor(
    private fb: FormBuilder,
    private store: Store<IAppState>,
    private cd: ChangeDetectorRef,
    private sharedSearchService: SharedSearchService
  ) {
    this.store.select(m => m.userSearch).subscribe(s => {
      if (!s || (!s.cernerSearch && !s.myCareSearch)) {
        return;
      }

    });

    this.sharedSearchService.resetObservable
      .subscribe((resetValue) => {
        if (resetValue) {
          this.clear();
        }
      });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.resetPatientSearchParams();
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      this.patientSearchRequest.isValidSearch = true;
      this.patientSearchRequest.firstName = existingPatientSearchDataOnPortal[0].firstName;
      this.patientSearchRequest.lastName = existingPatientSearchDataOnPortal[0].lastName;
      this.patientSearchRequest.dateOfBirth = existingPatientSearchDataOnPortal[0].dob ? new Date(existingPatientSearchDataOnPortal[0].dob) : null;
      this.patientSearchRequest.mrn = existingPatientSearchDataOnPortal[0].mrn;
      this.patientSearchRequest.isExactSearch = existingPatientSearchDataOnPortal[0].exactSearch;
      if (this.searchType.toLowerCase() == 'cerner')
        this.patientSearchRequest.patientId = existingPatientSearchDataOnPortal[0].personId;
    }

    if (this.patientSearchRequest.isValidSearch == true) {
      this.patientSearchForm = this.fb.group({
        'patientId': [this.patientSearchRequest.patientId],
        'mrn': [this.patientSearchRequest.mrn],
        'lastName': [this.patientSearchRequest.lastName],
        'firstName': [this.patientSearchRequest.firstName],
        'dateOfBirth': [this.patientSearchRequest.dateOfBirth ? this.patientSearchRequest.dateOfBirth : null],
        'exactSearch': [this.patientSearchRequest.isExactSearch]
      });

      if (existingPatientSearchDataOnPortal[0].firstName == '' && existingPatientSearchDataOnPortal[0].lastName == '' && existingPatientSearchDataOnPortal[0].dob === null && existingPatientSearchDataOnPortal[0].mrn == undefined) {
        this.patientSearchRequest.isValidSearch = false;
      }
      this.hasResults = true;
      this.patientSearchParams.emit(this.patientSearchRequest);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patientSearchObject && this.patientSearchForm) {
      this.hasResults = false;
      const controls = this.patientSearchForm.controls;
      controls['patientId'].setValue(this.patientSearchObject.patientId);
      controls['mrn'].setValue(this.patientSearchObject.mrn);
      controls['lastName'].setValue(this.patientSearchObject.lastName);
      controls['firstName'].setValue(this.patientSearchObject.firstName);
      controls['dateOfBirth'].setValue(this.patientSearchObject.dateOfBirth);
      controls['exactSearch'].setValue(this.patientSearchObject.isExactSearch);
    }
  }

  updateDobModel() {
    this.patientSearchForm.controls['dateOfBirth'].setValue(this.patientSearchForm.controls['dateOfBirth'].value);
  }

  valueChange(val: any) {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].exactSearch = this.patientSearchForm.controls['exactSearch'].value;
      existingPatientSearchDataOnPortal[0].firstName = this.patientSearchForm.controls['firstName'].value;//value.firstName;
      existingPatientSearchDataOnPortal[0].lastName = this.patientSearchForm.controls['lastName'].value;//value.lastName;
      existingPatientSearchDataOnPortal[0].dob = this.patientSearchForm.controls['dateOfBirth'].value;//value.dateOfBirth;
      existingPatientSearchDataOnPortal[0].mrn = this.patientSearchForm.controls['mrn'].value;//value.mrn;
      if (existingPatientSearchDataOnPortal[0].firstName != '' || existingPatientSearchDataOnPortal[0].lastName != '' || existingPatientSearchDataOnPortal[0].dob != null || existingPatientSearchDataOnPortal[0].mrn != '') {
        existingPatientSearchDataOnPortal[0].basicUserSearchText = undefined;
      }
      if (this.searchType.toLowerCase() == 'cerner')
        existingPatientSearchDataOnPortal[0].personId = this.patientSearchForm.controls['patientId'].value;

    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.firstName = this.patientSearchForm.controls['firstName'].value;
      newPatientSearch.lastName = this.patientSearchForm.controls['lastName'].value;
      newPatientSearch.exactSearch = this.patientSearchForm.controls['exactSearch'].value;
      newPatientSearch.mrn = this.patientSearchForm.controls['mrn'].value;
      newPatientSearch.dob = this.patientSearchForm.controls['dateOfBirth'].value;
      if (this.searchType.toLowerCase() == 'cerner')
        newPatientSearch.personId = this.patientSearchForm.controls['patientId'].value;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
  }

  modifySearch() {
    if (!this.patientSearchForm.valid) {
      return;
    }

    this.patientSearchObject.patientId = '';
    this.patientSearchObject.mrn = '';
    this.patientSearchObject.lastName = '';
    this.patientSearchObject.firstName = '';
    this.patientSearchObject.dateOfBirth = null;
    this.patientSearchObject.isValidSearch = false;
    this.patientSearchObject.isExactSearch = false;

    const controls = this.patientSearchForm.controls;
    this.patientSearchObject.patientId = controls['patientId'].value;
    this.patientSearchObject.mrn = controls['mrn'].value;
    this.patientSearchObject.lastName = controls['lastName'].value;
    this.patientSearchObject.firstName = controls['firstName'].value;
    this.patientSearchObject.dateOfBirth = controls['dateOfBirth'].value;
    this.patientSearchObject.isExactSearch = controls['exactSearch'].value;

    var filterArray = Object.keys(controls).map(function (personNamedIndex) {
      if (personNamedIndex != 'exactSearch') {
        let p = controls[personNamedIndex];
        return p.value !== null && p.value != '';
      }
    });

    // Ensure that length is greater than 1
    if (filterArray.filter((e) => {
      return e == true;
    }).length > 1) {
      this.patientSearchObject.isValidSearch = true;
    }

    this.hasResults = true;
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].firstName = this.patientSearchObject.firstName;
      existingPatientSearchDataOnPortal[0].lastName = this.patientSearchObject.lastName;
      existingPatientSearchDataOnPortal[0].dob = this.patientSearchObject.dateOfBirth;
      existingPatientSearchDataOnPortal[0].mrn = this.patientSearchObject.mrn;
      if (this.patientSearchObject.firstName != '' || this.patientSearchObject.lastName != '' || this.patientSearchObject.dateOfBirth != null) {
        existingPatientSearchDataOnPortal[0].basicUserSearchText = '';
      }
      if (this.searchType.toLowerCase() == 'cerner')
        existingPatientSearchDataOnPortal[0].personId = this.patientSearchObject.patientId;
    }
    else {
      if (this.patientSearchObject.firstName != '' || this.patientSearchObject.lastName != '' || this.patientSearchObject.mrn != '' || this.patientSearchObject.dateOfBirth != null || this.patientSearchObject.patientId != '') {
        var newPatientSearch = new PatientDetails();
        newPatientSearch.firstName = this.patientSearchObject.firstName;
        newPatientSearch.lastName = this.patientSearchObject.lastName;
        newPatientSearch.mrn = this.patientSearchObject.mrn;
        newPatientSearch.dob = this.patientSearchObject.dateOfBirth;
        if (this.searchType.toLowerCase() == 'cerner')
          newPatientSearch.personId = this.patientSearchObject.patientId;
        this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
      }
    }
    this.patientSearchParams.emit(this.patientSearchObject);
  }

  clear() {
    this.resetPatientSearchParams();
    const controls = this.patientSearchForm.controls;
    this.setPatientSearchValues(controls);
    this.patientSearchObject.isValidSearch = false;
    this.clearResult.emit(this.patientSearchObject);
    this.hasResults = false;
  }

  setPatientSearchValues(controls) {
    controls['patientId'].value = '';
    controls['firstName'].value = '';
    controls['lastName'].value = '';
    controls['mrn'].value = '';
    controls['dateOfBirth'].value = null;
    controls['exactSearch'].value = false;
  }

  resetPatientSearchParams() {
    if (this.patientSearchForm) {
      this.patientSearchForm.reset();
    } else {
      this.patientSearchForm = this.fb.group({
        'patientId': [''],
        'mrn': [''],
        'lastName': [''],
        'firstName': [''],
        'dateOfBirth': [null],
        'exactSearch': false
      });
    }
  }
}
