import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
export class EmailsValidator {
    static validEmails = (): ValidatorFn => {
        return (Control: AbstractControl): { [key: string]: boolean } => {
            var emails = Control.value.replace(/\s/g, '').split(";");
            emails = emails.filter((email: string) => email !== '');
            
            var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
           
            for (var i = 0; i < emails.length; i++) {
                if (!EMAIL_REGEXP.test(emails[i])) {
                    return {
                        validEmails: true
                    };
                }
            }
        };
    }
}