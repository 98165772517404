import { Component, OnInit, Inject } from '@angular/core';
import { PortalInvitation, PortalInvitationStatusType } from '@dignity-health/ciam-auth';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';;
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientPortalsInvitationDataSource } from 'app/types/patient-portals-invitation-datasource';

@Component({
  selector: 'expire-modal',
  templateUrl: './expire-modal.component.html',
  styleUrls: ['./expire-modal.component.scss']
})

export class ExpireModalComponent implements OnInit {

  constructor(
    public snackBar: MatSnackBar,
    public dialogRef: MatLegacyDialogRef<ExpireModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: PortalInvitation,
    public portalsDataSource: PatientPortalsInvitationDataSource
  ) {

  }

  ngOnInit() {
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  expireInvitation() {
    this.portalsDataSource.expireInvitation(this.data.invitationId)
      .then(response => {
        if (response.isValid) {
          this.openSnackBar('Selected invitation is successfully expired', 'Success');
          this.data.status = PortalInvitationStatusType.Expired;
        }
        else if (response.errors.length > 0) {
          this.openSnackBar(response.errors[0].message, 'Error');
        }
      });
  }
}
