import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'warning-dialog-user',
  templateUrl: './warning-dialog-user.component.html',
  styleUrls: ['./warning-dialog-user.component.scss']
})
export class WarningDialogUserComponent {
  public email: any;
  public oktaEnv: string = environment.oktaBaseUrl;
  public ciamEnv: string = "TBD"
  ciamUrl = environment.ciamUrl;
  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: any) {   
    if (this.ciamUrl == 'https://web-usw1-ciamweb-slot1-dev.ase-usw1-shared-ppe.p.azurewebsites.net')
      this.ciamEnv = 'DEV';
    else if (this.ciamUrl == 'https://web-usw1-ciamweb-slot2-tst.ase-usw1-shared-ppe.p.azurewebsites.net')
      this.ciamEnv = 'QA';
    else if (this.ciamUrl == 'https://web-usw1-ciamapi-stg.ase-usw1-shared-stg.p.azurewebsites.net')
      this.ciamEnv = 'STG';
    else if (this.ciamUrl == 'https://web-usw1-ciamapi-prd.ase-usw1-shared-prd.p.azurewebsites.net')
      this.ciamEnv = 'PROD';
  }
}
