import { Component, Inject, Input } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  disableConfirmButton: boolean = false;

  constructor( @Inject(MAT_LEGACY_DIALOG_DATA) public data: any) {
    this.disableConfirmButton = this.data.action.isAdvanceConfirmation;
  }

  validate(severObject) {
    this.disableConfirmButton = true;
    severObject.target.value = severObject.target.value.trim();
    if (severObject && severObject.target.value === this.data.action.advanceConfirmationValidationText) {
      this.disableConfirmButton = false;
    }
  }
}
