import { Component, Input, Inject, ViewChild } from '@angular/core';
import { BarGroup } from '@dignity-health/ciam-auth/src/types/interfaces';
import { FacilitiesService } from '../../../services/facilities/facilities-service';
import { UiService } from '../../../services/ui/ui.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../types';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';;
import { FacilityDomainInfo, FacilityDomain, UpdateFacilityRequest, Facility } from '@dignity-health/ciam-auth';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import * as _ from 'lodash';
import { FacilityDomainAssociationComponent } from 'app/components/facility/facility-domain-association/facility-domain-association.component';
import { REQUIRED_FIELD_MESSAGE, INVALID_LATITUDE_MESSAGE, INVALID_LONGITUDE_MESSAGE, INVALID_STATE_MESSAGE, INVALID_ZIP_MESSAGE, INVALID_CITY_MESSAGE, INVALID_ZIP_LENGTH_MESSAGE } from 'app/types/constants';
import { environment } from 'environments/environment';

@Component({
  selector: 'facilities-create-update',
  templateUrl: './facilities-create-update.component.html',
  styleUrls: ['./facilities-create-update.component.scss']
})
export class FacilitiesCreateUpdateComponent {
  domains: FacilityDomainInfo[];
  barGroups: BarGroup[];

  isLoading: boolean = false;
  chipSeparatorKeyCodes = [ENTER, COMMA];
  requiredFieldMessage = REQUIRED_FIELD_MESSAGE;

  validData = false;
  facilityId?: number = 0;
  request: UpdateFacilityRequest;
  regex = new RegExp('^-?[0-9]{1,3}(?:\.[0-9]{1,18})?$');
  regexCityState = new RegExp(/^[A-Z@~` !@#$%^&*()_=+\\';:"\/?>.<,-]*$/i);
  regexState = new RegExp(/^[A-Z@~`!@#$%^&*()_=+\\';:"\/?>.<,-]*$/i);
  regexZipCode = new RegExp('^[0-9]*$');
  validLatitude = true;
  validLongitude = true;
  validCity = true;
  validState = true;
  validZip = true;
  invalidLatitudeMessage = INVALID_LATITUDE_MESSAGE;
  invalidLongitudeMessage = INVALID_LONGITUDE_MESSAGE;
  invalidCityMessage = INVALID_CITY_MESSAGE;
  invalidStateMessage = INVALID_STATE_MESSAGE;
  invalidZipLength = INVALID_ZIP_LENGTH_MESSAGE;
  invalidZipMessage = INVALID_ZIP_MESSAGE;

  @ViewChild("facilityDomainAssociationComponent") child: FacilityDomainAssociationComponent;

  constructor(
    private store: Store<IAppState>,
    private facilitiesService: FacilitiesService,
    private uiService: UiService,
    private dialogRef: MatLegacyDialogRef<FacilitiesCreateUpdateComponent, FacilitiesCreateUpdateComponentData>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: FacilitiesCreateUpdateComponentData) {
    const facility = data.facility;
    this.facilityId = data.facility.facilityId;

    // Clone the facility to use to populate the update request

    this.request = {
      friendlyFacilityName: facility.friendlyFacilityName,
      isActive: facility.isActive,
      edwFacilityName: facility.edwFacilityName,
      barGroupId: (facility.barGroup || {}).barGroupId,
      latitude: facility.latitude,
      longitude: facility.longitude,
      registrationSystem: facility.registrationSystem,
      schedulingLocationId: facility.schedulingLocationId,
      webAddress: facility.webAddress,
      facilityDomains: !facility.domains ? null : _.cloneDeep(facility.domains).map(fd => {
        return {
          domainName: fd.name,
          isActive: fd.isActive,
          beginDate: fd.beginDate,
          endDate: fd.endDate
        };
      }),
      alternateFacilityNames: !facility.alternateNames ? null : _.clone(facility.alternateNames).map(a => a.alternateFacilityName),
      address: {
        line1: facility.addressLine1,
        line2: facility.addressLine2,
        zipCode: facility.zipCode,
        stateOrProvince: facility.stateOrProvince,
        city: facility.city,
        countryCode: facility.countryCode
      }
    }
    // Kick off a related info refresh
    this.isLoading = true;

    // subscribe to newest general info and pick out domains, bar groups, etc.
    this.store.select(s => s.info)
      .subscribe(info => {
        if (!info) {
          return this.refreshFacilitiesRelatedInfo();
        }

        this.isLoading = false;

        this.domains = info.domains;
        this.barGroups = info.barGroups;
      });
  }

  checkDomainFields = function () {
    return this.child.checkFields();
  }

  onAddDomainAssociation(association: FacilityDomain) {
    this.request.facilityDomains = this.request.facilityDomains || [];

    this.request.facilityDomains.push(association);
  }

  onRemoveDomainAssociation(association: FacilityDomain) {
    const domains = this.request.facilityDomains;

    const indexOfAssociation = domains.indexOf(association);
    domains.splice(indexOfAssociation, 1);
  }

  onAddAlternateFacilityName(inputElement: HTMLInputElement, name: string) {
    this.request.alternateFacilityNames = this.request.alternateFacilityNames || [];
    this.request.alternateFacilityNames.push(name);

    inputElement.value = null;
  }

  onRemoveAlternateFacilityName(name: string) {
    const names = this.request.alternateFacilityNames;

    const indexOfName = names.indexOf(name);
    names.splice(indexOfName, 1);
  }

  async refreshFacilitiesRelatedInfo(): Promise<void> {
    // Fire off request to get all domain/bargroup/etc info for population later
    const response = await this.facilitiesService.getAllFacilitiesRelatedInfo(true);
    if (response.errors && response.errors.length) {
      return this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "graphql/query/domains");
    }
  }

  validateLatitude(event): boolean {
    if (event.target.value == '') {
      this.validLatitude = true;
      return this.validLatitude;
    }
    this.validLatitude = this.regex.test(event.target.value) && event.target.value >= -90 && event.target.value <= 90;
    return this.validLatitude;
  }

  validateLongitude(event): boolean {
    if (event.target.value == '') {
      this.validLongitude = true;
      return this.validLongitude;
    }
    this.validLongitude = this.regex.test(event.target.value) && event.target.value >= -180 && event.target.value <= 360;
    return this.validLongitude;
  }

  validateCity(event): boolean {
    this.validCity = this.regexCityState.test(event.target.value);
    return this.validCity;
  }

  validateState(event): boolean {
    if (event.target.value.length > 1) {
      this.validState = this.regexState.test(event.target.value);
      this.request.address.stateOrProvince = event.target.value.toUpperCase();
      return this.validState;
    }
  }

  validateZipCode(event): boolean {
    if (event.target.value.length > 4) {
      this.validZip = this.regexZipCode.test(event.target.value);
      return this.validZip;
    }
  }

  async onClickSave() {
    try {
      if (!this.checkDomainFields()) {
        return;
      }

      // create facility
      let result: any = null;

      if (this.data.facility.facilityId == 0) {
        result = await this.facilitiesService.createFacility(this.request);
      }
      else {
        result = await this.facilitiesService.updateFacility(this.facilityId, this.request);
      }

      if (!result.isValid) {
        return this.uiService.showErrors(result.errors, true, environment.facilitiesApimUrl + "admin/facilities");
      }

      this.uiService.openSnackbar("Successfully saved facility!", "Awesome!");
      this.dialogRef.close({
        facility: result.result,
        didUpdate: true
      });
    } catch (e) {
      this.uiService.showException(e);
    }
  }

  onNoClick() {
    this.dialogRef.close({});
  }
}

export interface FacilitiesCreateUpdateComponentData {
  facility?: Facility;
  didUpdate?: boolean;
}