import { Injectable, InjectionToken } from '@angular/core';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { UnitServiceResponse } from '@dignity-health/ciam-auth/src/types/interfaces';
import { GraphQLPagedResponse, PagedResult, GraphQLQuery } from './graphql';
import { Subject } from 'rxjs';
import { debounceTime, switchMap,distinctUntilChanged } from 'rxjs/operators'
import 'rxjs';

@Injectable()
export class PatientPortalsInvitationDataSource {

  constructor(private ciamAuth: CiamAuth) {

  }

  search(query$: Subject<GraphQLQuery>) {
    
    return query$.pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(searchUsersQuery => this.searchInvitations(searchUsersQuery).then(data => {
        return data;
      })));

    // return query$.debounceTime(400)
    //   .distinctUntilChanged()
    //   .switchMap(searchUsersQuery => this.searchInvitations(searchUsersQuery).then(data => {
    //     return data;
    //   }));
  }

  searchInvitations(searchUsersQuery: GraphQLQuery) {
    return this.ciamAuth.httpApi.apiInfoQueryPost(searchUsersQuery, true);
  }

  resendInvitation(invitationId: number, recipientEmail: string): Promise<UnitServiceResponse> {
    return this.ciamAuth.httpApiPatientPortal.apiPortalsInvitationByInvitationIdUpdateAndResentPatch(invitationId, recipientEmail);
  }

  expireInvitation(invitationId: number): Promise<UnitServiceResponse> {
    return this.ciamAuth.httpApiPatientPortal.apiPortalsInvitationByInvitationIdExpirePost(invitationId);
  }
  disconnect(): void { }
}

export interface PatientSearchParameters {
  firstName: string;
  lastName: string;
  recipientEmail: string;
  dateOfBirth?: Date;
  domain: string;
  createdDateStart?: Date;
  createdDateEnd?: Date;
  statusId?: number;
}
