import { Component, OnInit } from '@angular/core';
import { CernerSSOConfigService } from '../../services/cerner-sso-config/cerner-sso-config';

@Component({
  selector: 'cerner-environment-configs',
  templateUrl: './cerner-environment-configs.component.html',
  styleUrls: ['./cerner-environment-configs.component.scss']
})
export class CernerEnvironmentConfigsComponent implements OnInit {
  isLoading = false;
  cernerSSOConfigDetails = null;
  constructor(private cernerSSOConfigService: CernerSSOConfigService) { }

  ngOnInit() {
    this.onRefreshConfigs();
  }

  async onRefreshConfigs() {
    this.isLoading = true;
    let response = await this.cernerSSOConfigService.GetCernerSSOConfigDetails();
    if (response.isValid) {
      this.isLoading = false;
      this.cernerSSOConfigDetails = response.result;
    }
    else {
      this.isLoading = false;
      this.cernerSSOConfigDetails = {'error' : 'Unable to fetch details from Cerner'};
    }
  }

  getIdpConfiguration(value) {
    let color = 'black';
    if (value === 'Okta') {
      color = '#007dc1';
    }
    if (value === 'Cerner') {
      color = '#79ba40'
    }
    return color;
  }
}
