export class EnrollmentTypeInfo {
    enrollmentType: string;
    hasError = false;
    enrollmentDisplayName: string;

    constructor (enrollTyp: string, hasErr: boolean, displayName: string) {
        this.enrollmentType = enrollTyp;
        this.hasError = hasErr;
        this.enrollmentDisplayName = displayName
    }
}
