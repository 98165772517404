export interface IdentityApiUserModel {
    firstName?: string,
    lastName?: string,
    email?: string,
    isEmailVerified?: boolean,
    emailVerificationCodeExpiration?: string,
    mobileNumber?: string,
    isMobileVerified?: boolean,
    mobileVerificationCodeExpiration?: string,
    isMfaEnabled?: boolean,
    isEnrollmentComplete?: boolean,
    userStatus?: boolean,
    createdDate?: string,
    modifiedDate?: string,
    lastModifiedBy?: string,
    dHomeId: string,
    accountLockDurationInMinute: number,
    failedVerificationAttemptCount: number
}

export interface AuditLogs {
    id: number;
    userId: number;
    email: string;
    eventType: string;
    createdBy: string;
    createdDate: string;
    changeLogs: Array<ChangeLogs>;
}

export interface ChangeLogs {
    id: number;
    auditLogId: number;
    fieldName: string;
    oldValue: string;
    newValue: string;
}

export class AuditLogsRequest {
    fromDate: string;
    toDate: string;
    email: string;
}

export class MarkEmailAsVerifiedRequest {
    email: string;
}

export class UnlockUserRequest {
    email: string;
}

export class UnlockUserResponse {
    IsSuccessful: boolean;
    ErrorMessage: string;
}

export class UserProfile {
    firstName: string
    lastName: string
    email: string
    isEmailVerified: boolean
    emailIdentifierExpiryDateTime: string
    mobileNumber: string
    isMobileVerified: boolean
    mobileIdentifierExpiryDateTime: string
    isMFAEnabled: boolean
    isEnrollmentComplete: boolean
    userStatus: boolean
    createdDate: string
    modifiedDate: string
    lastModifiedBy: string
    dHomeId: string
    isAccountLocked: boolean
    accountLockDurationInMinute: number
    failedVerificationAttemptCount: number
}

export class UpdateIdentityUserProfileRequest {
    email: string
    firstName: string
    lastName: string
    mobileNumber: string
    isMFAEnabled: boolean
}

export class EmailVerificationIdentifierRequest {
    email: string
    firstName: string
    mobileNumber: string
    brand: string
}

export class ErrorDetails {
    code?: string;
    message?: string;
    isSuccessful: boolean;
    errorMessage: string;
}

export class ResponseWrap {
    detail: string
    status: number
    errors: string[]
    isSuccessful: boolean
    errorMessage: string
}