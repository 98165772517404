import { Action } from '@ngrx/store';
import { FacilityDomainInfo, BarGroup } from '@dignity-health/ciam-auth';

export const UPDATE_DOMAINS_LISTING = "UPDATE_DOMAIN_LISTING";
export class UpdateDomainsListing implements Action {
    type: string = UPDATE_DOMAINS_LISTING;

    constructor(public payload: FacilityDomainInfo[]) { }
}

export const UPDATE_BAR_GROUPS_LISTINGS = "UPDATE_BAR_GROUPS_LISTINGS ";
export class UpdateBarGroupsListings implements Action {
    type: string = UPDATE_BAR_GROUPS_LISTINGS;

    constructor(public payload: BarGroup[]) { }
}
