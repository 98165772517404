import { Injectable } from "@angular/core";
import { CiamAuth, ServiceResponse, UnitServiceResponse } from "@dignity-health/ciam-auth";
import { SynonymMapResponse, SynonymMapName, SynonymMapMetadata } from "@dignity-health/ciam-auth/dist/src/types/provider-search/interfaces";
import { Synonym } from "app/types/azureSearch";

@Injectable()
export class AzureSearchService {

  constructor(private ciamAuth: CiamAuth) { }

  apiVersion: string = "2"

  getSynonymMapsMetadata(): Promise<ServiceResponse<SynonymMapMetadata>> {
    return this.ciamAuth.httpApiProviderSearch.apiSynonymsSynonymmapsmetadataGet(this.apiVersion)
  }

  getSynonymMap(synonymMapName: SynonymMapName): Promise<ServiceResponse<SynonymMapResponse>> {
    return this.ciamAuth.httpApiProviderSearch.apiSynonymsSynonymmapBySynonymMapNameGet(synonymMapName.name, synonymMapName.version, this.apiVersion);
  }

  saveSynonyms(synonymMapName: SynonymMapName, synonyms: Array<Synonym>): Promise<UnitServiceResponse> {
    return this.ciamAuth.httpApiProviderSearch.apiSynonymsSynonymmapBySynonymMapNamePut(synonymMapName.name, synonymMapName.version, synonyms, this.apiVersion);
  }

  deleteSynonym(synonymMapName: SynonymMapName, synonymMapDetailid:string): Promise<UnitServiceResponse> {
    return this.ciamAuth.httpApiProviderSearch.apiSynonymsSynonymmapBySynonymMapNameBySynonymDetailIdDelete(synonymMapName.name, synonymMapDetailid, synonymMapName.version, this.apiVersion);
  }
}
