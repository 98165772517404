<section *ngIf="user" class="page-content">
    <progress-bar [isLoading]="isLoading"></progress-bar>
    <mat-card>
        <mat-card-content>
            <form (ngSubmit)="saveWhitelistedDomains()" [formGroup]="form">
                <div>
                    <form-input [validationErrors]="validationErrors" [name]="'domainList'">
                        <mat-form-field>
                            <mat-label>Whitelist Domains</mat-label>
                            <mat-chip-list #chipList [formControl]="domainList">
                                <mat-chip *ngFor="let domain of whitelistedDomains" [removable]="removable" (removed)="removeChip(domain)">
                                    {{domain}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>

                                <input matInput type="text" matTextareaAutosize matAutosizeMaxRows="5" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addChip($event)" (paste)="pasteDomain($event)" [matChipInputAddOnBlur]="addChipOnBlur">
                            </mat-chip-list>
                            <mat-error *ngIf="domainList.hasError('required')">Please enter domains.</mat-error>
                            <mat-error *ngIf="domainList.hasError('pattern')">Please enter comma separated domains in format: abc.com, xyz.in</mat-error>
                            <mat-error *ngIf="domainList.hasError('duplicate')">Entered domain already exist in list.</mat-error>
                        </mat-form-field>
                    </form-input>
                </div>
                <div class="button-margin">
                    <mat-card-actions class="action-container">
                        <button mat-button mat-raised-button color="primary" tabindex="2">Save</button>
                    </mat-card-actions>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</section>
