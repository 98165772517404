import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CiamPersonGender, CiamPersonIdLevel } from '@dignity-health/ciam-auth';
import { IAppState } from 'app/types';
import { SEARCH_TYPE } from 'app/shared/enums/enums';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE } from 'app/types/constants';
import { UiService } from '../../../services/ui/ui.service';
import { UserSearchRequest } from '../../../types/userSearchRequest';
import { Store } from '@ngrx/store';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';
import { ConfirmationModalComponent } from 'app/shared/action-confirmation/confirmation-modal/confirmation-modal.component';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';

@Component({
  selector: 'app-users-search',
  templateUrl: './users-search.component.html',
  styleUrls: ['./users-search.component.scss']
})

export class UsersSearchComponent implements OnInit {
  basicUserSearchText: string;
  firstName: string;
  lastName: string;
  minDate = MIN_DATE;
  maxDate = new Date();
  genders = CiamPersonGender;
  levels = CiamPersonIdLevel;
  advancedSearchForm: FormGroup;
  searchType = SEARCH_TYPE.DIGNITY_HEALTH;
  maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
  showAdvancedSearch: boolean = true;
  @Input() hasResults;
  @Output() advancedSearchParams: EventEmitter<UserSearchRequest> = new EventEmitter<UserSearchRequest>();

  advancedSearchObject: UserSearchRequest = <UserSearchRequest>{
    username: '',
    firstName: '',
    lastName: '',
    dob: null,
    gender: null,
    userLevel: null,
    createdDate: null,
    inQuickerConfirmationNumber: '',
    isAdvancedUserSearch: false,
    basicUserSearchText: ''
  };

  constructor(
    private fb: FormBuilder,
    private uiService: UiService,
    private store: Store<IAppState>,
    private sharedSearchService: SharedSearchService,
    private dialog: MatLegacyDialog
  ) {
    this.store.select(m => m.userSearch).subscribe(s => {
      if (!s || !s.myHomeSearch) {
        return;
      }

      this.advancedSearchObject = Object.assign({}, s.myHomeSearch);
      this.advancedSearchObject.dob = s.myHomeSearch.dob ? new Date(s.myHomeSearch.dob) : null;
      this.advancedSearchObject.createdDate = s.myHomeSearch.createdDate ? new Date(s.myHomeSearch.createdDate) : null;
    });

    this.sharedSearchService.resetObservable
      .subscribe((resetValue) => {
        if (resetValue) {
          this.resetAdvancedSearchParams();
          this.basicUserSearchText = undefined;
        }
      });
  }

  ngOnInit() {
    this.resetAdvancedSearchParams();
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      this.advancedSearchObject.basicUserSearchText = existingPatientSearchDataOnPortal[0].basicUserSearchText;
      this.advancedSearchObject.username = existingPatientSearchDataOnPortal[0].userName;
      this.advancedSearchObject.firstName = existingPatientSearchDataOnPortal[0].firstName;
      this.advancedSearchObject.lastName = existingPatientSearchDataOnPortal[0].lastName;
      this.advancedSearchObject.inQuickerConfirmationNumber = existingPatientSearchDataOnPortal[0].inQuickerConfirmation;
      this.advancedSearchObject.dob = existingPatientSearchDataOnPortal[0].dob;
      this.advancedSearchObject.createdDate = existingPatientSearchDataOnPortal[0].createdDate;
      this.advancedSearchObject.gender = existingPatientSearchDataOnPortal[0].gender;
      this.advancedSearchObject.userLevel = existingPatientSearchDataOnPortal[0].userLevel;

      this.advancedSearchObject.isAdvancedUserSearch = true;
    }
    else {
      this.advancedSearchObject.basicUserSearchText = '';
      this.advancedSearchObject.firstName = '';
      this.advancedSearchObject.lastName = '';
      this.advancedSearchObject.username = '';
      this.advancedSearchObject.userLevel = null;
      this.advancedSearchObject.createdDate = null;
      this.advancedSearchObject.dob = null;
      this.advancedSearchObject.inQuickerConfirmationNumber = '';
      this.advancedSearchObject.gender = null;
    }
    if ((this.advancedSearchObject.basicUserSearchText != "" && this.advancedSearchObject.basicUserSearchText != undefined)
      || this.advancedSearchObject.isAdvancedUserSearch) {
      this.showAdvancedSearch = true;
      this.basicUserSearchText = this.advancedSearchObject.basicUserSearchText;
      this.advancedSearchForm = this.fb.group({
        'username': [this.advancedSearchObject.username],
        'lastName': [this.advancedSearchObject.lastName],
        'firstName': [this.advancedSearchObject.firstName],
        'dob': [this.advancedSearchObject.dob ? new Date(this.advancedSearchObject.dob) : null],
        'gender': [this.advancedSearchObject.gender],
        'userLevel': [this.advancedSearchObject.userLevel],
        'createdDate': [this.advancedSearchObject.createdDate ? new Date(this.advancedSearchObject.createdDate) : null],
        'inQuickerConfirmationNumber': [this.advancedSearchObject.inQuickerConfirmationNumber]
      });

      this.advancedSearchParams.emit(this.advancedSearchObject);
    }
  }

  valueChange() {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].firstName = this.advancedSearchForm.controls['firstName'].value;;
      existingPatientSearchDataOnPortal[0].lastName = this.advancedSearchForm.controls['lastName'].value;
      existingPatientSearchDataOnPortal[0].userName = this.advancedSearchForm.controls['username'].value;
      existingPatientSearchDataOnPortal[0].inQuickerConfirmation = this.advancedSearchForm.controls['inQuickerConfirmationNumber'].value;
      existingPatientSearchDataOnPortal[0].basicUserSearchText = this.basicUserSearchText;
      existingPatientSearchDataOnPortal[0].dob = this.uiService.formatDate(this.advancedSearchForm.controls['dob'].value);
      existingPatientSearchDataOnPortal[0].gender = this.advancedSearchForm.controls['gender'].value;
      existingPatientSearchDataOnPortal[0].userLevel = this.advancedSearchForm.controls['userLevel'].value;
      existingPatientSearchDataOnPortal[0].dob = this.advancedSearchForm.controls['dob'].value;
      existingPatientSearchDataOnPortal[0].createdDate = this.advancedSearchForm.controls['createdDate'].value;
    }

    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.firstName = this.advancedSearchForm.controls['firstName'].value;;
      newPatientSearch.lastName = this.advancedSearchForm.controls['lastName'].value;
      newPatientSearch.userName = this.advancedSearchForm.controls['username'].value;
      newPatientSearch.gender = this.advancedSearchForm.controls['gender'].value;
      newPatientSearch.userLevel = this.advancedSearchForm.controls['userLevel'].value;
      newPatientSearch.dob = this.advancedSearchForm.controls['dob'].value;
      newPatientSearch.createdDate = this.advancedSearchForm.controls['createdDate'].value;
      newPatientSearch.inQuickerConfirmation = this.advancedSearchForm.controls['inQuickerConfirmationNumber'].value;
      newPatientSearch.basicUserSearchText = this.basicUserSearchText;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
    if (existingPatientSearchDataOnPortal[0]?.basicUserSearchText != undefined)
      if (existingPatientSearchDataOnPortal[0]?.basicUserSearchText != "") {
        const dialogSearch = this.dialog.open(ConfirmationModalComponent, {
          data: {
            action: this.basicSearchBoxConfirmation
          },
          height: '200px',
          width: '500px'
        });
        dialogSearch.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.basicUserSearchText = undefined;
            existingPatientSearchDataOnPortal[0].basicUserSearchText = undefined;
            return true;
          } else {
            this.resetAdvancedSearchParams();
          }
        })
      }


  }

  modifySearch() {
    if (!this.advancedSearchForm.valid) {
      return;
    }

    var formValuesCount = 0;
    Object.keys(this.advancedSearchForm.value)
      .map(key => this.advancedSearchForm.value[key])
      .forEach(x => {
        if (x != null && x != "") {
          formValuesCount++;
        }
      });

    this.advancedSearchObject.basicUserSearchText = this.basicUserSearchText;
    if (this.showAdvancedSearch && formValuesCount > 0) {
      const controls = this.advancedSearchForm.controls;
      this.advancedSearchObject.gender = controls['gender'].value;
      this.advancedSearchObject.username = controls['username'].value;
      this.advancedSearchObject.lastName = controls['lastName'].value;
      this.advancedSearchObject.firstName = controls['firstName'].value;
      this.advancedSearchObject.userLevel = controls['userLevel'].value;
      this.advancedSearchObject.dob = this.uiService.formatDate(controls['dob'].value);
      this.advancedSearchObject.createdDate = this.uiService.formatDate(controls['createdDate'].value);
      this.advancedSearchObject.inQuickerConfirmationNumber = controls['inQuickerConfirmationNumber'].value;
      this.advancedSearchObject.isAdvancedUserSearch = true;
    } else {
      this.resetadvancedSearchObject(this.basicUserSearchText);
    }

    this.advancedSearchParams.emit(this.advancedSearchObject);
  }

  clearSearch() {
    this.resetAdvancedSearchParams();
    this.basicUserSearchText = '';
    this.resetadvancedSearchObject(this.basicUserSearchText);
    this.advancedSearchParams.emit(this.advancedSearchObject);
  }

  toggleAdvancedSearchControls() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
    this.advancedSearchObject.isAdvancedUserSearch = this.showAdvancedSearch;
    if (!this.showAdvancedSearch) {
      this.resetAdvancedSearchParams();
    }
  }

  updateDobModel() {
    this.advancedSearchForm.controls['dob'].setValue(this.advancedSearchForm.controls['dob'].value);
  }

  updateCreatedDateModel() {
    this.advancedSearchForm.controls['createdDate'].setValue(this.advancedSearchForm.controls['createdDate'].value);
  }

  resetAdvancedSearchParams(): void {
    // if (this.advancedSearchForm) {
    //   this.advancedSearchForm.reset();
    // } else {
    this.advancedSearchForm = this.fb.group({
      'username': [''],
      'lastName': [''],
      'firstName': [''],
      'dob': [null],
      'gender': [null],
      'userLevel': [null],
      'createdDate': [null],
      'inQuickerConfirmationNumber': ['']
    });
    //}
  }

  resetadvancedSearchObject(basicSearchText: string): void {
    this.advancedSearchObject = {
      username: '',
      firstName: '',
      lastName: '',
      dob: null,
      gender: null,
      userLevel: null,
      createdDate: null,
      inQuickerConfirmationNumber: '',
      isAdvancedUserSearch: false,
      basicUserSearchText: basicSearchText
    };
  }

  searchTypeChanged($event) {
    this.searchType = $event.value;
  }

  keyPress(event: KeyboardEvent) {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].basicUserSearchText = this.basicUserSearchText;
    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.basicUserSearchText = this.basicUserSearchText;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
    if ((this.advancedSearchForm.controls['firstName'].value != '' && this.advancedSearchForm.controls['firstName'].value != null)
      || (this.advancedSearchForm.controls['lastName'].value != '' && this.advancedSearchForm.controls['lastName'].value != null)
      || (this.advancedSearchForm.controls['dob'].value != '' && this.advancedSearchForm.controls['dob'].value != null)
      || (this.advancedSearchForm.controls['gender'].value != '' && this.advancedSearchForm.controls['gender'].value != null)
      || (this.advancedSearchForm.controls['userLevel'].value != '' && this.advancedSearchForm.controls['userLevel'].value != null)
      || (this.advancedSearchForm.controls['username'].value != '' && this.advancedSearchForm.controls['username'].value != null)
      || (this.advancedSearchForm.controls['createdDate'].value != '' && this.advancedSearchForm.controls['createdDate'].value != null)
      || (this.advancedSearchForm.controls['inQuickerConfirmationNumber'].value != '' && this.advancedSearchForm.controls['inQuickerConfirmationNumber'].value != null)) {
      const dialogSearch = this.dialog.open(ConfirmationModalComponent, {
        data: {
          action: this.searchBoxConfirmation
        },
        height: '200px',
        width: '500px'
      });
      dialogSearch.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.resetAdvancedSearchParams();
          var patient = existingPatientSearchDataOnPortal[0];
          patient.firstName = '',
            patient.lastName = '',
            patient.inQuickerConfirmation = '',
            patient.userName = '',
            patient.gender = null,
            patient.userLevel = null,
            patient.dob = null,
            patient.createdDate = null
          return true;
        } else {
          this.basicUserSearchText = undefined;
        }
      });
    }
    else {
      if ((existingPatientSearchDataOnPortal[0].mrn == '' || existingPatientSearchDataOnPortal[0].mrn == undefined)
        && (existingPatientSearchDataOnPortal[0].personId == '' || existingPatientSearchDataOnPortal[0].personId == undefined)
        && (existingPatientSearchDataOnPortal[0].toDate == null || existingPatientSearchDataOnPortal[0].toDate == undefined)
        && (existingPatientSearchDataOnPortal[0].fromDate == null || existingPatientSearchDataOnPortal[0].fromDate == undefined)
        && (existingPatientSearchDataOnPortal[0].invitationStatus == null || existingPatientSearchDataOnPortal[0].invitationStatus == undefined)
        && (existingPatientSearchDataOnPortal[0].domain == '' || existingPatientSearchDataOnPortal[0].domain == undefined)
        && (existingPatientSearchDataOnPortal[0].basicUserSearchText === undefined || existingPatientSearchDataOnPortal[0].basicUserSearchText == "")
      ) {
        this.sharedSearchService.removePatientDetailForSharedSearch();
      }
    }
  }

  searchBoxConfirmation: IActionConfirmation = {
    matIcon: 'alert',
    modalTitle: 'Search User Details',
    modalInstruction: 'Are you sure you want to override the search fields below ?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Ok',
    isAdvanceConfirmation: false
  }

  basicSearchBoxConfirmation: IActionConfirmation = {
    matIcon: 'alert',
    modalTitle: 'Search User Details',
    modalInstruction: 'Are you sure you want to override the basic search field ?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Ok',
    isAdvanceConfirmation: false
  }
}
