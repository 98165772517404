import { ServiceResponseError } from '@dignity-health/ciam-auth';
import { Component, Inject, Injectable, TemplateRef } from '@angular/core';
import { MatLegacyDialog, MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

export interface ErrorDialogData {
  errors: ServiceResponseError[];
  serviceUrl: string;
}

@Injectable()
export class UiService {
  constructor(private dialog: MatLegacyDialog, private datePipe: DatePipe, private snackbar: MatSnackBar) { }

  openSnackbar(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    config = {
      duration: 5000,
      ...config
    };

    return this.snackbar.open(message, action, config);
  }

  showException(exception: any) {
    this.snackbar.open(`Something bad happened!\n${exception}`, 'Whoops!', {
      duration: 0
    });

    this.showErrors([
      {
        code: "unknown",
        message: `Something really bad happened. Please let your administrator know!`
      },
      {
        code: "stack-trace",
        message: exception
      }
    ], true, '');
  }

  showErrors(errors: ServiceResponseError[], showErrorCode: boolean = false, serviceUrl: string = '') {
    const data: ErrorDialogData = {
      errors: errors,
      serviceUrl: serviceUrl
    };

    try {
      console.log('error details:', errors);
    } catch (e) {
      // suppress the exception in case of console not be be available in the execution context
    }

    this.dialog.open(UiServiceErrorsDialogComponent, {
      data: data
    });
  }

  formatDate(date?: Date): any {
    return date ? this.datePipe.transform(date, 'MM/dd/yyyy') : null;
  }

  turnLocalDateIntoUtcDate(localDate: Date): Date {
    if (!localDate) {
      return null;
    }

    return new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()));
  }
}

@Component({
  selector: 'ui-service-errors-dialog',
  template: `
  <section [ngSwitch]="hasErrors()">
  <section *ngSwitchCase="true">
    <h3>Uh-oh! Something went wrong:</h3>
    <div *ngIf ="serviceUrl != ''" >
    <mat-divider></mat-divider>   
        <div  class="mat-caption">From: {{serviceUrl}} </div>
    <mat-divider></mat-divider>
    </div>
    <span  *ngIf="errors">
    <ul *ngFor="let error of errors">
    <li *ngIf="error?.message!=undefined"><span  *ngIf="error?.code===undefined">Generic Error: </span>  <span  *ngIf="error?.code!=undefined">{{error?.code}}: </span><span>{{error?.message}}</span></li>
    </ul>
    </span>

  <div mat-dialog-actions align="end">
    <button mat-raised-button class="mat-primary update-profile-button" matDialogClose>Ok</button>
  </div>
  </section>

  <section *ngSwitchCase="false">
    <h3>Huh. Someone opened an error dialog without any errors...</h3>
  </section>
</section>
  `,
  styles: ['mat-divider {border: 1.5px solid #A8A8A8; margin-bottom:10px!important; margin-top:10px!important;}']
})
export class UiServiceErrorsDialogComponent {
  errors: ServiceResponseError[];
  serviceUrl: string;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: ErrorDialogData) {
    this.errors = data.errors;
    this.serviceUrl = data.serviceUrl;
  }

  hasErrors(): boolean {
    return this.errors && this.errors.length != 0;
  }
}
