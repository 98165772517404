<div class="search-component" [ngClass]="{'on-top': this.hasResults}" fxLayout="column" fxLayoutGap="10px"
  fxLayoutAlign="center">
  <form [formGroup]="patientSearchForm">
    <div fxFlex="100%">
      <div class="border exact-search-chk">
        <mat-checkbox title="Exact Search" (input)="valueChange($event)" style="margin:1%;"
          formControlName="exactSearch">Exact Search</mat-checkbox>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <span class="border exact-search-mat" fxFlex="30%">
          <mat-form-field fxFlex="45%" class="exact-search-field">
            <input matInput type="text" (input)="valueChange($event)" title="First Name" placeholder="First Name"
              formControlName="firstName" />
          </mat-form-field>

          <mat-form-field fxFlex="45%" class="exact-search-field">
            <input matInput type="text" (input)="valueChange($event)" title="Last Name" placeholder="Last Name"
              formControlName="lastName" />
          </mat-form-field>
        </span>
        <mat-form-field fxFlex="15%">
          <input matInput (blur)="updateDobModel()" title="Date of Birth" [matDatepicker]="picker" [min]="minDate"
            [max]="maxDate" placeholder="DOB" formControlName="dateOfBirth" (dateInput)="valueChange($event)"
            (dateChange)="valueChange($event)" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="patientSearchForm.controls['dateOfBirth'].hasError('matDatepickerMin')">Please enter DOB in
            format MM/DD/YYYY.</mat-error>
          <mat-error
            *ngIf="patientSearchForm.controls['dateOfBirth'].hasError('matDatepickerMax')">{{maxDateErrorMessage}}</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="10%">
          <input matInput type="text" (input)="valueChange($event)" title="MRN" placeholder="MRN"
            formControlName="mrn" />
        </mat-form-field>

        <span class="text-style" *ngIf="searchType=='Cerner'">OR</span>

        <mat-form-field fxFlex="10%" *ngIf="searchType=='Cerner'">
          <input matInput type="text" (input)="valueChange($event)" title="Person ID" placeholder="Person ID"
            formControlName="patientId" />
        </mat-form-field>

        <div fx-flex="35%">
          <button class="search-button" mat-raised-button [color]="'primary'" [disabled]="isLoading"
            (click)="modifySearch()">
            <mat-icon>search</mat-icon> Search Patients
          </button>
          <button class="search-button" mat-raised-button [color]="'accent'" [hidden]="!this.hasResults"
            (click)="clear()">
            <mat-icon>clear_all</mat-icon> Clear
          </button>
        </div>
      </div>
    </div>
  </form>
</div>