import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MIN_DATE, MAX_DATE_ERROR_MESSAGE } from 'app/types/constants';
import { PatientEnrollmentSearchRequest } from '../../../types/patient-enrollment-search-request';
import { UiService } from 'app/services/ui/ui.service';
import { max } from "rxjs/operators";
import { environment } from "environments/environment";
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';

@Component({
    selector: 'app-patient-enrollments-search',
    templateUrl: './patient-enrollments-search.component.html',
    styleUrls: ['./patient-enrollments-search.component.scss']
})

export class PatientEnrollmentsSearchComponent implements OnInit {
    @Input() searchType: string;
    patientEnrollmentsSearchForm: FormGroup;
    // Allowing any date but the year should be 4 digit

    maxDate = new Date();
    fromDate: Date;
    maxDateErrorMessage = MAX_DATE_ERROR_MESSAGE;
    username: string = '';
    toDate: Date;
    portalName: string;
    state: string;
    enrollmentType: string;

    @Input() hasResults = false;
    @Input() isLoading = false;
    @Output() patientEnrollmentsSearchParams = new EventEmitter<PatientEnrollmentSearchRequest>();

    constructor(private fb: FormBuilder, private uiService: UiService, private sharedSearchService: SharedSearchService) {
        this.sharedSearchService.resetObservable
            .subscribe((resetValue) => {
                if (resetValue) {
                    this.username = '';
                    this.portalName = '';
                    this.toDate = new Date();
                    this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
                    this.state = null;
                    this.enrollmentType = null;
                    this.resetPatientEnrollmentSearchParams();
                }
            });
    }

    ngOnInit() {
        this.toDate = new Date();
        this.fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
        this.resetPatientEnrollmentSearchParams();
    }

    valueChange() {
        var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
        const controls = this.patientEnrollmentsSearchForm.controls;
        this.fromDate = controls['fromDate'].value;
        var username = controls['userName'].value.trim();
        if (existingPatientSearchDataOnPortal.length > 0) {
            existingPatientSearchDataOnPortal[0].userName = username.trim();
            existingPatientSearchDataOnPortal[0].enrollmentFromDate = controls['fromDate'].value;
            existingPatientSearchDataOnPortal[0].enrollmentToDate = controls['toDate'].value;
            existingPatientSearchDataOnPortal[0].enrollmentPortalName = controls['portalName'].value;
            existingPatientSearchDataOnPortal[0].enrollmentType = controls['type'].value;
            existingPatientSearchDataOnPortal[0].enrollmentState = controls['states'].value;
            if (username != '') {
                existingPatientSearchDataOnPortal[0].basicUserSearchText = '';
            }
        }
        else {
            var newPatientSearch = new PatientDetails();
            newPatientSearch.userName = username.trim();
            newPatientSearch.enrollmentFromDate = controls['fromDate'].value;
            newPatientSearch.enrollmentToDate = controls['toDate'].value;
            newPatientSearch.enrollmentPortalName = controls['portalName'].value;
            newPatientSearch.enrollmentState = controls['states'].value;
            newPatientSearch.enrollmentType = controls['type'].value;
            this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
        }
    }

    modifySearch() {
        if (!this.patientEnrollmentsSearchForm.valid) {
            return;
        }
        const controls = this.patientEnrollmentsSearchForm.controls;

        const patientEnrollmentSearchObject: PatientEnrollmentSearchRequest = <PatientEnrollmentSearchRequest>{
            portalName: controls['portalName'].value,
            userName: controls['userName'].value,
            status: controls['states'].value,
            patientEnrollmentType: controls['type'].value,
            startDateTime: controls['fromDate'].value,
            endDateTime: controls['toDate'].value
        };

        this.patientEnrollmentsSearchParams.emit(patientEnrollmentSearchObject);
        this.hasResults = true;
    }

    resetPatientEnrollmentSearchParams() {
        var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
        if (existingPatientSearchDataOnPortal.length > 0) {
            this.username = existingPatientSearchDataOnPortal[0].userName;
            this.portalName = existingPatientSearchDataOnPortal[0].enrollmentPortalName;
            if (existingPatientSearchDataOnPortal[0].enrollmentFromDate != undefined)
                this.fromDate = existingPatientSearchDataOnPortal[0].enrollmentFromDate;
            if (existingPatientSearchDataOnPortal[0].enrollmentToDate != undefined)
                this.toDate = existingPatientSearchDataOnPortal[0].enrollmentToDate;
            this.state = existingPatientSearchDataOnPortal[0].enrollmentState;
            this.enrollmentType = existingPatientSearchDataOnPortal[0].enrollmentType;
        }

        this.patientEnrollmentsSearchForm = this.fb.group({
            'portalName': [this.portalName],
            'userName': [this.username],
            'states': this.state,
            'type': this.enrollmentType,
            'fromDate': this.fromDate,
            'toDate': this.toDate
        });
    }

    patientEnrollmentStatuses = [
        { value: 'InProgress', name: 'In Progress' },
        { value: 'Completed', name: 'Completed' },
        { value: 'Error', name: 'Error' }
    ];

    patientEnrollmentTypes = [
        { value: 'Migration', name: 'Migration' },
        { value: 'SelfEnrollment', name: 'Self Enrollment' },
        { value: 'Invitation', name: 'Invitation' }
    ];
}
