import { Component, OnInit, Input, ElementRef, Inject } from '@angular/core';
import { MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CiamAuth, ServiceResponse, BarGroup, Facility } from '@dignity-health/ciam-auth';
import { UpdateFacilitiesBarGroupRequest } from '@dignity-health/ciam-auth/src/types/interfaces';
import { UiService } from '../../../services/ui/ui.service';
import { ValidationErrors, ValidationMessages } from '../../../types/forms';
import { getValidationErrors } from '../../../types/helpers';
import { BarGroupService } from 'app/services/bar-group/bar-group-service';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';

const validationMessages = {
  barGroupFacility: {
    'required': 'Bar Group Facility is required'
  },
  barGroupId: {
    'required': 'Bar Group is required'
  }
}

@Component({
  selector: 'app-bargroup-facilities',
  templateUrl: './bargroup-facilities.component.html',
  styleUrls: ['./bargroup-facilities.component.scss']
})

export class BarGroupFacilitiesComponent implements OnInit {
  barGroups: BarGroup[] = [];

  facilities: Facility[] = [];
  barGroupFacilities: Facility[] = [];
  selectedBarGroupFacilities: Facility[];

  query = '';
  barGroupFacilitiesRequest: UpdateFacilitiesBarGroupRequest;

  showLoader: boolean = false;
  form: FormGroup;
  validationErrors: ValidationErrors = {};

  constructor(
    private uiService: UiService,
    private ciamAuth: CiamAuth,
    private snackBar: MatSnackBar,
    private barGroupService: BarGroupService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getBarGroups();
    this.form = this.fb.group({
      'barGroupId': [0, Validators.required],
      'barGroupFacility': ['']
    });

    this.form.valueChanges.subscribe(data => this.onFormValuesChanged(data));
  }

  onFormValuesChanged(data: any) {
    this.validationErrors = getValidationErrors(this.form, validationMessages);
  }

  getBarGroups() {
    this.barGroupService.getBarGroups().subscribe((barGroups) => {
      this.barGroups = barGroups;
    });

    this.barGroupService.getFacilities().subscribe((facilities) => {
      this.facilities = facilities;
    });
  }

  onChangeGroup(barGroupId: number) {
    this.query = '';
    this.barGroupFacilities = [];
    this.selectedBarGroupFacilities = [];
    this.getFacilitiesByBarGroup(barGroupId);
  }

  getFacilitiesByBarGroup(barGroupId: number): void {
    if (barGroupId && barGroupId != 0) {
      this.barGroupService.getFacilitiesByBarGroupId(barGroupId).subscribe((barGroup) => {
        if (barGroup && barGroup.facilities) {
          this.selectedBarGroupFacilities = barGroup.facilities;
        }
      });
    }
  }

  setBarGroupFacility(item) {
    this.selectedBarGroupFacilities.push(item);
    this.query = '';
    this.barGroupFacilities = [];
  }

  removeBarGroupFacility(item) {
    this.selectedBarGroupFacilities.splice(this.selectedBarGroupFacilities.indexOf(item), 1);
  }

  searchBarGroupFacilities() {
    if (!this.query) {
      this.barGroupFacilities = [];
      return;
    }

    this.barGroupFacilities = this.facilities.filter(facility => {
      const selectedFacility = this.selectedBarGroupFacilities.find(barGroupFacility => barGroupFacility.edwFacilityName == facility.edwFacilityName);

      return !selectedFacility
        ? facility.edwFacilityName.toLowerCase().substr(0, this.query.length) === this.query.toLowerCase()
        : false;
    });
  }

  onFormSubmit() {
    const controls = this.form.controls;
    if (!this.form.valid) {
      return;
    }

    this.saveBarGroupFacilities(controls['barGroupId'].value);
  }

  saveBarGroupFacilities(barGroupId: number) {
    if (!barGroupId || !(this.selectedBarGroupFacilities && this.selectedBarGroupFacilities.length)) {
      this.uiService.openSnackbar('Please select bar group & facilities need to be updated', 'Error');
      return;
    }

    this.updateBarGroupFacilities(barGroupId);
  }

  async updateBarGroupFacilities(barGroupId: number) {
    this.showLoader = true;

    const response = await this.ciamAuth.httpApiFacilities.apiAdminFacilitiesSetFacilitiesPatch({
      barGroupId: barGroupId,
      facilityIds: this.selectedBarGroupFacilities.map(f => f.facilityId)
    });

    this.showLoader = false;

    if (response.errors.length) {
      this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "admin/facilities/set-facilities");
      return;
    }

    this.uiService.openSnackbar('Bar Group Facilities successfully updated', 'Success');
  }
}
