<section class="page-content" *ngIf="user">
  <mat-card-title>Patient Enrollment Failure(s)</mat-card-title>

  <div>
    <progress-bar [isLoading]="isLoading"></progress-bar>
    <button mat-raised-button color="primary" class="create-button-margin" (click)="goToPatientEnrollments()">Go to Patient Enrollments</button>

    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="errorId">
        <mat-header-cell *matHeaderCellDef fxFlex="10%">Error Id</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="10%"> {{row.errorId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="attemptNumber">
        <mat-header-cell *matHeaderCellDef fxFlex="10%">Attempt Number</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="10%"> {{row.attemptNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="errorCode">
        <mat-header-cell *matHeaderCellDef fxFlex="30%">Error Code</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="30%"> {{row.errorCode}} </mat-cell>
        od
      </ng-container>

      <ng-container matColumnDef="errorMessage">
        <mat-header-cell *matHeaderCellDef fxFlex="50%">Error Message</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="50%"> {{row.errorMessage}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <div class="message-text" *ngIf="!isValid">
      No Records to show!
    </div>

  </div>
</section>
