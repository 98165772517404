import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';;
import { IdentityService } from 'app/services/identity-logs/identity-service';
import { PortalInvitation } from '../../../types/portalInvitations';
import { SendPortalInvitationRequestInvitationSource, SendPortalInvitationRequestPatientRelationshipToRecipient, PortalInvitationStatusType } from '@dignity-health/ciam-auth/src/types/patient-portals/portal-Invitation';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'patient-invitation-detail-information',
  templateUrl: 'patient-invitation-detail-information.component.html',
  styleUrls: ['patient-invitation-detail-information.component.scss']
})
export class PatientInvitationDetailInformationComponent implements OnInit {
  invitationId: number;

  patientFirstName: String;
  patientLastName: String;
  patientDateOfBirth: String;
  gender: String;
  patientRelationshipToRecipient: string;
  patientPersonId: String;
  mrn: String;

  status: string;
  createdDate: Date;
  dateSent: Date;
  dateClaimed: Date;
  expiredDate: Date;
  modifiedDate: Date;

  recipientFirstName: String;
  recipientLastName: String;
  recipientEmail: String;
  recipientPersonId: String;

  invitationSource: string;
  domainName: String;
  domainId: String;
  verificationCode: String;
  invitationResendStatus: String;
  invitationSourceSystem: String;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatLegacyDialogRef<PatientInvitationDetailInformationComponent, PatientInvitationDetailInformationComponentData>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: PatientInvitationDetailInformationComponentData, private identityService: IdentityService, private dialog: MatLegacyDialog) {

    this.invitationId = data.patientinvitationDetail.invitationId;

    this.patientFirstName = data.patientinvitationDetail.patientFirstName;
    this.patientLastName = data.patientinvitationDetail.patientLastName;
    if(data?.patientinvitationDetail?.patientDateOfBirth)
    {
    this.patientDateOfBirth = data.patientinvitationDetail.patientDateOfBirth.toString();
    }
    this.patientRelationshipToRecipient = data.patientinvitationDetail.patientRelationshipToRecipient;
    this.patientPersonId = data.patientinvitationDetail.patientSourceId;
    this.mrn = data.patientinvitationDetail.mrn;


    this.status = data.patientinvitationDetail.status;
    this.createdDate = data.patientinvitationDetail.createdDate;
    this.dateSent = data.patientinvitationDetail.dateSent
    this.dateClaimed = data.patientinvitationDetail.dateClaimed
    this.modifiedDate = data.patientinvitationDetail.modifiedDate;
    this.recipientFirstName = data.patientinvitationDetail.recipientFirstName;
    this.recipientLastName = data.patientinvitationDetail.recipientLastName;
    this.recipientEmail = data.patientinvitationDetail.recipientEmail;
    this.domainName = data.patientinvitationDetail.domainName;
    this.domainId = data.patientinvitationDetail.domainId;
    this.verificationCode = data.patientinvitationDetail.verificationCode;
    this.invitationSource = data.patientinvitationDetail.invitationSource;
    this.recipientPersonId = data.patientinvitationDetail.principalSourceId;
    this.invitationResendStatus = data.patientinvitationDetail.invitationResendStatus;
    this.gender = data.patientinvitationDetail.patientGender;
    this.invitationSourceSystem = data.patientinvitationDetail.invitationSourceSystem;
  }

  ngOnInit(): void {
  }

  calculateExpirationDate(): Date {
    return this.status.toLocaleString().toLocaleLowerCase() === 'expired' ? new Date(this.modifiedDate) : null;
  }
}

export interface PatientInvitationDetailInformationComponentData {
  patientinvitationDetail: PortalInvitation;
  didUpdate?: boolean;
}