import { UPDATE_ACTIVE_FACILITY } from '../actions/facility';
import { Facility } from '@dignity-health/ciam-auth';

export function facility(state: Facility, { type, payload }) {
    switch(type) {
        case UPDATE_ACTIVE_FACILITY:
            return payload;
        
        default:
            return state;
    }
}