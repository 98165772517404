<div>
  <section [ngSwitch]="resolvingUser">
    <section *ngSwitchCase="false" [ngSwitch]="user != null" class="mt-2">
      <section *ngSwitchCase="true">
        <span>
          {{user.username}}
          <span>&nbsp;&nbsp;</span>
          <mat-button type="button" placement="bottom-right" (mouseclick)="p.open()"
                      #p="ngbPopover" [ngbPopover]="popContent">
            <mat-icon>account_circle</mat-icon>
          </mat-button>
        </span>
        
        <ng-template #popContent>
          <label class="mat-label-title">Color Scheme:</label>
          <br />
          <mat-radio-group fxLayout="column" fxLayoutGap="10px" [ngModel]="currentSelectedColorScheme" (change)="changeColorScheme($event)">
            <mat-radio-button *ngFor="let cs of colorSchemeList" [value]="cs.schemaClassName">
              {{ cs.schemaDisplayName }}
            </mat-radio-button>
          </mat-radio-group>          
          <hr />
          <button mat-button (click)="signOut()">Sign Out</button>
        </ng-template>
        
      </section>
    </section>
  </section>
</div>
