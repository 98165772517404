<div [@fadeInOut] class="page-content" fxLayout="column" fxLayoutAlign="center center">
    <div>
        <mat-icon class="mat-icon-lg vertical-center">map</mat-icon>
        <h1 class="vertical-center" style="margin: 0;">404</h1>
    </div>
    <p>Page Not Found</p>
    <button mat-raised-button color="primary" routerLink="/">
        <mat-icon>home</mat-icon>
        <span>Back to Home</span>
    </button>
</div>
