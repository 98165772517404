import { Component, OnInit } from '@angular/core';
import { SEARCH_TYPE } from 'app/shared/enums/enums';
import { ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { IAppState } from 'app/types';

@Component({
  selector: 'users-and-patients',
  templateUrl: './users-and-patients.component.html',
  styleUrls: ['./users-and-patients.component.scss']
})
export class UsersAndPatientsComponent implements OnInit {

  searchType: string = SEARCH_TYPE.DIGNITY_HEALTH;
  constructor(private route: ActivatedRoute, private store: Store<IAppState>,) {
    this.route.url.subscribe(params => {
      if (params[0].path === 'users-cerner-millennium') {
        localStorage.setItem("MyHelp_UserSearchType", JSON.stringify(SEARCH_TYPE.CERNER_MILLENIUM));
        this.searchType = SEARCH_TYPE.CERNER_MILLENIUM;
      }
      else if (params[0].path === 'users-patient-portals') {
        localStorage.setItem("MyHelp_UserSearchType", JSON.stringify(SEARCH_TYPE.PATIENT_PORTALS));
        this.searchType = SEARCH_TYPE.PATIENT_PORTALS;
      }
      else {
        localStorage.setItem("MyHelp_UserSearchType", JSON.stringify(SEARCH_TYPE.DIGNITY_HEALTH));
        this.searchType = SEARCH_TYPE.DIGNITY_HEALTH;
      }
    });
  }

  ngOnInit() {
    if (localStorage.getItem("MyHelp_UserSearchType") != null) {
      this.searchType = JSON.parse(localStorage.getItem('MyHelp_UserSearchType'));
    }
  }

  searchTypeChanged(searchType: string) {
    this.searchType = searchType;
  }

  checkIfUsersSearch() {
    return this.searchType == SEARCH_TYPE.DIGNITY_HEALTH;
  }

  checkIfPatientsSearch() {
    return this.searchType == SEARCH_TYPE.PATIENT_PORTALS || this.searchType == SEARCH_TYPE.CERNER_MILLENIUM;
  }
}
