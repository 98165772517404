<div class="outerDiv">
    <div class="contentDiv">
        The temporary password must be given to the customer on the phone call. After the customer logs in with this
        temporary password,
        they will be asked to set a new password.
        Advise the customer to set up the password at least 10 characters long or better more.
        Tell them that this is important in our days as simple passwords can be easily cracked.
    </div>
    <div class="actionDiv">
        <action-confirmation class="float-right" [actionConfirmation]="temporaryPasswordActionConfirmationData"
            (userResponse)="temporaryPasswordResponse($event)">
        </action-confirmation>
        <mat-spinner *ngIf="showTemporaryPasswordSpinner" class="float-left" [diameter]="30"></mat-spinner>
    </div>
</div>