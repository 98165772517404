<div [ngClass]="getThemeClass()">
  <div id="app_container" class="nav-container mat-app-background">
    <mat-toolbar color="primary" class="app-toolbar mat-elevation-z6">
      <button *ngIf="showMenu$ | async" mat-icon-button (click)="expandLeftMenu()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 class="app-name">
        <a [routerLink]="'/'">My Help</a>
      </h1>
      <span class="app-toolbar-spacer"></span>
      <login-navbar></login-navbar>
    </mat-toolbar>
    <as-split direction="horizontal" [gutterSize]="gutterSize">
      <as-split-area [size]="navWidth" flexOrder="0" class="app-sidenav-nav mat-elevation-z6">
        <mat-nav-list *ngIf="showMenu$ | async">
          <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>send</mat-icon>Invitations
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.patientPortals.cerner.invitations.list"
              [routerLink]="'/cerner-invitations'" [routerLinkActiveOptions]="{exact:true}"
              routerLinkActive="active-link">
              Portal Invitations
            </a>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>group</mat-icon>Users
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.users.search" [routerLink]="'/users-dignity-health'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
              Dignity Health
            </a>
            <a mat-list-item *ngIf="flags.navigation.users.patientportals.view" [routerLink]="'/users-patient-portals'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
              Patient Portal
            </a>
            <a mat-list-item *ngIf="flags.navigation.users.cernermillenium.view"
              [routerLink]="'/users-cerner-millennium'" routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact:true}">
              Cerner Millennium
            </a>
            <a mat-list-item *ngIf="flags.navigation.users.identity.view" [routerLink]="'/users-consumer-identity'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
              Consumer Identity
            </a>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showSupportLog()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>search</mat-icon>Search Logs
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.logs.search" [routerLink]="'/support-log-viewer'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
              Support Logs
            </a>
            <a mat-list-item *ngIf="flags.navigation.logs.enrollments.list" [routerLink]="'/patient/enrollments'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
              Patient Enrollments
            </a>
            <a mat-list-item *ngIf="flags.navigation.logs.identity.list" [routerLink]="'/identity-audit-logs'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
              Identity Audit
            </a>

          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showEnterpriseEvents()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>description</mat-icon>Enterprise Events
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.enterpriseEvents.monitoring.view" routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact:true}" [routerLink]="'/enterprise-events/events-monitoring'">
              Monitoring
            </a>
            <a mat-list-item *ngIf="flags.navigation.enterpriseEvents.replayEngine.view" routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact:true}" [routerLink]="'/enterprise-events/replay-engine'">
              Replay Engine
            </a>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>dashboard</mat-icon> Entities
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.entities.facilities" [routerLink]="'/entities/facilities'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Facilities</a>
            <a mat-list-item *ngIf="flags.navigation.entities.bargroup" [routerLink]="'/entities/bargroups'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Bar Groups</a>
            <a mat-list-item *ngIf="flags.navigation.entities.region" [routerLink]="'/entities/regions'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Regions</a>
            <a mat-list-item *ngIf="flags.navigation.entities.empihub" [routerLink]="'/entities/empi-hubs'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">EMPI Hubs</a>
            <a mat-list-item *ngIf="flags.navigation.entities.bargroupFacilities.create"
              [routerLink]="'/entities/bargroup-facilities'" routerLinkActive="active-link">Bar Group Facilities</a>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showAzureSearch()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>cloud</mat-icon>Azure Search
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item [routerLink]="'/providersearch/synonyms'" routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact:true}">
              Provider Search Synonyms
            </a>
            <a mat-list-item *ngIf="flags.navigation.azureSearch.scoreAndFields.create"
              [routerLink]="'/providersearch/scores'" routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact:true}">Scores</a>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showDevelopersOptions()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>developer_mode</mat-icon>Developer Options
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.developersOptions.cernerSSODashboard.view"
              [routerLink]="'/cernerSSODashboard'" routerLinkActive="active-link">
              Cerner Dashboard
            </a>
          </mat-expansion-panel>

          <mat-expansion-panel [class.mat-elevation-z0]="true" *ngIf="showSiteSettings()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon>settings</mat-icon>Site Settings
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item *ngIf="flags.navigation.siteSettings.domainWhitelisting.create"
              [routerLink]="'/settings/domain-whitelisting'" routerLinkActive="active-link"
              [routerLinkActiveOptions]="{exact:true}">Whitelisted Email Domains</a>
            <a mat-list-item *ngIf="flags.navigation.siteSettings.alerts.create" [routerLink]="'/alerts'"
              routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Alerts</a>
            <a mat-list-item *ngIf="flags.navigation.instructions.create" [routerLink]="'/instructions'"
              routerLinkActive="active-link">Login Announcement</a>
          </mat-expansion-panel>
        </mat-nav-list>
        <button class="clear-search-button active" mat-button *ngIf="showResetButton" (click)="ResetUserInfo()">
          Clear Search Info
        </button>
        <button class="clear-search-button disabled" mat-button *ngIf="!showResetButton" [disabled]="disabled">
          Clear Search Info
        </button>
      </as-split-area>

      <as-split-area [size]="contentWidth" flexOrder="1">
        <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" class="loader-margin"></mat-spinner>
        <div fxLayout="column" fxFill>
          <div class="titleDiv"><span>{{pageTitle}}</span></div>
          <div id="mainContent" [hidden]="showLoader" fxFlex="grow">
            <router-outlet></router-outlet>
          </div>
        </div>
      </as-split-area>
    </as-split>
  </div>
</div>