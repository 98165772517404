import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatLegacyPaginator, LegacyPageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource, MatLegacyTable } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { Router } from '@angular/router';
import { CiamUser } from '@dignity-health/ciam-auth';
import { IAppState } from 'app/types';
import { UpdateUserSearch } from 'app/store/actions';
import { UsersDataSource } from 'app/types/users-datasource';
import { PageInfo, SortInfo } from '../../../types/models';
import { UiService } from '../../../services/ui/ui.service';
import { GraphQLQuery, PagedResult } from '../../../types/graphql';
import { UserSearchRequest } from '../../../types/userSearchRequest';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators'
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { SharedSearchService, PatientDetails } from 'app/services/shared-search/sharedSearch.service';

const GET_USERS_QUERY = `
query getUsers( $basicUserSearchText: String, 
                $isAdvancedUserSearch: Boolean, 
                $username: String,
                $firstName: String,
                $lastName: String,                 
                $dateOfBirth: String, 
                $gender: String,
                $idLevel: String,
                $createdDate: String,
                $inQuickerConfirmationNumber: String,  
                $sortColumn: String,
                $sortOrder: String,                  
                $pageSize: Int,                
                $currentPage: Int) {
                searchUsers(
                    searchParameters: { 
                      basicUserSearchText: $basicUserSearchText, 
                      isAdvancedUserSearch: $isAdvancedUserSearch,
                      username: $username,
                      firstName: $firstName,
                      lastName: $lastName,
                      gender: $gender
                      idLevel: $idLevel
                      dateOfBirth: $dateOfBirth,
                      createdDate: $createdDate,
                      inQuickerConfirmationNumber: $inQuickerConfirmationNumber
                    }, 
                    pageInfo: { 
                      pageSize: $pageSize,
                      currentPage: $currentPage 
                    },
                   sortInfo: {     
                    sortColumn: $sortColumn,
                    sortOrder: $sortOrder
                     }) 
    {
      pageSize
      currentPage
      firstRowOnPage
      lastRowOnPage
      pageCount
      rowCount
      results {
        username,
        firstName,
        lastName,
        dateOfBirth,
        gender,
        idLevel,
        createdDate
      }
    }
}
`;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  user: CiamUser;
  user$: Observable<CiamUser>;
  @ViewChild(MatLegacyTable) table: MatLegacyTable<CiamUser>;
  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  rowCount = 0;
  hasResults = false;
  noResultsMessage: string;
  loadingIndicator: boolean;
  searchUsersQuery: GraphQLQuery;
  userSearchRequest: UserSearchRequest;
  dataSource: MatLegacyTableDataSource<CiamUser>;
  advancedSearch$ = new BehaviorSubject<UserSearchRequest>({
    'basicUserSearchText': '',
    'isAdvancedUserSearch': false,
    'username': '',
    'firstName': '',
    'lastName': '',
    'dob': null,
    'gender': null,
    'userLevel': null,
    'createdDate': null,
    'inQuickerConfirmationNumber': ''
  });

  pageInfo$ = new BehaviorSubject<PageInfo>({
    'pageSize': 10,
    'currentPage': 1
  });

  sortInfo: SortInfo = { sortColumn: "", sortOrder: "" };
  displayedColumns = ['userName', 'firstName', 'lastName', 'dateOfBirth', 'gender', 'idLevel', 'createdDate'];
  isSuperAdmin = false;
  timezoneAbbrevation: string;

  constructor(
    private router: Router,
    private uiService: UiService,
    private cd: ChangeDetectorRef,
    private store: Store<IAppState>,
    public usersDataSource: UsersDataSource,
    private oktaGroupService: OktaSupportGroupsService,
    private sharedSearchService: SharedSearchService
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => { this.user = user; });

    this.store.select(m => m.userSearch).subscribe(s => {
      if (s != undefined && (s.myHomeSearch != null)) {
        this.userSearchRequest = s.myHomeSearch;
      }
    });
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.getUsers();
    this.isSuperAdmin = this.oktaGroupService.isSuperAdmin;
    this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');
  }

  onPaginateChange(LegacyPageEvent: LegacyPageEvent) {
    this.pageInfo$.next({
      'pageSize': LegacyPageEvent.pageSize,
      'currentPage': LegacyPageEvent.pageIndex + 1
    });
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    var sortOnColumns = '';
    switch (sort.active) {
      case "Username":
        sortOnColumns = "Username, LastName, FirstName, DateOfBirth, Gender";
        break;
      case "FirstName":
        sortOnColumns = "FirstName, LastName, DateOfBirth, Gender";
        break;
      case "LastName":
        sortOnColumns = "LastName, FirstName, DateOfBirth, Gender";
        break;
      case "DateOfBirth":
        sortOnColumns = "DateOfBirth, LastName, FirstName, Gender";
        break;
      case "Gender":
        sortOnColumns = "Gender, LastName, FirstName, DateOfBirth";
        break;
      case "IdLevel":
        sortOnColumns = "IdLevel, LastName, FirstName, DateOfBirth, Gender";
        break;
      case "CreatedDate":
        sortOnColumns = "CreatedDate, LastName, FirstName, DateOfBirth, Gender";
        break;
    }

    this.sortInfo = { sortColumn: sortOnColumns, sortOrder: sort.direction };
    this.getUsers();
  }

  getUsers(): void {
    const combined = combineLatest(this.advancedSearch$, this.pageInfo$);
    combined.pipe(distinctUntilChanged())
      .subscribe((searchRequest: [UserSearchRequest, PageInfo]) => {
        this.noResultsMessage = '';
        this.loadingIndicator = true;
        const searchText = searchRequest[0].basicUserSearchText;

        if ((searchText === '' || searchText === undefined) && !searchRequest[0].isAdvancedUserSearch) {
          this.hasResults = false;
          this.loadingIndicator = false;
          return;
        }

        this.store.dispatch(new UpdateUserSearch(searchRequest[0]));

        // Rebuild Query, so that there is no stale data
        this.searchUsersQuery = {
          operationName: null,
          query: GET_USERS_QUERY,
          variables: {
            'basicUserSearchText': searchRequest[0].basicUserSearchText,
            'isAdvancedUserSearch': searchRequest[0].isAdvancedUserSearch,
            'username': searchRequest[0].username,
            'firstName': searchRequest[0].firstName,
            'lastName': searchRequest[0].lastName,
            'dateOfBirth': this.uiService.formatDate(searchRequest[0].dob),
            'gender': searchRequest[0].gender,
            'idLevel': searchRequest[0].userLevel,
            'createdDate': this.uiService.formatDate(searchRequest[0].createdDate),
            'inQuickerConfirmationNumber': searchRequest[0].inQuickerConfirmationNumber,
            'sortColumn': this.sortInfo.sortColumn,
            'sortOrder': this.sortInfo.sortOrder,
            'pageSize': searchRequest[1].pageSize,
            'currentPage': searchRequest[1].currentPage
          }
        };

        this.usersDataSource.searchUsers(this.searchUsersQuery)
          .then(response => {
            // Handle the errors elegantly.
            if (response && response.errors && response.errors.length) {
              this.hasResults = false;
              this.loadingIndicator = false;
              return this.uiService.showErrors(response.errors, true, environment.ciamUrl + "graphql/query");
            }

            if (response && response.data !== null && response.data !== undefined) {
              const pagedResult = <PagedResult<CiamUser>>(response.data.searchUsers);
              if (pagedResult && pagedResult.rowCount > 0) {
                this.dataSource = new MatLegacyTableDataSource(<CiamUser[]>(pagedResult.results));
                this.rowCount = pagedResult.rowCount;
                this.hasResults = true;

                // If no record is available on the page searched, take the user back to page 1.
                if ((pagedResult.currentPage !== 1)
                  && (pagedResult.rowCount < ((pagedResult.currentPage - 1) * pagedResult.pageSize))) {
                  this.paginator.pageIndex = 0;
                  this.pageInfo$.next({
                    currentPage: 1,
                    pageSize: 10
                  });
                }
              }
              else {
                this.hasResults = false;
                this.noResultsMessage = `No results found.`;
                this.rowCount = 0;
              }
              this.loadingIndicator = false;
            }
          })
          .catch(errors => {
            this.loadingIndicator = false;
            this.hasResults = false;

            return this.uiService.showErrors([{ message: errors.message }], true, environment.ciamUrl + "graphql/query");
          });
      });
  }

  goToViewUserProfile(username: string) {
    var existingPatientSearchDataOnPortal = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (existingPatientSearchDataOnPortal.length > 0) {
      existingPatientSearchDataOnPortal[0].userName = username;
    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.userName = username;
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
    this.router.navigate(['/users/view', { user: username }]);
  }

  advancedSearch(advanceSearchParms: UserSearchRequest) {
    this.advancedSearch$.next(advanceSearchParms);
  }
}
