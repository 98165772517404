<div class="page-content">
    <div class="facilities-search-term-container">
        <button mat-raised-button color="primary" class="create-facilties" [hidden]="isTier1SupportGroup" (click)="AddUpdateFacilties(null)">
            <mat-icon class="mat-icon-margin-left">add</mat-icon>Create Facility 
          </button>
        <mat-form-field class="search-term-input-container">
            <input matInput #search="ngModel" (keyup.enter)="onRequestSearch()" [(ngModel)]="searchTerm" placeholder="Search Facilities">
        </mat-form-field>
        <div class="actions">
            <div class="action-container">
                <button class="search-button" mat-raised-button color="primary" (click)="onRequestSearch()">
          <mat-icon>search</mat-icon>Search
        </button>
            </div>
            <div class="action-container" *ngIf="!isTier1SupportGroup">
                <button class="more-actions" [matMenuTriggerFor]="moreMenu" mat-raised-button color="accent">
          <mat-icon>more_horiz</mat-icon>More
        </button>

                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item (click)="onClickExportData()">Export Data</button>
                </mat-menu>
            </div>

            <div class="action-container" [hidden]="!searchPerformed">
                <button class="search-button" mat-raised-button [color]="'accent'" (click)="clearSearch()">
          <mat-icon>clear_all</mat-icon> Clear
        </button>
            </div>
        </div>
    </div>

    <progress-bar [isLoading]="isLoading"></progress-bar>
    <mat-table #table [dataSource]="dataSource" matSort matSortDisableClear="true" (matSortChange)="onSortData($event)">
      <!-- id Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let facility"> {{facility?.facilityId}} </mat-cell>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header="FriendlyFacilityName"> Name </mat-header-cell>
        <mat-cell *matCellDef="let facility"> {{facility?.friendlyFacilityName}} </mat-cell>
      </ng-container>

      <!-- schedulingLocationId Column -->
      <ng-container matColumnDef="schedulingLocationId">
        <mat-header-cell *matHeaderCellDef mat-sort-header="SchedulingLocationId"> Scheduling Location ID </mat-header-cell>
        <mat-cell *matCellDef="let facility"> {{facility?.schedulingLocationId}} </mat-cell>
      </ng-container>

      <!-- edwFacilityName Column -->
      <ng-container matColumnDef="edwFacilityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="EdwFacilityName"> EDW Facility Name </mat-header-cell>
        <mat-cell *matCellDef="let facility"> {{facility?.edwFacilityName}} </mat-cell>
      </ng-container>

      <!-- address Column -->
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
        <mat-cell *matCellDef="let facility"> {{facility | facilityAddress}} </mat-cell>
      </ng-container>

      <!-- domain Column -->
      <ng-container matColumnDef="domainName">
        <mat-header-cell *matHeaderCellDef mat-sort-header="DomainName"> Domain Name </mat-header-cell>
        <mat-cell *matCellDef="let facility"> {{facility?.domainName}} </mat-cell>
      </ng-container>

      <!-- actions Column -->
      <ng-container class="facility-actions-container" matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef [hidden]="isTier1SupportGroup">Actions</mat-header-cell>
        <mat-cell *matCellDef="let facility" [hidden]="isTier1SupportGroup">
          <mat-icon class="clickable" (click)="AddUpdateFacilties(facility)" matTooltip="Quick Edit Facility">border_color</mat-icon>
          <mat-icon matTooltip="Delete Facility - not implemented yet." [hidden]="checkTier2SupportGroup()" style="opacity:.5">delete</mat-icon>
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div *ngIf="showNoResultMessage" class="left-margin">
        No facilities found. Please try searching again.
    </div>
    <mat-paginator #paginator [pageSize]="pageInfo.pageSize" [length]="pageInfo.rowCount" [pageSizeOptions]="[20, 50, 100]" (page)="onPaginateChange($event)">
    </mat-paginator>
</div>
