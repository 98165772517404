import { Component, OnInit } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

import { IAppState } from '../../../types/state';
import { GraphQLQuery } from '../../../types/graphql';
import { UpdateParentUser } from 'app/store/actions/parentUser';
import { UserProfileImage } from 'app/types/user-models/user-profile-image.model';

import { CiamAuth, CiamUser } from '@dignity-health/ciam-auth';
import { UpdateUsernameComponent } from 'app/components/user/update-username/update-username.component';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UiService } from '../../../services/ui/ui.service';
import { Observable } from 'rxjs';
import { SEARCH_TYPE } from 'app/shared/enums/enums';
import * as moment from 'moment';

const GET_USER_PROFILE_BY_USERID_QUERY = `
      query getUsersByName($username: String){
        users(username: $username) {
          userId
          selfId
          firstName
          lastName
          preferredName
          email
          gender
          dateOfBirth
          email
          phoneNumber
          isTermsAccepted
          isVerified
          emailVerificationCode
          lastPasswordChangedDateTime
          createdDate
          address {
            line1
            line2
            city
            zipCode
            stateOrProvince
          }
          profileImages {
            id
            url
            height
            width
          }
          oktaGroups {
            oktaGroupId
            oktaGroupName
          }          
        }}`;

const GET_PERSON_PROFILE_BY_PERSONID_QUERY = `
      query getPersonsByName($personId : String){
        person(personId: $personId) {
          firstName
          lastName
          dateOfBirth
          email
          phoneNumber
          gender
          address {
            line1
            line2
            city
            zipCode
            stateOrProvince
          }
        }}`;

const GET_PATIENT_PROFILE_BY_PATIENTID_QUERY = `
      query getPatientById($patientId : String){
        patient(patientId: $patientId) {
          firstName
          lastName
          dateOfBirth  
          gender
          address {
            line1
            line2
            city
            zipCode
            stateOrProvince
          }
        }}`;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})

export class UserDetailsComponent implements OnInit {

  user: CiamUser;
  parentUser: CiamUser;
  selectedUser: CiamUser;

  user$: Observable<CiamUser>;
  parentUser$: Observable<CiamUser>;
  selectedUser$: Observable<CiamUser>;

  getUserProfileQuery: GraphQLQuery;
  getPersonProfileQuery: GraphQLQuery;
  getPatientProfileQuery: GraphQLQuery;
  showOktaGroupsLoading = true;

  username = '';
  personId = '';
  patientId = '';
  isMyHomeUser: boolean = true;
  isAdmin: boolean = false;
  tabIndex: number = 0;
  timezoneAbbrevation: string;

  userProfileImage: UserProfileImage = {
    url: '',
    width: '',
    height: ''
  };
  showLoadder: boolean = false;
  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private router: Router,
    private ciamAuth: CiamAuth,
    private dialog: MatLegacyDialog,
    private store: Store<IAppState>,
    private oktaGroupService: OktaSupportGroupsService) {

  }

  ngOnInit() {
    this.selectedUser$ = this.store.select(s => s.user);
    this.selectedUser$.subscribe(user => {
      if (!user) {
        return this.getUserFromUrl();
      }
    });

    this.isAdmin = this.oktaGroupService.isSuperAdmin;
    this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');
    this.user$ = this.store.select(s => s.user);
    this.user$.subscribe(user => {
      this.user = user;
      this.getUserFromUrl();
    });
  }

  getUserFromUrl() {
    this.route.params.subscribe(params => {
      this.username = params['user'];
      this.personId = params['personId'];
      this.patientId = params['patientId'];
    });

    if (!this.username && this.personId == 'undefined' && this.patientId == 'undefined') {
      this.uiService.openSnackbar('Person Id or Patient Id of the selected non-my-home-user not found.', 'Error');
      return;
    }

    this.getUserProfile();
  }

  async getUserProfile(): Promise<void> {
    if (!this.username) {
      this.isMyHomeUser = false;
      if (this.personId !== undefined && this.personId != 'undefined') {
        this.getPersonProfile();
      }
      else {
        this.getPatientProfile();
      }
      return;
    }

    this.getUserProfileQuery = {
      query: GET_USER_PROFILE_BY_USERID_QUERY,
      variables: {
        'username': this.username
      }
    };
    this.showLoadder = true;
    const result = await this.ciamAuth.httpApi.apiUsersQueryPost(this.getUserProfileQuery, true);
    const hasRecord = result && result.data && result.data.users;
    this.showLoadder = false;
    if (!hasRecord) {
      this.isMyHomeUser = false;
      if (this.personId !== undefined && this.personId != 'undefined') {
        this.getPersonProfile();
      }
      else {
        this.getPatientProfile();
      }
      return;
    }

    this.selectedUser = <CiamUser>result.data.users;
    this.showOktaGroupsLoading = false;
    if (result.data.users.profileImages.length > 0) {
      this.getProfileImage(result.data.users.profileImages);
    }

    this.getParentUser();
  }

  async getPersonProfile(): Promise<void> {
    this.getPersonProfileQuery = {
      query: GET_PERSON_PROFILE_BY_PERSONID_QUERY,
      variables: {
        'personId': this.personId
      }
    };

    const result = await this.ciamAuth.httpApi.apiUsersQueryPost(this.getPersonProfileQuery, true);
    if (result && result.data && result.data.person) {
      this.selectedUser = <CiamUser>result.data.person;

      this.getParentUser();
    }
  }

  async getPatientProfile(): Promise<void> {
    this.getPatientProfileQuery = {
      query: GET_PATIENT_PROFILE_BY_PATIENTID_QUERY,
      variables: {
        'patientId': this.patientId
      }
    };

    const result = await this.ciamAuth.httpApiPatientPortal.apiPortalQueryPost(this.getPatientProfileQuery);
    if (result && result.data && result.data.patient) {
      this.selectedUser = <CiamUser>result.data.patient;

      this.getParentUser();
    }
  }

  private getParentUser() {
    if (this.personId || this.patientId) {
      if (localStorage.getItem("parentUser") != null) {
        this.parentUser = JSON.parse(localStorage.getItem('parentUser'));
      }
    }
    else {
      this.parentUser$ = this.store.select(s => s.parentUser);
      this.updateParentUser(this.selectedUser);
      this.parentUser$.subscribe(user => {
        this.parentUser = user;
        localStorage.setItem('parentUser', JSON.stringify(this.parentUser));
      });
    }
  }

  private updateParentUser(parentUser: CiamUser) {
    this.store.dispatch(new UpdateParentUser(parentUser));
    //this.parentUser$.dispatch(new UpdateParentUser(parentUser));
  }

  BackToUsers() {
    if (localStorage.getItem("MyHelp_UserSearchType") != null) {
      var searchType = JSON.parse(localStorage.getItem('MyHelp_UserSearchType'));
      if (searchType == SEARCH_TYPE.CERNER_MILLENIUM) {
        this.router.navigate(['users-cerner-millennium']);
      }
      else if (searchType == SEARCH_TYPE.PATIENT_PORTALS) {
        this.router.navigate(['users-patient-portals']);
      }
      else {
        this.router.navigate(['users-dignity-health']);
      }
    }
  }

  BackToParent() {
    this.router.navigate(['/users/view', { user: this.parentUser.email }]);
  }

  OpenUpdateUsernameModal() {
    this.dialog.open(UpdateUsernameComponent, {
      height: '250px',
      width: '400px',
      data: this.username
    });
  }

  getProfileImage(profileImages: any) {
    if (profileImages.length > 0) {
      // get image width of 200
      const userImage = profileImages.filter(image => image.width === 200);
      if (userImage.length > 0) {
        this.userProfileImage.url = userImage[0].url;
        this.userProfileImage.height = userImage[0].height;
        this.userProfileImage.width = userImage[0].width;
      }
    }
  }

  goToSupportLogViewer() {
    this.router.navigate(['/support-log-viewer/view', this.selectedUser.email]);
  }

  getCurrentTabIndex(event: MatTabChangeEvent): void {
    this.tabIndex = event.index;
  }

  getUpdatedProfileData() {
    this.getUserProfile();
  }
}
