import { Pipe, PipeTransform } from '@angular/core';
import { Facility } from '@dignity-health/ciam-auth';

@Pipe({
  name: 'facilityAddress'
})
export class FacilityAddressPipe implements PipeTransform {

  transform(facility: Facility, args?: any): any {
    return `${facility.addressLine1} ${facility.city}, ${facility.stateOrProvince} ${facility.zipCode}`;
  }
}