import { PagedResult, GraphQLPagedResponse, GraphQLResponse } from "../graphql";
import { Facility, ServiceResponse, ServiceResponseError } from "@dignity-health/ciam-auth";
import { PageInfo } from "../models";

export function graphQLPagedResponseToServiceResponse<T, U>(response: GraphQLPagedResponse<T>, getResultsFn: (res: T) => PagedResult<U[]>): ServiceResponse<PagedResult<U[]>> {
    return graphQLResponseToServiceResponse(response, res => {
        return getResultsFn(res as any);
    });
}

export function graphQLResponseToServiceResponse<T, U>(response: GraphQLResponse<T>, getResultsFn: (res: T) => U): ServiceResponse<U> {
    const errors: ServiceResponseError[] = (response.errors || []).map(e => { return { message: e.message }; });
    const isValid = !errors || !errors.length;

    return {
        isValid: isValid,
        result: isValid ? getResultsFn(<any>response.data) : null,
        errors: errors
    };
}

export function patchPageInfo(target: PageInfo, source: PageInfo, goToNextPage: boolean = false) {
    target.currentPage = source.currentPage ? source.currentPage : target.currentPage;
    target.pageCount = source.pageCount ? source.pageCount : target.pageCount;
    target.pageSize = source.pageSize ? source.pageSize : target.pageSize;
    target.rowCount = source.rowCount ? source.rowCount : target.rowCount;

    if (goToNextPage) {
        target.currentPage += 1;
    }
}