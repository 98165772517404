import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ValidationErrors } from '../../../../types/forms';
import { MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';;
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { IdentityApiUserModel, UserProfile, ResponseWrap, ErrorDetails, UnlockUserRequest } from '../../../../types/user-models/identity-api-user.model';
import { IdentityService } from 'app/services/identity-logs/identity-service';
import { UpdateIdentityUserProfileRequest, EmailVerificationIdentifierRequest, MarkEmailAsVerifiedRequest, UnlockUserResponse } from "../../../../types/user-models/identity-api-user.model";
import { UiService } from '../../../../services/ui/ui.service';
import { ServiceResponseError } from '@dignity-health/ciam-auth';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { IActionConfirmation } from '../../../../types/action-confirmation-model/action-confirmation-model';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ConfirmationModalComponent } from 'app/shared/action-confirmation/confirmation-modal/confirmation-modal.component';
import { environment } from 'environments/environment';


@Component({
  selector: 'create-update-identity-api-user',
  templateUrl: './create-update-identity-api-user.component.html',
  styleUrls: ['./create-update-identity-api-user.component.scss']
})
export class CreateUpdateIdentityApiUsersComponent implements OnInit {

  isLoading = false;
  form: FormGroup;
  FirstName: string;
  LastName: string;
  EnrollmentCompleteText: string;
  MobileNumber: string;
  ExistingMobileNumber: string;
  ExistingFirstName: string;
  ExistingLastName: string;
  MobileNumberValidationMessage: string;
  Email: string;
  disableModifiedDate: boolean;
  disableCreatedDate: boolean;
  disableEmailCodeExpiryDate: boolean;
  disableMobileCodeExpiryDate: boolean;
  validationErrors: ValidationErrors = {};
  request: IdentityApiUserModel;
  message: string;
  isMfaEnabled: boolean;
  isDisabled: boolean = false;
  isEmailVerified: boolean = false;
  isAccountLocked: boolean;
  dataSource: MatLegacyTableDataSource<UserProfile>;

  constructor(
    private uiService: UiService,
    private fb: FormBuilder,
    private dialogRef: MatLegacyDialogRef<CreateUpdateIdentityApiUsersComponent, CreateUpdateIdentityApiUsersComponentData>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: CreateUpdateIdentityApiUsersComponentData, private identityService: IdentityService, private dialog: MatLegacyDialog) {
    this.FirstName = '',
      this.LastName = '',
      this.MobileNumber = ''
    var userdetail = data.userProfile;
    this.Email = data.userProfile.email;
    this.isMfaEnabled = data.userProfile.isMFAEnabled;
    this.FirstName = data.userProfile.firstName;
    this.LastName = data.userProfile.lastName;
    this.MobileNumber = data.userProfile.mobileNumber;
    this.ExistingMobileNumber = data.userProfile.mobileNumber;
    this.ExistingFirstName = data.userProfile.firstName;
    this.ExistingLastName = data.userProfile.lastName;
    this.isAccountLocked = data.userProfile.isAccountLocked;
    this.request = {
      isEmailVerified: userdetail.isEmailVerified,
      isMobileVerified: userdetail.isMobileVerified,
      createdDate: userdetail.createdDate,
      lastModifiedBy: userdetail.lastModifiedBy,
      emailVerificationCodeExpiration: userdetail.emailIdentifierExpiryDateTime,
      mobileVerificationCodeExpiration: userdetail.mobileIdentifierExpiryDateTime,
      isEnrollmentComplete: userdetail.isEnrollmentComplete,
      modifiedDate: userdetail.modifiedDate,
      userStatus: userdetail.userStatus,
      dHomeId: userdetail.dHomeId,
      accountLockDurationInMinute: userdetail.accountLockDurationInMinute,
      failedVerificationAttemptCount: userdetail.failedVerificationAttemptCount
    }

    this.disableEmailCodeExpiryDate = this.isMinimumDateTimeValue(new Date(this.request.emailVerificationCodeExpiration));
    this.disableMobileCodeExpiryDate = this.isMinimumDateTimeValue(new Date(this.request.mobileVerificationCodeExpiration));
    this.disableCreatedDate = this.isMinimumDateTimeValue(new Date(this.request.createdDate));
    this.disableModifiedDate = this.isMinimumDateTimeValue(new Date(this.request.modifiedDate));
  }

  ngOnInit(): void {
    this.isDisabled = true;
    this.EnrollmentCompleteText = this.request.isEnrollmentComplete ? "Yes" : "No";
  }


  confirmAndUpdateProfile() {
    this.isDisabled = true;
    if (this.isMfaEnabled) {
      if (this.MobileNumber == '' && this.ExistingMobileNumber != '') {
        const dialogRemoveMobile = this.dialog.open(ConfirmationModalComponent, {
          data: {
            action: this.removeMobileNumberConfirmation
          },
          height: '205px',
          width: '700px'
        });
        dialogRemoveMobile.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.isMfaEnabled = false;
            this.isLoading = true;
            this.updateIdentityUserDetail(this.FirstName, this.LastName, this.MobileNumber, this.Email, this.isMfaEnabled);
          } else { return; }
        });
      }
      else if (this.MobileNumber != this.ExistingMobileNumber) {
        const dialogUpdateMobile = this.dialog.open(ConfirmationModalComponent, {
          data: {
            action: this.updateMobileConfirmation
          },
          height: '205px',
          width: '700px'
        });
        dialogUpdateMobile.afterClosed().subscribe(confirm => {
          if (confirm) {
            const dialogCustomerConfirmation = this.dialog.open(ConfirmationModalComponent, {
              data: {
                action: this.customerActionConfirmation
              },
              height: '280px',
              width: '500px'
            });
            dialogCustomerConfirmation.afterClosed().subscribe(confirm => {
              if (confirm) {
                this.isMfaEnabled = false;
                this.isLoading = true;
                this.updateIdentityUserDetail(this.FirstName, this.LastName, this.MobileNumber, this.Email, this.isMfaEnabled);
              } else { return; }
            });
          }
          else { return; }
        });
      }
      else {
        this.isLoading = true;
        this.updateIdentityUserDetail(this.FirstName, this.LastName, this.MobileNumber, this.Email, this.isMfaEnabled);
      }
    }
    else {
      this.isLoading = true;
      this.updateIdentityUserDetail(this.FirstName, this.LastName, this.MobileNumber, this.Email, this.isMfaEnabled);
    }
  }

  async updateIdentityUserDetail(FirstName: string, LastName: string, MobileNumber: string, Email: string, IsMfaEnabled: boolean) {
    var request = new UpdateIdentityUserProfileRequest();
    request.firstName = FirstName
    request.lastName = LastName
    request.mobileNumber = MobileNumber
    request.email = Email
    request.isMFAEnabled = IsMfaEnabled

    let result: any = null;
    this.identityService.updateUserProfile(request).then(response => {
      if (response.status == 200) {
        response.json().then(jsn => {
          var updateResponse = JSON.parse(JSON.stringify(<ErrorDetails>jsn));
          if (!updateResponse.isSuccessful) {
            var serviceResError: ServiceResponseError = new ErrorDetails();
            var ErrorArray: ServiceResponseError[] = [];
            serviceResError.code = response.status.toString();
            if (updateResponse.errorMessage != '') {
              serviceResError.message = updateResponse.errorMessage;
            }
            ErrorArray.push(serviceResError);
            this.uiService.showErrors(ErrorArray, true, environment.identityApiUrl + 'v1/UserProfile/UpdateUserProfile');
            this.isDisabled = false;
          }
          else {
            var request = new MarkEmailAsVerifiedRequest();
            request.email = this.Email;
            this.identityService.getUserProfile(request).then(response => {
              response.json()
                .then(json => {
                  result = JSON.parse(JSON.stringify(<UserProfile>json));
                  this.dialogRef.close({
                    userProfile: result,
                    didUpdate: true
                  });
                  this.uiService.openSnackbar('User details updated successfully', 'Success');
                });
            });
          }
        });
      }
      else {
        this.isDisabled = false;
        this.showErrorMessage(response, environment.identityApiUrl + "v1/UserProfile/UpdateUserProfile");
      }
      this.isLoading = false;
    })
  }

  sendEmailVerificationCode() {
    this.isLoading = true;
    var request = new EmailVerificationIdentifierRequest();
    request.email = this.Email;
    request.firstName = this.FirstName;
    request.mobileNumber = this.MobileNumber;
    request.brand = "DH";

    this.identityService.SendEmailVerificationIdentifier(request).then(response => {
      if (response.ok && response.status == 200) {
        var request = new MarkEmailAsVerifiedRequest();
        request.email = this.Email;
        this.identityService.getUserProfile(request).then(response => {
          response.json()
            .then(json => {
              var result = JSON.parse(JSON.stringify(<UserProfile>json));
              this.request.emailVerificationCodeExpiration = result.emailIdentifierExpiryDateTime;
              this.uiService.openSnackbar('Email verification code sent successfully', 'Success');
            });
        });
      }
      else {
        this.showErrorMessage(response, environment.identityApiUrl + "v1/VerificationIdentifier/SendEmailVerificationIdentifier");
      }
      this.isLoading = false;
    })
  }

  markEmailAsVerified() {
    this.isLoading = true;
    var request = new MarkEmailAsVerifiedRequest();
    request.email = this.Email;
    this.identityService.MarkEmailAsVerified(request).then(response => {
      if (response.ok && response.status == 200) {
        response.json()
          .then(json => {
            let response = JSON.parse(JSON.stringify(<ResponseWrap>json));
            if (response.isSuccessful) {
              this.request.isEmailVerified = true;
              this.uiService.openSnackbar('Email marked as verified successfully', 'Success');
            }
            else {
              this.request.isEmailVerified = true;
              this.uiService.openSnackbar(response.errorMessage, 'Already Marked');
            }
          })
      }
      else {
        this.showErrorMessage(response, environment.identityApiUrl + "v1/UserProfile/MarkEmailAsVerified");
      }
      this.isLoading = false;
    })
  }

  showErrorMessage(response: any, serviceUrl: any) {
    var serviceResError: ServiceResponseError = new ErrorDetails();
    var ErrorArray: ServiceResponseError[] = [];
    if (response.status != 422) {
      serviceResError.code = response.status;
      serviceResError.message = "Something went wrong! Please contact Administrator";
    }
    else {
      response.json()
        .then(json => {
          let response = JSON.parse(JSON.stringify(<ResponseWrap>json));
          serviceResError.code = response.status;
          for (let key in response.errors) {
            let value = response.errors[key];
            serviceResError.message = value;
          }
        });
    }
    ErrorArray.push(serviceResError);
    this.uiService.showErrors(ErrorArray, true, serviceUrl);
  }

  UnlockAccount() {
    var unlockuserRequest = new UnlockUserRequest();
    unlockuserRequest.email = this.Email;
    this.identityService.UnlockUser(unlockuserRequest).then(response => {
      response.json()
        .then(json => {
          var result = JSON.parse(JSON.stringify(<UnlockUserResponse>json));
          if (result.isSuccessful) {
            this.isAccountLocked = false;
            this.uiService.openSnackbar('User account unlocked successfully', 'Success');
          }
          else {
            this.uiService.openSnackbar(result.errorMessage, 'Already Unlocked');
          }
        });
    });
  }

  async onMFAChange(changedValue: MatLegacySlideToggleChange) {
    if (changedValue.checked) {
      this.isMfaEnabled = true;
    } else {
      this.isMfaEnabled = false;
    }
  }

  onKeyPress(event: any) {
    var phonenumber = event.currentTarget.value.replaceAll('.', '');
    if (phonenumber.length >= 11) {
      event.preventDefault();
    }
    return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57);
  }

  onKeyup(event: any) {
    var phonenumber = event.currentTarget.value.replaceAll('.', '');
    this.ExistingMobileNumber = this.ExistingMobileNumber != null ? this.ExistingMobileNumber.split('.').join('') : "";

    if ((phonenumber.length >= 11) && !phonenumber.startsWith('1')) {
      this.MobileNumberValidationMessage = "You entered an 11 digit number. In this case it must be start with the digit '1'.";
      this.isDisabled = true;
    }
    else if (phonenumber.length > 0 && phonenumber.length < 10) {
      this.MobileNumberValidationMessage = "The mobile number should contain 10 or 11 digits.";
      this.isDisabled = true;
    }
    else if (phonenumber.trim() === this.ExistingMobileNumber.trim() && this.LastName.trim() === this.ExistingLastName.trim() && this.FirstName.trim() === this.ExistingFirstName.trim()) {
      this.MobileNumberValidationMessage = "";
      this.isDisabled = true;
    }
    else {
      this.MobileNumberValidationMessage = "";
      this.isDisabled = false;
    }
  }

  onKeyupName(event: any) {
    if (this.FirstName.trim() == '' || this.LastName.trim() == '' || (this.FirstName.trim() === this.ExistingFirstName.trim() && this.LastName.trim() === this.ExistingLastName.trim() && this.ExistingMobileNumber.trim().split('.').join('') === this.MobileNumber.trim().split('.').join(''))) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  isMinimumDateTimeValue(date: Date): boolean {
    var minimumDate = "Mon Jan 01 0001";
    var minimumDateDec = "Sun Dec 31 0000";
    if (minimumDateDec.toLowerCase() == date.toDateString().toLowerCase() || minimumDate.toLowerCase() == date.toDateString().toLowerCase()) {
      return true;
    }
    return false;
  }

  updateMobileConfirmation: IActionConfirmation = {
    matIcon: 'confirm',
    modalTitle: 'Update user profile Confirmation',
    modalInstruction: 'Updating the mobile number will disable MFA for this customer. Do you want to proceed?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes',
    isAdvanceConfirmation: false
  }

  customerActionConfirmation: IActionConfirmation = {
    matIcon: 'confirm',
    modalTitle: 'Update user profile Confirmation',
    modalInstruction: "Check with the customer if they would like to continue using MFA with their new number. " +
      "If they want to continue, Instruct them to go to the Account settings page, locate the Security section and click the Edit button. They should enable MFA there and confirm their new mobile phone.",
    cancelButtonText: '',
    okButtonText: 'Continue',
    isAdvanceConfirmation: false

  }

  removeMobileNumberConfirmation: IActionConfirmation = {
    matIcon: 'alert',
    modalTitle: 'Update user profile Confirmation',
    modalInstruction: 'Removing the mobile number will disable MFA for this customer. Do you want to proceed?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Ok',
    isAdvanceConfirmation: false
  }
}

export interface CreateUpdateIdentityApiUsersComponentData {
  userProfile?: UserProfile;
  didUpdate?: boolean;
}