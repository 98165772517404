import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'active',
  pure: false
})
export class ActivePipe implements PipeTransform {
  transform(values: { isActive: boolean }[], args?: any): any {
    return values.filter(v => v.isActive);
  }
}
