import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { AzureSearchService } from 'app/services/azure-search/azure-search-service';
import { SynonymMapName } from "@dignity-health/ciam-auth/dist/src/types/provider-search/interfaces";
import { Synonym } from '../../../types/azureSearch';
import { OktaSupportGroupsService } from 'app/services/okta-group/okta-support-group.service';
import { UiService } from 'app/services/ui/ui.service';
import { Router } from '@angular/router';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';

@Component({
  selector: 'synonyms',
  templateUrl: './synonyms.component.html',
  styleUrls: ['./synonyms.component.scss']
})

export class SynonymsComponent implements OnInit {

  isSuperAdmin: boolean = false;
  isLoading: boolean = false;
  isNeedToSave: boolean = false;
  hasResults: boolean = false;
  rowCount: number = 0;
  mapName: SynonymMapName;

  displayedColumns = ["keyword", "synonyms", "action"];
  dataSource: MatLegacyTableDataSource<Synonym>;

  synonymMapNamesColums = ["name", "version"];
  synonymMapNames: MatLegacyTableDataSource<SynonymMapName>;
  synonymMapName: SynonymMapName;

  @ViewChild(MatLegacyTable)
  table: MatLegacyTable<Synonym>;

  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;

  request: Synonym = <Synonym>{};
  synonyms: any;

  constructor(private azureSearchService: AzureSearchService,
    private oktaGroupService: OktaSupportGroupsService,
    private uiService: UiService,
    private router: Router) {
    this.isSuperAdmin = this.oktaGroupService.isSuperAdmin;
  }

  ngOnInit() {

    if (!this.isSuperAdmin) {
      return this.router.navigate(['unauthorized']);
    }

    this.getSynonymMapsMetadata();
  }

  actionConfirmationData: IActionConfirmation = {
    matIcon: 'delete',
    modalTitle: 'Delete Synonym Confirmation',
    modalInstruction: 'Are you sure you want to delete the selected Synonym?',
    cancelButtonText: 'Cancel',
    okButtonText: 'Yes',
    isAdvanceConfirmation: false
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  setPaginator() {
    this.dataSource.paginator = this.paginator;
  }

  getSynonymMapsMetadata(): void {
    this.isLoading = true;

    this.azureSearchService.getSynonymMapsMetadata()
      .then(response => {

        let synonymNames: SynonymMapName[] = [];
        let result = <Array<any>>response.result;

        result.forEach(element => {
          synonymNames.push(element.synonymMapName);
        });

        this.synonymMapNames = new MatLegacyTableDataSource(synonymNames);
        this.isLoading = false;
      }).catch((e) => {
        this.uiService.showException(e);
        this.isLoading = false;
      });
  }

  getSynonyms(mapName: SynonymMapName): void {
    this.mapName = mapName;
    this.isLoading = true;

    this.azureSearchService.getSynonymMap(this.mapName)
      .then(response => {
        var responseJson = response;
        this.synonymMapName = responseJson.result.synonymMapName
        this.synonyms = responseJson.result.synonyms;
        this.dataSource = new MatLegacyTableDataSource(this.synonyms);
        this.rowCount = this.synonyms.length;
        this.hasResults = true;
        this.isLoading = false;
        this.setPaginator();
      }).catch((e) => {
        this.uiService.showException(e);
        this.isLoading = false;
        this.hasResults = false;
      });
  }

  addSynonym(): void {
    if (this.isObjectEmpty(this.request)) {
      this.showMessage("Please enter keyword and synonyms.");
      return;
    }
    this.isNeedToSave = true;
    this.synonyms.push(this.request);
    this.request = <Synonym>{};
    this.dataSource = new MatLegacyTableDataSource(this.synonyms);
    this.setPaginator();
    this.showMessage("Added.  Please save to commit.");
  }

  saveSynonyms(): void {
    this.isLoading = true;

    this.azureSearchService.saveSynonyms(this.synonymMapName, this.synonyms)
      .then(() => {
        this.dataSource = new MatLegacyTableDataSource(this.synonyms);
        this.isLoading = false;
        this.isNeedToSave = false;
        this.showMessage("Saved successfully.");
        this.getSynonyms(this.mapName);
        this.setPaginator();
      }).catch((e) => {
        this.uiService.showException(e);
        this.isLoading = false;
      });
  }

  backToList(): void {

    this.request = <Synonym>{};
    this.dataSource = new MatLegacyTableDataSource<Synonym>();
    this.synonyms = <any>{};
    this.isNeedToSave = false;
    this.hasResults = false;
    this.rowCount = 0;
    this.paginator.pageIndex = 0;
    this.setPaginator();
  }

  removeKeyword(confirmationValue: boolean, data): void {
    if (!confirmationValue) {
      return;
    }
    else {
      if (data.id) {
      this.azureSearchService.deleteSynonym(this.synonymMapName, data.id)
        .then(response => {
          this.isNeedToSave = true;
          this.synonyms = this.synonyms.filter(item => item !== data);
          this.dataSource = new MatLegacyTableDataSource(this.synonyms);
          this.setPaginator();
          this.uiService.openSnackbar('Selected Synonym is successfully deleted', 'Success');
          this.getSynonyms(this.mapName);
        }).catch((e) => {
          this.uiService.showException(e);
          this.isNeedToSave = false;
        });
    }
    else {
      this.isNeedToSave = true;
      this.synonyms = this.synonyms.filter(item => item !== data);
      this.dataSource = new MatLegacyTableDataSource(this.synonyms);
      this.setPaginator();
      this.uiService.openSnackbar('Selected Synonym is successfully deleted', 'Success');
    }
  }
  }

  onKeyup(): void {
    this.isNeedToSave = true;
  }

  showMessage(message): void {
    this.uiService.openSnackbar(message, null, { duration: 2500 });
  }

  isObjectEmpty(Obj): boolean {
    return ((Obj.hasOwnProperty("keyword") && Obj["keyword"] !== "") && (Obj.hasOwnProperty("synonyms") && Obj["synonyms"] !== "")) === false;
  }
}
