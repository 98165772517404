import { ValidationErrors } from '../../../types/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {
  @Input()
  name: string;

  @Input()
  validationErrors: ValidationErrors;

  constructor() { }

  ngOnInit() {
  }

}
