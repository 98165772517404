<section *ngIf="user != null" class="page-content">
    <form (ngSubmit)="saveInstruction()" [formGroup]="form">
        <mat-card>
            <mat-card-content>
                <div>
                  <ul>
                    <li>The announcement text can be either empty or non empty.</li>
                    <li>When the announcement text is empty a picture is displayed on the login page.</li>
                    <li>It is recommended to remove the announcement after displaying it for a while.</li>
                  </ul>
                </div>
                <div>
                    <material-form-input tabindex="1" [validationErrors]="validationErrors" [name]="'description'" fxFlex="">
                      <span>Login Announcement</span>
                        <mat-form-field>
                            <textarea rows="10" matInput formControlName="description"></textarea>
                        </mat-form-field>
                    </material-form-input>
                </div>
                <div class="margin-left">
                    <mat-card-actions class="actions-container">
                        <button mat-button type="submit" mat-raised-button color="primary" tabindex="2">Save</button>
                        <mat-spinner *ngIf="showLoader" fxLayoutAlign="center" mode="indeterminate" class="instruction-spinner-margin" [diameter]="30"></mat-spinner>
                    </mat-card-actions>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</section>
