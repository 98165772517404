import { Component, Input } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { ResetUserPasswordDialogComponent } from './reset-user-password-dialog.component';

@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.less']
})
export class ResetUserPasswordComponent {
  @Input() username: string;

  constructor(public dialog: MatLegacyDialog) { }

  openResetPasswordDialog() {
    this.dialog.open(ResetUserPasswordDialogComponent, {
      width: '40%',     
      data: this.username
    });
  }
}