<ng-template mat-tab-label>
  <mat-icon>group</mat-icon> Associated Accounts
</ng-template>
<mat-card *ngIf="selectedUser">
  <mat-card-title>
    Associated Accounts of:
    <strong>{{selectedUser?.firstName}} {{selectedUser?.lastName}}</strong>
  </mat-card-title>
  <mat-card-content>
    <mat-spinner *ngIf="!isValid" fxLayoutAlign="center" mode="indeterminate"  [diameter]="30"></mat-spinner>
    <div *ngIf="isValid">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <hr />
      </div>
      <div fxLayout="row">
        <div fxFlex fxFlexFill>
          <mat-tab-group class="tab-section-height">
            <div *ngFor="let dependent of filteredUserDependents">
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="outer-div">
                  <div class="float-left">
                    {{dependent.person.firstName}} {{dependent.person.lastName}}
                  </div>                
                  <div class="float-right">
                    <mat-icon class="mat-icon-mg" svgIcon="MyCareIcon"></mat-icon>
                  </div>
                </div>
              </ng-template>
              <app-user-manage-associated-account [personId]="dependent.personId" [userPersonAccessId]="dependent.userPersonAccessId" [personRelationshipType]="dependent.relationshipType" [selectedAssociate]="dependent.person"
                                                  [selectedUser]="selectedUser"></app-user-manage-associated-account>
            </mat-tab>
          </div>
            <mat-tab *ngFor="let authRep of userAuthRepresentatives">
              <ng-template mat-tab-label>
                <div class="outer-div">
                  <div class="float-left">
                    {{authRep.user.self.firstName}} {{authRep.user.self.lastName}}
                  </div>
                  <div class="float-right">
                    <mat-icon class="mat-icon-sg" svgIcon="MyCareGoldIcon"></mat-icon>
                  </div>
                </div>
              </ng-template>
              <app-user-manage-associated-account [userPatientRelationshipId]="authRep.userPatientRelationshipId" [personId]="authRep.user.selfId"
                                                  [personRelationshipType]="authRep.relationshipType" [selectedAssociate]="authRep.user.self"
                                                  [selectedUser]="selectedUser" [selectedAuthRepresentativeEmail]="authRep.user.email"
                                                  (isSeverAccessSuccessful)="onAuthRepSeverAccessSuccess($event, authRep)"></app-user-manage-associated-account>
            </mat-tab>

            <mat-tab *ngFor="let patientdependent of patientDependents">
              <ng-template mat-tab-label *ngIf="patientdependent.patient">
                <mat-icon>people_outline</mat-icon>
                {{patientdependent.patient.firstName}} {{patientdependent.patient.lastName}}
              </ng-template>
              <app-user-patient-dependents-detail *ngIf="patientdependent.patient" [patientDependent]="patientdependent.patient" [userPatientRelationshipId]="patientdependent.userPatientRelationshipId"
                                                  [relationshipType]="patientdependent.relationshipType"  [userPersonAccess]="patientdependent.personAccess" [username]="patientdependent.user.username" [personId] ="patientdependent.patientPersonId"
                                                  [patientId] = "patientdependent.patientId" [isPatientUserExist]="patientdependent.isPatientUserExist" (isSeverAccessSuccessful)="onPatientDependentSeverAccessSuccess($event, patientdependent)">
              </app-user-patient-dependents-detail>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
