<div class="page-content app-login-margin" fxLayout="column" fxLayoutAlign="center center">
    <div class="root-card">
        <mat-spinner *ngIf="showSpinner"></mat-spinner>
        <mat-card class="mat-elevation-z6" *ngIf="showSpinner == false">
            <mat-card-content>
                <button mat-button color="primary" style="width: 95%;" mat-raised-button type="submit" (click)="login()">Login With Okta</button>
            </mat-card-content>
        </mat-card>
        <div class="margin-top">
            <mat-card class="mat-elevation-z6" *ngIf="showSpinner == false">
                <span class="title-font" *ngIf="description">Instructions</span>
                <mat-card-subtitle class="mat-card-subtitle-padding-top">
                   <span *ngIf="!description && !loading">
                    <img [src]="'/assets/'+loginImage" width="100%" alt="instruction alternative image"/>
                   </span> 
                   <span *ngIf="description" class="instructions-text">
                       {{ description }}
                   </span>
                </mat-card-subtitle>
                <mat-divider class="mat-divider"></mat-divider>
                <mat-card-content class="need-access">                    
                    <span style="font-size:14px; margin-top:8px;">User Manual:</span> 
                    <button mat-button color="primary" (click)="userManualLink()">Click Here</button>                
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
