import { Injectable } from '@angular/core';
import { CiamAuth } from '@dignity-health/ciam-auth';

@Injectable()
export class CernerSSOConfigService {

    constructor(private ciamAuth: CiamAuth) { }

    public GetCernerSSOConfigDetails() {
        return this.ciamAuth.httpApiPatientPortal.apiCernerssoConfigEnvironemtDevelopmentGet()
        .then(response => {
            return response;
        });
    }
}
