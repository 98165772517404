import { NativeDateAdapter } from "@angular/material/core";
/**
 * The date adapter will format the date selected by using Material Datepicker to `MM/dd/yyyy`
 */
export class AppDateAdapter extends NativeDateAdapter {
   format(date: Date, displayFormat: Object): string {
           let day = date.getDate();
           // Month starts from zero.
           let month = date.getMonth() + 1;
           let year = date.getFullYear();
           return  this._to2digit(month) + '/' + this._to2digit(day) + '/' + year;
   }

   private _to2digit(n: number) {
       return ('00' + n).slice(-2);
   } 
}