import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable } from "rxjs";

@Injectable()
export class CheckUserAuthService {
    constructor(private router: Router,
        private oidcSecurityService: OidcSecurityService) { }

    isUserAuthorized(url: string): Observable<boolean> {
        if(this.router.url.startsWith(url)) {
          return this.oidcSecurityService.isAuthenticated();       
        }
    }
}