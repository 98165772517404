<h1 mat-dialog-title>Attention:</h1>
<mat-dialog-content>
  <span><b>{{data.dialogMessage}}</b></span>
  <br />
  <div class="alingEmail">Email: {{data.email}}</div>
  <br />
  <span>Okta Env: {{oktaEnv}}</span>
  <br />
  <span>CIAM Env: {{ciamUrl}}</span>
  <br />
  <br />
  <span>Please contact administrator to update your user information.</span>
  <br />
  <span>In the current configuration application cannot let you in.</span>
</mat-dialog-content>
<br />
<mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>