<section class="page-content" *ngIf="user">
  <div fxLayout="row">
    <button mat-raised-button color="primary" class="back-button-margin" (click)="backToUsers()">Go Back to Users</button>
  </div>
  <div>
    <progress-bar [isLoading]="isLoading"></progress-bar>
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="facilityName">
        <mat-header-cell *matHeaderCellDef fxFlex="35%">Facility Name</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="35%">{{row.encounterLocation}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="encounterType">
        <mat-header-cell *matHeaderCellDef fxFlex="15%">Encounter Type</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="15%">{{row.encounterType}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="registrationDate">
        <mat-header-cell *matHeaderCellDef fxFlex="15%">Registration Date</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="15%">{{row.startDate | date: 'MM/dd/yyyy, h:mm a'}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="dischargeDate">
        <mat-header-cell *matHeaderCellDef fxFlex="15%">Discharge Date</mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="15%">{{row.endDate | date: 'MM/dd/yyyy, h:mm a'}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div *ngIf="rowCount == 0">
    No Records to show!
  </div>
</section>