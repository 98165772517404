import { Injectable } from "@angular/core";
import { CiamAuth } from "@dignity-health/ciam-auth";
import { Store } from "@ngrx/store";
import { IAppState } from "app/types/state";
import { MarkEmailAsVerifiedRequest, AuditLogsRequest, UpdateIdentityUserProfileRequest, EmailVerificationIdentifierRequest,UnlockUserRequest } from "app/types/user-models/identity-api-user.model";

@Injectable()
export class IdentityService {
    constructor(private ciamAuth: CiamAuth, private store: Store<IAppState>) {
    }

    async getAuditLogs(fromDate: Date, toDate: Date, userName: string): Promise<Response> {
        var request = new AuditLogsRequest();
        request.email = userName;
        request.fromDate = fromDate.toISOString();
        request.toDate = toDate.toISOString();
        return await this.ciamAuth.httpIdentityApiService.serviceRequest("POST", "v1/UserProfile/GetAuditLogs", JSON.stringify(request), true);
    }

    async getUserProfile(request: MarkEmailAsVerifiedRequest): Promise<Response> {
        return await this.ciamAuth.httpIdentityApiService.serviceRequest("POST", "v1/UserProfile/GetUserProfile", JSON.stringify(request), true);
    }

    async updateUserProfile(request: UpdateIdentityUserProfileRequest): Promise<Response> {
        return await this.ciamAuth.httpIdentityApiService.serviceRequest("POST", "v1/UserProfile/UpdateUserProfile", JSON.stringify(request), true);
    }

    async SendEmailVerificationIdentifier(request: EmailVerificationIdentifierRequest): Promise<Response> {
        return await this.ciamAuth.httpIdentityApiService.serviceRequest("POST", "v1/VerificationIdentifier/SendEmailVerificationIdentifier", JSON.stringify(request), true);
    }

    async MarkEmailAsVerified(request: MarkEmailAsVerifiedRequest): Promise<Response> {
        return await this.ciamAuth.httpIdentityApiService.serviceRequest("POST", "v1/UserProfile/MarkEmailAsVerified", JSON.stringify(request), true);
    }

    async UnlockUser(request: UnlockUserRequest): Promise<Response> {
        return await this.ciamAuth.httpIdentityApiService.serviceRequest("POST", "v1/UserProfile/UnlockUser", JSON.stringify(request), true);
    }
}