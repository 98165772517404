<div>
  <div class="div-left"></div>
  <div mat-dialog-title class="dialog-title">
    <h5>Consumer Identity User Details </h5>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div class="div-right"></div>
  <div *ngIf="isLoading" class="progressbar"><progress-bar [isLoading]="isLoading"></progress-bar></div>
  <mat-error *ngIf="message">{{message}}</mat-error>

  <div class="lable-value-section-input-box">
    <mat-form-field>
      <div class="input-left-border">
        <input matInput [disabled]="!request.isEnrollmentComplete" type="text" (keyup)="onKeyupName($event)"
          placeholder="First Name" maxlength="35" required [(ngModel)]="FirstName" />
      </div>
    </mat-form-field>
    <mat-error *ngIf="FirstName?.length &lt; 1">{{validationErrors.firstName}}</mat-error>

    <mat-form-field>
      <div class="input-left-border">
        <input matInput [disabled]="!request.isEnrollmentComplete" type="text" placeholder="Last Name" maxlength="35"
          required [(ngModel)]="LastName" (keyup)="onKeyupName($event)" />
      </div>
    </mat-form-field>
    <mat-error *ngIf="LastName?.length &lt; 1">{{validationErrors.lastName}}</mat-error>
  </div>
  <div class="tab-container">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Email and Phone">
        <div class="tab-body-wrapper">
          <div class="lable-value-section">
            <label class="mat-label-title">Email address: </label>
            <label class="mat-label-value" [(ngModel)]="Email">{{Email}}</label>
          </div>

          <div class="email-verified-section">
            <label class="mat-label-title">Email verified: </label> <label
              class="mat-label-value">{{request.isEmailVerified?"Yes":"No"}}</label>
            <div class="action-buttons">
              <button [disabled]="!request.isEnrollmentComplete || request.isEmailVerified"
                [ngClass]="{'verfication-code-link-button-disabled': !request.isEnrollmentComplete || request.isEmailVerified, 'verfication-code-link-button' : request.isEnrollmentComplete || request.isEmailVerified }"
                (click)="sendEmailVerificationCode()">Send Email
                Verification Code</button>
              <button [disabled]="!request.isEnrollmentComplete || request.isEmailVerified"
                [ngClass]="{'verfication-code-link-button-disabled': !request.isEnrollmentComplete || request.isEmailVerified, 'verfication-code-link-button' : request.isEnrollmentComplete || request.isEmailVerified }"
                (click)="markEmailAsVerified()">Mark Email as
                Verified</button>
            </div>
          </div>
          <div class="lable-value-section">
            <label class="mat-label-title">Email verification code expiration: </label>
            <label class="mat-label-value" *ngIf="!disableEmailCodeExpiryDate">{{request.emailVerificationCodeExpiration
              |
              date:'medium' }}</label>
          </div>
          <div class="lable-value-section-input-box-phone-number">
            <mat-form-field>
              <div class="input-left-border">
                <input matInput [disabled]="!request.isEnrollmentComplete" type="text" placeholder="Mobile Number"
                  (keypress)="onKeyPress($event)" (keyup)="onKeyup($event)" [(ngModel)]="MobileNumber" />
              </div>

            </mat-form-field>
            <mat-error *ngIf="MobileNumberValidationMessage">{{MobileNumberValidationMessage}}</mat-error>

          </div>
          <div class="lable-value-section">
            <label class="mat-label-title">Mobile verified: </label>
            <label class="mat-label-value">{{request.isMobileVerified?"Yes":"No"}}</label>

            <label class="mat-label-title">Mobile verification code expiration: </label>
            <label class="mat-label-value"
              *ngIf="!disableMobileCodeExpiryDate">{{request.mobileVerificationCodeExpiration
              |
              date:'medium' }}</label>
            <label class="mat-label-value" *ngIf="disableMobileCodeExpiryDate"></label>

            <label class="mat-label-title">MFA enabled: </label>
            <label class="mat-label-value">{{isMfaEnabled?"Yes":"No"}}</label>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Enrollment Status">
        <div class="tab-body-wrapper">
          <div class="lable-value-section">
            <label class="mat-label-title">Enrollment complete: </label>
            <label class="mat-label-value" *ngIf="request.isEnrollmentComplete">{{EnrollmentCompleteText}}</label>
            <label class="mat-label-value"
              *ngIf="!request.isEnrollmentComplete"><b>{{EnrollmentCompleteText}}</b></label>
            <label class="incompleteEnrollementLabel" *ngIf="!request.isEnrollmentComplete">Users with incomplete
              enrollment
              cannot be edited. </label>

            <label class="mat-label-title">User status in okta: </label>
            <label class="mat-label-value">{{request.userStatus}}</label>

            <label class="mat-label-title">Digital home user id: </label>
            <label class="mat-label-value-lg">{{request.dHomeId}}</label>

            <label class="mat-label-title">Account locked: </label>
            <label class="mat-label-value">{{isAccountLocked?"Yes":"No"}}</label>
            <button mat-raised-button *ngIf="isAccountLocked" class="mat-primary update-profile-button"
              (click)="UnlockAccount()"> Unlock Account </button>

            <label class="mat-label-title-lockDuration" *ngIf="isAccountLocked">The account will be unlocked
              automatically if no
              action is taken in minutes: </label>
            <label class="mat-label-value" *ngIf="isAccountLocked">{{request.accountLockDurationInMinute}}</label>

            <label class="mat-label-title" *ngIf="request.failedVerificationAttemptCount>0">Failed verification
              attempts: </label>
            <label class="mat-label-value"
              *ngIf="request.failedVerificationAttemptCount>0">{{request.failedVerificationAttemptCount}}</label>

            <label class="mat-label-title">Created date: </label>
            <label class="mat-label-value" *ngIf="!disableCreatedDate">{{request.createdDate | date:'medium'}}</label>
            <label class="mat-label-value" *ngIf="disableCreatedDate"></label>

            <label class="mat-label-title">Modified date: </label>
            <label class="mat-label-value" *ngIf="!disableModifiedDate">{{request.modifiedDate | date:'medium'}}</label>

            <label class="mat-label-title" *ngIf="request.lastModifiedBy!=null">Last modified by: </label>
            <label class="mat-label-value">{{request.lastModifiedBy }}</label>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="isDisabled" class="mat-primary update-profile-button"
      (click)="confirmAndUpdateProfile()">Update
      Profile</button>
    <button mat-raised-button class="popup-close" matDialogClose>Cancel</button>
  </div>
</div>