import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';;
import { FormControl, Validators } from '@angular/forms';
import { UpdateRegionRequest, CiamAuth } from '@dignity-health/ciam-auth';
import { UiService } from '../../../services/ui/ui.service';
import { BarGroupService } from '../../../services/bar-group/bar-group-service';

@Component({
  selector: 'regions-details',
  templateUrl: './regions-details.component.html',
  styleUrls: ['./regions-details.component.scss']
})

export class RegionsDetailsComponent {
  region: UpdateRegionRequest;
  regionName = new FormControl();
  showLoader: boolean = false;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: UpdateRegionRequest,
    public dialogRef: MatLegacyDialogRef<RegionsDetailsComponent>,
    public ciamAuth: CiamAuth,
    private barGroupService: BarGroupService,
    private uiService: UiService
  ) {
    this.region = data ? data : null;
    this.regionName = new FormControl(this.data ? this.data.regionName : '', [Validators.required]);
    }

  saveRegion() {
    this.showLoader = true;
    if (this.region) {
      // update selected region 
      this.updateRegion();
    }
    else {
      // create a new region     
      this.createRegion();
    }
  }

  private updateRegion() {
    this.region.regionName = this.regionName.value;
    this.ciamAuth.httpApiFacilities.apiAdminBargroupUpdateRegionPatch(this.region)
      .then(response => {
        if (response.isValid) {
          this.uiService.openSnackbar('Region is Successfully updated', 'Success');
          this.dialogRef.close();
        }
        else if (response.errors.length > 0) {
          this.uiService.openSnackbar(response.errors[0].message, 'Error');
        }
        this.showLoader = false;
        this.barGroupService.getRegions();
      });
  }

  private createRegion() {
    this.ciamAuth.httpApiFacilities.apiAdminBargroupCreateRegionPost({
      regionName: this.regionName.value.trim(),
      isActive: true
    }).then(response => {
      if (response.isValid) {
        this.uiService.openSnackbar('Region is Successfully created', 'Success');
        this.dialogRef.close();
      }
      else if (response.errors.length > 0) {
        this.uiService.openSnackbar(response.errors[0].message, 'Error');
      }
      this.showLoader = false;
      this.barGroupService.getRegions();
    });
  }
}
