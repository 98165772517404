import { Component, Inject } from '@angular/core';
import { MatLegacyDialog, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ResetUserPasswordStatusComponent, ResetUserPasswordStatus } from './reset-user-password-status.component';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { UiService } from 'app/services/ui/ui.service';
import { RESET_PASSWORD_ERROR_MESSAGE, RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_MESSAGE, RESET_PASSWORD_FOR_RECOVERY_USER_ERROR_CODE, RESET_PASSWORD_SUCCESS_MESSAGE } from 'app/types/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { OktaApiService } from 'app/services/okta/okta-api.service';

@Component({
    selector: 'app-reset-user-password-dialog',
    templateUrl: './reset-user-password-dialog.component.html',
    styleUrls: ['./reset-user-password-dialog.component.scss']
})

export class ResetUserPasswordDialogComponent {
    username: string;
    showWithSecuritySpinner: boolean = false;

    constructor(public dialog: MatLegacyDialog, private uiService: UiService, @Inject(MAT_LEGACY_DIALOG_DATA) public data: string, public http: HttpClient, private oktaService: OktaApiService) {
        this.username = data;
    }

    dialogData: ResetUserPasswordStatus = <ResetUserPasswordStatus>{
        message: ''
    };
}
