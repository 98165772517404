import { Component } from '@angular/core';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CreateUpdateIdentityApiUsersComponent } from '../create-update-identity-api-user/identity-api-user/create-update-identity-api-user.component';
import { CreateUpdateIdentityApiUsersComponentData } from '../create-update-identity-api-user/identity-api-user/create-update-identity-api-user.component';
import { IdentityService } from 'app/services/identity-logs/identity-service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { UserProfile, ResponseWrap, ErrorDetails, MarkEmailAsVerifiedRequest } from "app/types/user-models/identity-api-user.model";
import { UiService } from 'app/services/ui/ui.service';
import { ServiceResponseError } from '@dignity-health/ciam-auth';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { PatientDetails, SharedSearchService } from 'app/services/shared-search/sharedSearch.service';

@Component({
  selector: 'identity-api-user',
  templateUrl: './identity-api-user.component.html',
  styleUrls: ['./identity-api-user.component.scss']
})
export class IdentityApiUsersComponent {
  email: string = '';
  form: FormGroup;

  showEmailError: boolean = false;
  isLoading: boolean = false;

  displayedColumns = ['emailAddress', 'firstName', 'lastName', 'createdDate', 'modifiedDate'];
  timezoneAbbrevation: string;
  dataSource: MatLegacyTableDataSource<UserProfile> = null;
  dataGridReplacementMessage: string = '';
  disableModifiedDate: boolean;
  disableCreatedDate: boolean;


  constructor(
    private uiService: UiService, private dialog: MatLegacyDialog,
    private identityService: IdentityService, private fb: FormBuilder,
    private sharedSearchService: SharedSearchService
  ) {
    this.createForm();
    this.sharedSearchService.resetObservable
      .subscribe((resetValue) => {
        if (resetValue) {
          this.showEmailError = false;
          this.createForm();
        }
      });
      this.timezoneAbbrevation = moment.tz(moment.tz.guess()).format('z');
  }

  ngOnInit() {
    var patientSearched = this.sharedSearchService.patientDetailArray;
    this.form = this.fb.group({
      'email': [patientSearched[0]?.userName != "" ? patientSearched[0]?.userName : undefined],
    });
    if (patientSearched[0]?.userName != "" && patientSearched[0]?.userName != undefined && patientSearched[0]?.userName != null) {
      this.performIdentityUserSearch();
    }    
  }

  createForm() {
    this.form = this.fb.group({ 'email': '' });
  }

  valueChange() {
    var patientDetails = this.sharedSearchService.getDetailForPatientSharedSearch();
    if (patientDetails.length > 0) {
      patientDetails[0].userName = this.form.controls['email'].value.trim().toLowerCase();
      if (this.form.controls['email'].value.trim() != '') {
        patientDetails[0].basicUserSearchText = undefined;
      } else {
        this.showEmailError = false;
      }
    }
    else {
      var newPatientSearch = new PatientDetails();
      newPatientSearch.userName = this.form.controls['email'].value.trim().toLowerCase();
      this.sharedSearchService.setPatientDetailForSharedSearch(newPatientSearch);
    }
  }

  performIdentityUserSearch() {
    if (!this.checkEmail()) {
      this.showEmailError = true;
      return;
    }


    this.isLoading = true;
    this.dataSource = null;
    this.dataGridReplacementMessage = '';

    var request = new MarkEmailAsVerifiedRequest();
    request.email = this.email.trim();
    this.identityService.getUserProfile(request).then(response => {
      if (response.status == 204) {
        this.dataGridReplacementMessage = `The email "${this.email.trim()}" is not known to the system.`;
      }
      else if (!response.ok && response.status != 200) {
        this.showErrorMessage(response, environment.identityApiUrl + "v1/UserProfile/GetUserProfile");
      }
      else {
        response.json()
          .then(json => {
            var userProfile: UserProfile[] = [];
            userProfile.push(JSON.parse(JSON.stringify(<UserProfile>json)));
            this.disableCreatedDate = this.isMinimumDateTimeValue(new Date(userProfile[0].createdDate));
            this.disableModifiedDate = this.isMinimumDateTimeValue(new Date(userProfile[0].modifiedDate));
            this.dataSource = new MatLegacyTableDataSource(userProfile);
          });
      }

      this.isLoading = false;
    });
  }

  addUpdateUser(userProfile?: UserProfile) {
    // Open quick edit modal.
    this.dialog
      .open<CreateUpdateIdentityApiUsersComponent, CreateUpdateIdentityApiUsersComponentData>(CreateUpdateIdentityApiUsersComponent, {
        data: {
          userProfile: userProfile
        },
        disableClose: true,
        width: '850px',
        autoFocus: false,
        maxHeight: '95vh',
        hasBackdrop: true
      })
      .afterClosed()
      .subscribe((result: CreateUpdateIdentityApiUsersComponentData) => {
        if (result.didUpdate) {
          var userProfile: UserProfile[] = [];
          userProfile.push(JSON.parse(JSON.stringify(<UserProfile>result.userProfile)));
          this.disableCreatedDate = this.isMinimumDateTimeValue(new Date(userProfile[0].createdDate));
          this.disableModifiedDate = this.isMinimumDateTimeValue(new Date(userProfile[0].modifiedDate));
          this.dataSource = new MatLegacyTableDataSource(userProfile);
        }
      });
  }

  showErrorMessage(response: Response, serviceUrl: string) {
    var serviceResError: ServiceResponseError = new ErrorDetails();

    if (response.status != 422) {
      serviceResError.code = response.status.toString();
      serviceResError.message = "Something went wrong! Please contact Administrator.";
    }
    else {
      response.json()
        .then(json => {
          let response = JSON.parse(JSON.stringify(<ResponseWrap>json));
          serviceResError.code = response.status;
          for (let key in response.errors) {
            let value = response.errors[key];
            serviceResError.message = value;
          }
        });
    }

    var errorsArray: ServiceResponseError[] = [];
    errorsArray.push(serviceResError);
    this.uiService.showErrors(errorsArray, true, serviceUrl);
  }

  private checkEmail(): boolean {
    this.email = this.form.get('email').value;
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    var emailToCheck = this.email?.trim();
    return expression.test(emailToCheck) && emailToCheck.length >= 6 && emailToCheck.length <= 100;
  }

  onKeyUp(event: any) {
    // The key up even only resets the email error condition.
    if (this.checkEmail() && this.showEmailError) {
      this.showEmailError = false;
    }
    if (this.dataGridReplacementMessage != '') {
      this.dataGridReplacementMessage = '';
    }
  }


  isMinimumDateTimeValue(date: Date): boolean {
    const dd = "Mon Jan 01 0001";
    return dd === date.toDateString();
  }
}