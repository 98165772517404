import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FacilityDomainInfo, FacilityDomain } from '@dignity-health/ciam-auth';
import { DOMAIN_VALUE_REQUIRED_ERROR, ADD_CLICK_REQUIRED_ERROR, DOMAIN_START_DATE_REQUIRED_MESSAGE, DOMAIN_END_DATE_REQUIRED_MESSAGE, DOMAIN_START_DATE_INVALID_MESSAGE, DOMAIN_END_DATE_INVALID_MESSAGE } from 'app/types/constants';
import * as moment from 'moment';
import { DATE_REGEX } from '../../../types/helpers/dateValidation';

@Component({
  selector: 'facility-domain-association',
  templateUrl: './facility-domain-association.component.html',
  styleUrls: ['./facility-domain-association.component.scss']
})
export class FacilityDomainAssociationComponent implements OnInit {
  @Input()
  domains: FacilityDomainInfo[];

  @Input()
  domainAssociation: FacilityDomain;

  @Input()
  pending = false;

  @Output()
  remove = new EventEmitter<FacilityDomainInfo>();

  @Output()
  add = new EventEmitter<FacilityDomainInfo>(); 

  dateFormat = 'MM/DD/YYYY';

  constructor() { }

  error: boolean = false;
  saveError: boolean = false;
  requiredError = DOMAIN_VALUE_REQUIRED_ERROR;
  requiredStartDateMessage = DOMAIN_START_DATE_REQUIRED_MESSAGE;
  requiredEndDateMessage = DOMAIN_END_DATE_REQUIRED_MESSAGE;
  invalidStartDateMessage = DOMAIN_START_DATE_INVALID_MESSAGE;
  invalidEndDateMessage = DOMAIN_END_DATE_INVALID_MESSAGE;
  requiredAddClickError = ADD_CLICK_REQUIRED_ERROR;
  beginDate;
  endDate;
  requireStartDate: boolean = false;
  requireEndDate: boolean = false;
  invalidStartDate: boolean = false;
  invalidEndDate: boolean = false;

  ngOnInit() {
    if (!this.domainAssociation || this.pending) {
      this.domainAssociation = this.makeNewDomainAssocation();
    }
  }

  onRemove() {
    this.remove.emit(this.domainAssociation);
  }

  checkFields() {
    this.error = false;
    this.saveError = false;
    if (this.domainAssociation.domainName) {
      this.requiredAddClickError = `${ this.domainAssociation.domainName } ${ this.requiredAddClickError }`;//this.domainAssociation.domainName + this.requiredAddClickError;
      this.saveError = true;
      return false;
    }
    return true;
  }

  onAdd() {
    this.saveError = false;
    this.error = false;
    if (!this.domainAssociation.domainName) {
      this.error = true;
      return false;
    }
    
    if(!this.domainAssociation.beginDate || !this.domainAssociation.endDate) {
      this.requireStartDate = true;
      this.requireEndDate = true;
      return false;
    }
    this.resetValidationMessage();

    this.add.emit(this.domainAssociation);

    this.domainAssociation = this.makeNewDomainAssocation();
  }

  private makeNewDomainAssocation(): FacilityDomain {
    return {
      isActive: true
    };
  }

  setBeginDate(beginDate: string) {
    this.domainAssociation.beginDate = null;
    if (beginDate == null || beginDate === '') {
      return;
    }
    if (!(beginDate.trim()).match(DATE_REGEX)) {
      this.invalidStartDate = true;
      return;
    }
    this.domainAssociation.beginDate = new Date(moment(beginDate).format(this.dateFormat));
  }

  setEndDate(endDate: string) {
    this.domainAssociation.endDate = null;
    if (endDate == null || endDate === '') {
      return;
    }
    if (!(endDate.trim()).match(DATE_REGEX)) {
      this.invalidEndDate = true;
      return;
    }
    this.domainAssociation.endDate = new Date(moment(endDate).format(this.dateFormat));
  }

  resetValidationMessage() {
    this.requireStartDate = false;
    this.requireEndDate = false;
    this.invalidStartDate = false;
    this.invalidEndDate = false;
  }
}
