import { CiamUser } from '@dignity-health/ciam-auth';
import { Action } from '@ngrx/store';

export const UPDATE_USER = 'UPDATE_USER';

export class UpdateUser implements Action {
    public type = UPDATE_USER;

    constructor(public payload: CiamUser) { }
}

