import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UtcDatePipe } from '../../../pipes/utc-date/utc-date.pipe';
import { CapitalizePipe } from '../../../pipes/capitalize/capitalize.pipe';
import { Patient, UnitServiceResponse, UserPersonAccess } from '@dignity-health/ciam-auth';
import { CiamAuth } from '@dignity-health/ciam-auth';
import { IActionConfirmation } from '../../../types/action-confirmation-model/action-confirmation-model';
import { UiService } from '../../../services/ui/ui.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-user-patient-dependents-detail',
  templateUrl: './user-patient-dependents-detail.component.html',
  styleUrls: ['./user-patient-dependents-detail.component.scss'],
  providers: [UtcDatePipe, CapitalizePipe]
})

export class UserPatientDependentsDetailComponent implements OnInit {
  @Input()
  patientDependent: Patient;
  @Input()
  userPatientRelationshipId: number;
  @Input()
  relationshipType: string;
  @Input()
  personId: string;
  @Input()
  username: string;
  @Input()
  isPatientUserExist: boolean;
  @Input()
  userPersonAccess: UserPersonAccess[];
  @Input()
  patientId: string;
  @Output()
  isSeverAccessSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();

  severAccessConfirmation: IActionConfirmation;
  isLoading: boolean = false;
  routeCommand: any[];
  parentUser: string;

  constructor(
    private ciamAuth: CiamAuth,
    private uiService: UiService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      this.parentUser = params['user'];
    });

    this.severAccessConfirmation = {
      matIcon: "lock",
      actionButtonText: `Sever Link to ${this.patientDependent.firstName}`,
      modalTitle: "Confirmation!",
      modalInstruction: "Are you sure you want to sever access for this user?",
      cancelButtonText: "Cancel",
      okButtonText: "OK",
      actionButtonClass: 'mat-raised-button',
      isAdvanceConfirmation: true,
      advanceConfirmationValidationText: "SEVER",
      advanceConfirmationInstruction: "To sever Type 'SEVER'"

    }
  }

  async severLink() {
    try {
      this.isLoading = true;

      let upaId = 0;
      let removedep: Promise<UnitServiceResponse>;

      if (this.userPersonAccess && this.userPersonAccess != null && this.userPersonAccess.length > 0) {
        upaId = this.userPersonAccess[0].userPersonAccessId;
        removedep = this.ciamAuth.httpApi.apiAdminUsersRemoveDependentByUserPersonAccessIdDelete(upaId);
      }

      const portalupr = this.ciamAuth.httpApiPatientPortal.apiPortalsUserUserPatientRelationshipPost(this.userPatientRelationshipId);

      portalupr.then(ppResult => {
        if (!ppResult.isValid && ppResult.errors) {
          return this.uiService.showErrors(ppResult.errors, true, environment.patientPortalsUrl + "/portals/user/user-patient-relationship?userPatientRelationshipId=" + this.userPatientRelationshipId)
        } else {
          if (upaId > 0) {
            removedep.then(ciamResult => {
              if (!ciamResult.isValid && ciamResult.errors) {
                return this.uiService.showErrors(ciamResult.errors, true, environment.patientPortalsUrl + "/portals/user/user-patient-relationship?userPatientRelationshipId=" + this.userPatientRelationshipId)
              } else {
                this.snackBar.open("Updated successfully", null, { duration: 2500 });
                this.isSeverAccessSuccessful.emit(true);
              }
            })
          }
          else {
            this.snackBar.open("Updated successfully", null, { duration: 2500 });
            this.isSeverAccessSuccessful.emit(true);
          }
        }
      });
    } catch (errors) {
      return;
    } finally {
      this.isLoading = false;
    }
  }

  viewAssociatedAccountProfile(email: string) {
    var username = this.parentUser == email ? '' : email;

    this.routeCommand = ['/users/view', { user: username, personId: this.personId, patientId: this.patientId }];
    this.router.navigate(this.routeCommand, { skipLocationChange: true });
  }

  severUserAccess(confirmationValue: boolean, withSecurityResponse: boolean) {
    if (confirmationValue) {
      this.severLink();
    }
  }

  displayAssociateFirstName() {
    if (this.patientDependent == null || this.patientDependent.firstName == null || this.patientDependent.firstName == "") {
      return "Unknown name";
    }

    return this.patientDependent.firstName;
  }
}
