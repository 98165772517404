<h1 mat-dialog-title>{{instruction}}</h1>

<mat-dialog-content padding="true">
  <mat-tab-group mat-stretch-tabs (selectedTabChange)="onEnrollmentTabChanged($event)">
    <mat-tab *ngFor="let enrollment of enrollmentTypes" [label]="enrollment.enrollmentType">
      <ng-template mat-tab-label>
          <span>{{ enrollment.enrollmentDisplayName }}</span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="start" fxLayoutGap="20px" class="dialog-content">
    <div fxFlex="50%">  
      <div class="field-wrapper">
        <mat-checkbox class="field-margin" [(ngModel)]="resetUserRequest.resetToLevel2" [disabled]="!isMigrationSelected && resetUserRequest.resetIndividualUser && disableResetToLevel2">Reset to Level 2</mat-checkbox>
      </div>
      <div class="field-wrapper" *ngIf="resetInformation.displayDelete">
        <mat-checkbox class="field-margin" [(ngModel)]="resetUserRequest.deleteUser" [disabled]="!isMigrationSelected && resetUserRequest.resetIndividualUser">Delete User</mat-checkbox>
      </div>
    </div>
    <div fxFlex>
      <div class="field-wrapper" *ngIf="!isMigrationSelected">
        <mat-checkbox class="field-margin" [(ngModel)]= "resetUserRequest.resetIndividualUser"  (change)="onResetIndUserChanged($event);">Reset Individual User</mat-checkbox>
      </div>
    </div>
  </div>
    <div *ngIf="!isMigrationSelected && errorObtainingData" class="error-loading">
      Error while fetching individual user record(s).
    </div>
    <mat-table [dataSource]="dataSource" *ngIf = "!isMigrationSelected && !errorObtainingData && !showDataLoading">
      <ng-container matColumnDef="sourceId">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" class="radio-header-cell">
          <mat-radio-button *ngIf="resetUserRequest.resetIndividualUser" [value]="element" (change)="onIndividualUserSelection($event);"></mat-radio-button>
        </mat-cell>
      </ng-container>

        
      <ng-container matColumnDef="firstName">
        <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
      </ng-container>

      
      <ng-container matColumnDef="lastName">
        <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.lastName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <mat-header-cell *matHeaderCellDef> Date of Birth </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.patientDateOfBirth | date: 'MM/dd/yyyy'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="enrollmentStatus">
        <mat-header-cell *matHeaderCellDef> Enrollment Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.enrollmentStatus}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDateTime">
        <mat-header-cell *matHeaderCellDef> Portal Completed Time</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.endDateTime |  date:'MMM d, y, h:mm:ss a'}}</mat-cell>
      </ng-container>
      
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div class="error" *ngIf="!isMigrationSelected && resetUserRequest.resetIndividualUser && !showDataLoading && !errorObtainingData && displayError">Please select at least one user.</div>
</mat-dialog-content>

<mat-dialog-actions class="popup-content">
  <mat-spinner *ngIf="showDataLoading && !isMigrationSelected" [diameter]="30" class="spinner-wrapper"></mat-spinner>
  <div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="left right" fxLayoutGap="65px">
    <div fxFlex="52%">
      {{ resetInformation.advanceConfirmationInstruction }}
      <input type="text" class="input-wrapper" (keyup)="validate($event)" [(ngModel)] ="confirmationText" />

    </div>
    <div fxFlex fx-layout-align="end" *ngIf="resetInformation.isAdvanceConfirmation">
      <button mat-button [mat-dialog-close]="true" (click) = "cancelReset();">Cancel</button>
      <button mat-button [mat-dialog-close]="false" (click) = "resetRequestDetails();" 
      [disabled]="(isMigrationSelected) ? !confirmationTextMatched : ((resetUserRequest.resetIndividualUser && displayError) || !confirmationTextMatched)" >Reset {{ resetUserRequest.enrollmentType }}</button>
    </div>
  </div>
</mat-dialog-actions>
<div><br/></div>