<div>
  <div class="div-left"></div>
  <div mat-dialog-title class="dialog-title">
    <h5>Patient Portal Invitation </h5>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div class="div-right"></div>

  <div class="lable-value-section">
    <label class="mat-label-title">Portal InvitationId: </label>
    <label class="mat-label-value">{{invitationId}}</label>
  </div>

  <mat-divider></mat-divider>

  <div class="lable-value-section">
    <label class="mat-label-title">Patient First Name: </label>
    <label class="mat-label-value">{{patientFirstName}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Patient Last Name: </label>
    <label class="mat-label-value">{{patientLastName}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Patient DOB: </label>
    <label class="mat-label-value">{{patientDateOfBirth | date}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Patient Gender: </label>
    <label class="mat-label-value">{{gender}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Relationship To Recipient: </label>
    <label class="mat-label-value">{{patientRelationshipToRecipient}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Patient PersonId: </label>
    <label class="mat-label-value">{{patientPersonId}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Mrn: </label>
    <label class="mat-label-value">{{mrn}}</label>
  </div>

  <mat-divider></mat-divider>

  <div class="lable-value-section">
    <label class="mat-label-title">Status: </label>
    <label class="mat-label-value">{{status}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Created Date: </label>
    <label class="mat-label-value">{{createdDate |date:'MMM d, y, h:mm:ss a' }}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Last Sent: </label>
    <label class="mat-label-value">{{dateSent |date:'MMM d, y, h:mm:ss a' }}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Claimed Date: </label>
    <label class="mat-label-value">{{dateClaimed |date:'MMM d, y, h:mm:ss a' }}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Expired Date: </label>
    <label class="mat-label-value">{{calculateExpirationDate() | date:'MMM d, y, h:mm:ss a'}}</label>
  </div>

  <mat-divider></mat-divider>

  <div class="lable-value-section" >
    <label class="mat-label-title">Recipient Email: </label>
    <label class="mat-label-value">{{recipientEmail}}</label>
  </div>

    <div class="lable-value-section">
      <label class="mat-label-title">Recipient First Name: </label>
      <label class="mat-label-value">{{recipientFirstName}}</label>
    </div>
    <div class="lable-value-section">
      <label class="mat-label-title">Recipient Last Name: </label>
      <label class="mat-label-value">{{recipientLastName}}</label>
    </div>
    <div class="lable-value-section">
      <label class="mat-label-title">Recipient PersonId: </label>
      <label class="mat-label-value">{{recipientPersonId}}</label>
  </div>

  <mat-divider></mat-divider>

  <div class="lable-value-section">
    <label class="mat-label-title">Invitation Source: </label>
    <label class="mat-label-value">{{invitationSource}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Invitation Source System: </label>
    <label class="mat-label-value">{{invitationSourceSystem}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">DomainId: </label>
    <label class="mat-label-value">{{domainId}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Domain Name: </label>
    <label class="mat-label-value">{{domainName}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Verification Code: </label>
    <label class="mat-label-value">{{verificationCode}}</label>
  </div>
  <div class="lable-value-section">
    <label class="mat-label-title">Resend Status: </label>
    <label class="mat-label-value">{{invitationResendStatus}}</label>
  </div> 

  <div mat-dialog-actions>
    <button mat-raised-button class="popup-close" matDialogClose>Close</button>
  </div>
</div>