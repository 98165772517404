import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phoneNumber: string): string {
    var filteredPhoneNumber = phoneNumber.replace(/\D/g, "");
    return filteredPhoneNumber && filteredPhoneNumber.length == 10
      ? `${filteredPhoneNumber.substr(0, 3)}${'.'}${filteredPhoneNumber.substr(3, 3)}${'.'}${filteredPhoneNumber.substr(6, 4)}`
      : phoneNumber;    
  }
}
