import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const regExp = /([^\W_]+[^\s-]*) */g;

    return (!!value)
      ? value.replace(regExp, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })
      : '';
  }
}
