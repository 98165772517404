import { Component, Inject, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedSearchService {
  patientDetailArray: PatientDetails[] = [];
  public resetBehaviour = new BehaviorSubject<boolean>(false);
  resetObservable = this.resetBehaviour.asObservable();

  constructor() {
  }

  changeResetButtonState(value: boolean): void {
    this.resetBehaviour.next(value);
  }

  setPatientDetailForSharedSearch(patientDetail: PatientDetails) {
    this.patientDetailArray.push(patientDetail);
  }

  getDetailForPatientSharedSearch(): PatientDetails[] {
    return this.patientDetailArray;
  }

  removePatientDetailForSharedSearch() {
    this.patientDetailArray.pop();
  }

  checkPatientDetailArrayHasValues(): boolean {
    if (this.patientDetailArray.length === 0) return false;

    const details = this.patientDetailArray[0];
    const fieldsToCheck = [
      'firstName', 'lastName', 'userName', 'userLevel', 'gender',
      'inQuickerConfirmation', 'mrn', 'personId', 'enrollmentPortalName',
      'errorCode', 'createdDate', 'dob', 'fromDate', 'toDate',
      'invitationStatus', 'domain', 'basicUserSearchText',
      'supportLogfromDate', 'supportLogToDate', 'auditLogFromDate',
      'auditLogToDate', 'enrollmentState', 'enrollmentFromDate',
      'enrollmentToDate', 'enrollmentType'
    ];

    return fieldsToCheck.some(field => {
      const value = details[field];
      return value != null && (typeof value !== 'string' || value.trim().length > 0);
    });
  }
}

export class PatientDetails {
  basicUserSearchText: string;
  userName: string;
  firstName: string;
  lastName: string;
  dob: Date;
  gender: string;
  userLevel: string;
  inQuickerConfirmation: string;
  createdDate: Date;
  mrn: string;
  personId: string;
  fromDate: Date;
  toDate: Date;
  exactSearch: boolean;
  domain: string;
  invitationStatus: number;
  errorCode: string;
  supportLogfromDate: Date;
  supportLogToDate: Date;
  auditLogFromDate: Date;
  auditLogToDate: Date;
  enrollmentState: string;
  enrollmentFromDate: Date;
  enrollmentToDate: Date;
  enrollmentPortalName: string;
  enrollmentType: string;
}