import { Router } from '@angular/router';
import { MatLegacyTable, MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent, MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';


import { Component, ViewChild } from '@angular/core';
import { CiamUser, Facility, UpdateFacilityRequest } from '@dignity-health/ciam-auth';
import { Store } from '@ngrx/store';

import { UpdateActiveFacility } from '../../../store/actions';
import { IAppState, patchPageInfo } from '../../../types';
import { FacilitiesService } from '../../../services/facilities/facilities-service';
import { PageInfo, SortInfo } from '../../../types/models';
import { UiService } from '../../../services/ui/ui.service';
import { OktaSupportGroupsService } from '../../../services/okta-group/okta-support-group.service';
import { FacilitiesCreateUpdateComponent, FacilitiesCreateUpdateComponentData } from '../facilities-create-update/facilities-create-update.component';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent {
  isLoading = false;
  isTier1SupportGroup = false;
  isTier2SupportGroup = false;
  showNoResultMessage = false;

  user: CiamUser;
  user$: Observable<CiamUser>;
  @ViewChild(MatLegacyTable)
  table: MatLegacyTable<Facility>;

  @ViewChild(MatLegacyPaginator) paginator: MatLegacyPaginator;
  displayedColumns = ['name', 'schedulingLocationId', 'edwFacilityName', 'address', 'domainName', 'actions'];

  public pageInfo: PageInfo = {
    currentPage: 0,
    pageSize: 20
  };

  sortInfo: SortInfo = { sortColumn: "", sortOrder: "" };
  searchTerm: string;
  dataSource: MatLegacyTableDataSource<Facility>;
  searchPerformed = false;
  constructor(
    private facilitiesService: FacilitiesService,
    private uiService: UiService,
    private oktaGroupService: OktaSupportGroupsService,
    private store: Store<IAppState>,
    private dialog: MatLegacyDialog
  ) {
    this.user$ = store.select(s => s.user);
    this.user$.subscribe(user => { this.user = user; });

    this.pageInfo.currentPage = 1;
    this.refreshFacilities(this.searchTerm, this.pageInfo, this.sortInfo);

    if (!this.checkTier1SupportGroup()) {
      //do not allow Tier1 user to access BarGroup and Domain info.
      this.refreshFacilitiesRelatedInfo();
    }

  }

  refreshFacilities(searchTerm: string, pageInfo: PageInfo, sortInfo: SortInfo) {
    this.isLoading = true;

    // Set the paginator's pageindex back to 0, if searching on page 1
    if (this.pageInfo.currentPage == 1 && this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.facilitiesService.getPagedFacilities(true, searchTerm, this.pageInfo, this.sortInfo)
      .then(response => {
        this.isLoading = false;

        if (!response.isValid) {
          return this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "graphql/query/getAllFacilitiesPaged");
        }

        const pagedResult = response.result;
        const facilities = pagedResult ? pagedResult.results : null;
        this.showNoResultMessage = facilities == null;
        this.dataSource = new MatLegacyTableDataSource(facilities);
        patchPageInfo(this.pageInfo, pagedResult, true);
      });
  }

  AddUpdateFacilties(facility?: Facility) {
    if (facility == null) {
      facility = <Facility>{
        facilityId: 0,
        alternativeFacilities: [],
        images: [],
        domains: [],
        alternateNames: []
      };
    }
    else {
      this.store.dispatch(new UpdateActiveFacility(facility));
    }

    // open quick edit modal
    this.dialog
      .open<FacilitiesCreateUpdateComponent, FacilitiesCreateUpdateComponentData>(FacilitiesCreateUpdateComponent, {
        data: {
          facility: facility
        },
        disableClose: true,
        width: '60%',
        height: '90%',
        hasBackdrop: true
      })
      .afterClosed()
      .subscribe((result: FacilitiesCreateUpdateComponentData) => {
        if (result.didUpdate) {
          // Refresh the facilities after closing the modal
          this.pageInfo.currentPage = this.pageInfo.currentPage - 1;
          let index = this.dataSource.data.findIndex(x => x.facilityId == result.facility.facilityId);
          if (index > -1) {
            this.dataSource.data[index] = result.facility;
          }

          var facilities = this.dataSource.data;
          this.dataSource = new MatLegacyTableDataSource(facilities);
        }
      });
  }

  onPaginateChange(event: LegacyPageEvent) {
    this.pageInfo = { currentPage: event.pageIndex + 1, pageSize: event.pageSize };
    this.refreshFacilities(this.searchTerm, this.pageInfo, this.sortInfo);
  }

  onSortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.sortInfo = { sortColumn: sort.active, sortOrder: sort.direction };
    this.pageInfo.currentPage = 1;
    this.refreshFacilities(this.searchTerm, this.pageInfo, this.sortInfo);
  }

  onRequestSearch() {
    this.pageInfo.currentPage = 1;
    this.refreshFacilities(this.searchTerm, this.pageInfo, this.sortInfo);

    if (this.searchTerm) {
      this.searchPerformed = true;
    } else {
      this.searchPerformed = false;
    }
  }

  onClickExportData() {
    this.isLoading = true;

    this.facilitiesService.exportFacilityData()
      .then(response => {
        this.isLoading = false;

        if (!response.isValid) {
          return this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "graphql/query/facilities");
        }

        // Sort facilities by name
        const facilities = response.result;
        const sortedFacilities = _.sortBy(facilities, (f: Facility) => f.friendlyFacilityName);

        // Generate the file content
        const filename = this.makeFileNameForFacilitiesExport();
        const file = new Blob([JSON.stringify(sortedFacilities, null, 2)], { type: 'application/json' });

        // Save the file
        saveAs(file, filename);
      });
  }

  makeFileNameForFacilitiesExport(): string {
    const now = new Date();
    return `facilities.${window.location}.${now.toUTCString()}.json`;
  }

  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  async refreshFacilitiesRelatedInfo(): Promise<void> {
    // Fire off request to get all domain/bargroup/etc info for population later
    const response = await this.facilitiesService.getAllFacilitiesRelatedInfo(true);
    if (response.errors && response.errors.length) {
      return this.uiService.showErrors(response.errors, true, environment.facilitiesApimUrl + "graphql/query/domains");
    }
  }

  checkTier1SupportGroup(): boolean {
    this.isTier1SupportGroup = this.oktaGroupService.isTier1SupportGroup;
    return this.isTier1SupportGroup;
  }

  checkTier2SupportGroup(): boolean {
    this.isTier2SupportGroup = this.oktaGroupService.isTier2SupportGroup;
    return this.isTier2SupportGroup;
  }

  clearSearch() {
    this.searchTerm = '';
    this.searchPerformed = false;
    this.pageInfo.currentPage = 1;
    this.refreshFacilities(this.searchTerm, this.pageInfo, this.sortInfo);
  }
}
