<div [@fadeInOut] class="page-content" fxLayout="column" fxLayoutAlign="center center">
  <div>
    <mat-icon class="mat-icon-lg vertical-center">visibility_off</mat-icon>
    <h1 class="vertical-center" style="margin: 0;">401</h1>
  </div>
  <p>Sorry, but you're not authorized to access this page.</p>
  <button mat-raised-button color="primary" routerLink="/">
    <mat-icon>home</mat-icon>
    <span>Back to Home</span>
  </button>
</div>
