import { Injectable } from "@angular/core";
import { CiamAuth, ServiceResponse, UnitServiceResponse } from "@dignity-health/ciam-auth";
import { ScoringProfile, SaveScoringProfileRequest } from "@dignity-health/ciam-auth/dist/src/types/provider-search/interfaces";

@Injectable()
export class ScoreAndFieldsService {

  constructor(private ciamAuth: CiamAuth) { }

  apiVersion: string = "2"

  getScoringProfile(): Promise<ServiceResponse<ScoringProfile>> {
    return this.ciamAuth.httpApiProviderSearch.apiScoringProfilesGet(this.apiVersion)
  }

  updateScore(scoringProfileRequest: SaveScoringProfileRequest): Promise<UnitServiceResponse> {
    return this.ciamAuth.httpApiProviderSearch.apiScoringProfilesPut(scoringProfileRequest, this.apiVersion);
  }
}
