import { FacilityDomainInfo, BarGroup } from '@dignity-health/ciam-auth';
import { IAppStateInfo } from '../../types';
import { UPDATE_DOMAINS_LISTING, UPDATE_BAR_GROUPS_LISTINGS } from '../actions/info';

export function info(state: IAppStateInfo, { type, payload }): IAppStateInfo {
    console.debug('info reducer received message', {state, type, payload});

    switch (type) {
        case UPDATE_DOMAINS_LISTING:
            console.log('updating domains')

            return {
                ...state,
                domains: payload
            };
        case UPDATE_BAR_GROUPS_LISTINGS:
            return {
                ...state,
                barGroups: payload
            };
        default:
            return state;
    }
}
