import { ValidationErrors, ValidationMessages } from '../forms';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ServiceResponseError, Facility, ServiceResponse } from '@dignity-health/ciam-auth';
import { PagedResult, GraphQLPagedResponse } from '../graphql';

export * from './paging';

export function getValidationErrors(form: FormGroup, validationMessages: ValidationMessages): ValidationErrors {
    if (!form) {
        return {};
    }

    return Object.getOwnPropertyNames(form.controls)
        .reduce((acc, name) => {
            const control = form.controls[name];
            if (!control || !control.errors || control.pristine) {
                return acc;
            }

            acc[name] = Object.getOwnPropertyNames(control.errors)
                .map(error => {
                    const messagesForControl = validationMessages[name]
                    if (!messagesForControl) {
                        return;
                    }

                    return messagesForControl[error] || '';
                });

            return acc;
        }, {});
}

export function formatDate(datePipe: DatePipe, date: Date): any {
    return (date == null || date == undefined ? null : datePipe.transform(date, 'MM/dd/yyyy'));
}