import { UPDATE_USER } from '../actions/user';
import { Action } from '@ngrx/store';
import { User } from '@dignity-health/ciam-auth';

export function user(state: User, { type, payload }) {
    switch (type) {
        case UPDATE_USER:
            return payload;

        default:
            return state;
    }
}