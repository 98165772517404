import { CiamAuth, ServiceResponse, UnitServiceResponse, RevokePortalAccessRequest } from "@dignity-health/ciam-auth";
import { Injectable } from '@angular/core';
import { UiService } from "../ui/ui.service";

@Injectable()
export class PortalAccessService {

    constructor(private ciamAuth: CiamAuth,
        private uiService: UiService){
    }

    async revokePortalAccess(request: RevokePortalAccessRequest) : Promise<UnitServiceResponse> {
        const result = await this.ciamAuth.httpApiPatientPortal.apiPortalsUserRevokePortalAccessPatch(request);
        
        if (result && result.errors && result.errors.length) {
            return result;
        }

        return result;
      }
}
