<progress-bar [isLoading]="isLoading"></progress-bar>
<mat-tab-group class="tab-root">
  <mat-tab label="Basic Info">
    <div class="basic-info-form">
      <mat-form-field>
        <input matInput placeholder="Name" name="friendlyFacilityName" maxlength="200" [(ngModel)]="request.friendlyFacilityName" />
      </mat-form-field>
      <mat-error *ngIf="request.friendlyFacilityName?.length &lt; 1">{{requiredFieldMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="Scheduling Location Id" name="schedulingLocationId" maxlength="200" [(ngModel)]="request.schedulingLocationId" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="EDW Facility Name" name="edwFacilityName" maxlength="255" [(ngModel)]="request.edwFacilityName" />
      </mat-form-field>
      <mat-error *ngIf="request.edwFacilityName?.length &lt; 1">{{requiredFieldMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="Registration System" name="registrationSystem" maxlength="255" [(ngModel)]="request.registrationSystem" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Web Address" name="webAddress" maxlength="255" [(ngModel)]="request.webAddress" />
      </mat-form-field>

      <mat-form-field>
        <mat-chip-list #alternateNames>
          <mat-chip *ngFor="let altName of request.alternateFacilityNames" [removable]="true" (remove)="onRemoveAlternateFacilityName(altName)">
            {{altName}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <input class="alternate-names-input" #alternateNamesInput [matChipInputFor]="alternateNames" maxlength="50" [matChipInputSeparatorKeyCodes]="chipSeparatorKeyCodes"
          (matChipInputTokenEnd)="onAddAlternateFacilityName(alternateNamesInput, $event.value)" placeholder="Alternate Names"
          name="alternateNames" />
      </mat-form-field>

      <mat-slide-toggle class="is-active-control" [(ngModel)]="request.isActive">Active?</mat-slide-toggle>
    </div>
  </mat-tab>

  <mat-tab label="Address Info">
    <div class="address-info-form">
      <mat-form-field>
        <input matInput placeholder="Latitude" name="latitude" maxlength="21" (blur)="validateLatitude($event)" [(ngModel)]="request.latitude">
      </mat-form-field>
      <mat-error *ngIf="!validLatitude">{{invalidLatitudeMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="Longitude" name="longitude" maxlength="21" (blur)="validateLongitude($event)" [(ngModel)]="request.longitude">
      </mat-form-field>
      <mat-error *ngIf="!validLongitude">{{invalidLongitudeMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="Line 1" name="addressLine1" maxlength="150" [(ngModel)]="request.address.line1">
      </mat-form-field>
      <mat-error *ngIf="request.address?.line1?.length &lt; 1">{{requiredFieldMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="Line 2" name="addressLine2" maxlength="150" [(ngModel)]="request.address.line2">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="City" name="city" maxlength="35" (blur)="validateCity($event)" [(ngModel)]="request.address.city">
      </mat-form-field>
      <mat-error *ngIf="request.address?.city?.length &lt; 1">{{requiredFieldMessage}}</mat-error>
      <mat-error *ngIf="!validCity">{{invalidCityMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="State Abbreviation" name="stateOrProvince" maxlength="2" (keyup)="validateState($event)"[(ngModel)]="request.address.stateOrProvince">
      </mat-form-field>
      <mat-error *ngIf="request.address?.stateOrProvince?.length &lt; 2">{{requiredFieldMessage}}</mat-error>
      <mat-error *ngIf="!validState">{{invalidStateMessage}}</mat-error>

      <mat-form-field>
        <input matInput placeholder="Zip Code" name="zipCode" maxlength="5" (keyup)="validateZipCode($event)"[(ngModel)]="request.address.zipCode">
      </mat-form-field>
      <mat-error *ngIf="request.address?.zipCode?.length &lt; 5">{{invalidZipLength}}</mat-error>
      <mat-error *ngIf="!validZip">{{invalidZipMessage}}</mat-error>
    </div>
  </mat-tab>

  <mat-tab label="Domain Associations">
    <div class="domain-associations-form">
      <div *ngFor="let association of request.facilityDomains | active">
        <facility-domain-association [domains]="domains" [domainAssociation]="association" (remove)="onRemoveDomainAssociation($event)"></facility-domain-association>
      </div>

      <facility-domain-association [domains]="domains" [pending]="true" (add)="onAddDomainAssociation($event)" #facilityDomainAssociationComponent></facility-domain-association>
    </div>
  </mat-tab>

  <mat-tab label="Bar Group &amp; EMPI Hub Info">
    <div class="bar-group-and-empi-form">
      <mat-form-field>
        <mat-select placeholder="Bar Group (Group : Region : EMPI Hub)" [(value)]="request.barGroupId">
          <mat-option *ngFor="let barGroup of barGroups" [value]="barGroup.barGroupId">
            {{barGroup.groupId}} : {{barGroup.region}} : {{barGroup.empiId}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-tab>
</mat-tab-group>
<div mat-dialog-actions>
  <button class="popup-save" [disabled]="!(request.edwFacilityName && request.friendlyFacilityName && request.address?.line1 && request.address?.city && (request.address?.stateOrProvince?.length > 1 && validState) && (request.address?.zipCode?.length > 4 && validZip) && (!request.latitude || validLatitude) && (!request.longitude || validLongitude))" mat-raised-button (click)="onClickSave()">Save</button> 
  <button class="popup-close" mat-raised-button (click)="onNoClick()">Cancel</button> 
</div>

